import Input from '@admin/components/html/input'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import React from 'react'

class NumericField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.number,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    of: PropTypes.any,
    min: PropTypes.any,
    max: PropTypes.any,
    units: PropTypes.string,
    tabIndex: PropTypes.number,
    value: PropTypes.any,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    disabled: false,
    tabIndex: 0,
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    focused: false,
    value: 0,
    text: '0'
  }

  _handleBlured = this._handleBlured.bind(this)
  _handleDecrease = this._handleDecrease.bind(this)
  _handleFocused = this._handleFocused.bind(this)
  _handleIncrease = this._handleIncrease.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    const { of } = this.props
    return (
      <div className={ this._getClass() }>
        <div className="maha-input-field">
          <Input { ...this._getInput() } />
          { of !== undefined &&
            <span> / {of}</span>
          }
        </div>
        <div className="maha-numericfield-controls">
          <Button { ...this._getIncrease() } />
          <Button { ...this._getDecrease() } />
        </div>
      </div>
    )
  }

  componentDidMount() {
    const { value } = this.props
    if(!_.isNil(value)) this._handleSet(value)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.value !== this.props.value) {
      this._handleSet(this.props.value)
    }
    if(prevState.value !== this.state.value) {
      this._handleChange()
    }
  }

  _getClass() {
    const { disabled } = this.props
    const { focused } = this.state
    const classes = ['maha-input','maha-numericfield']
    if(disabled) classes.push('disabled')
    if(focused) classes.push('focused')
    return classes.join(' ')
  }

  _getDecrease() {
    return {
      icon: 'caret-down',
      className: 'maha-numericfield-decrease',
      handler: this._handleDecrease
    }
  }

  _getIncrease() {
    return {
      icon: 'caret-up',
      className: 'maha-numericfield-increase',
      handler: this._handleIncrease
    }
  }

  _getFormatted(value) {
    const { units } = this.props
    return units ? `${value}${units}` : value
  }

  _getInput() {
    const { disabled, id, tabIndex } = this.props
    const { text } = this.state
    return {
      disabled,
      type: 'textfield',
      id,
      tabIndex,
      value: text,
      onBlur: this._handleBlured,
      onFocus: this._handleFocused,
      onChange: this._handleUpdate
    }
  }

  _handleBlured() {
    const { onBlur } = this.props
    const { text } = this.state
    const matches = text.match(/^([\d.]*)/)
    const value = matches[1].length > 0 ? Number(matches[1]).toString() : null
    this._handleSet(value)
    this.setState({
      focused: false
    })
    if(onBlur) onBlur()
  }

  _handleChange() {
    const { value } = this.state
    this.props.onChange(value)
  }

  _handleDecrease() {
    const { min } = this.props
    const value = this.state.value - 1
    const decreased = !_.isNil(min) ? Math.max(min, value) : value
    this._handleSet(decreased)
  }

  _handleFocused() {
    const { onFocus } = this.props
    this.setState({
      focused: true
    })
    if(onFocus) onFocus()
  }

  _handleIncrease() {
    const { max } = this.props
    const value = this.state.value + 1
    const increased = !_.isNil(max) ? Math.min(max, value) : value
    this._handleSet(increased)
  }

  _handleSet(value) {
    const { disabled } = this.props
    if(disabled) return
    this.setState({
      text: this._getFormatted(value),
      value: Number(value)
    })
  }

  _handleUpdate(text) {
    this.setState({ text })
  }

}

export default NumericField
