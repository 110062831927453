import SchedulingToken from '@apps/truevail/admin/tokens/scheduling'
import ChannelToken from '@apps/truevail/admin/tokens/channel'
import Configuration from '../configuration'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    advisor: PropTypes.object,
    configuration: PropTypes.object,
    onBack: PropTypes.func,
    onDone: PropTypes.func
  }

  _handleBack = this._handleBack.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { advisor, configuration } = this.props
    return {
      title: 't(New Email Subscription)',
      method: 'POST',
      action: `/api/admin/truevail/agency/advisors/${advisor.id}/subscriptions`,
      cancelIcon: 'chevron-left',
      onCancel: this._handleBack,
      onSuccess: this._handleSuccess,
      before: <Configuration configuration={ configuration } fields={['email_strategy','email_channels','email_scheduling','voice']} />,
      sections: [
        {
          fields: [
            { name: 'type', type: 'hidden', value: 'email' },
            { label: 't(Sender)', name: 'sender_id', type: 'dropdown', entity: 'sender', endpoint: `/api/admin/truevail/agency/advisors/${advisor.id}/senders`, valueKey: 'id', textKey: 'rfc822', selectFirst: true, required: true },
            { label: 't(List)', name: 'list_id', type: 'dropdown', entity: 'list', endpoint: `/api/admin/truevail/agency/advisors/${advisor.id}/lists`, valueKey: 'id', textKey: 'title', selectFirst: true, required: true },
            { label: 't(Email Template)', name: 'email_template_id', type: 'dropdown', entity: 'template', endpoint: `/api/admin/truevail/agency/advisors/${advisor.id}/templates`, valueKey: 'id', textKey: 'title', selectFirst: true, required: true },
            { label: 't(Scheduling)', name: 'scheduling', type: 'radiogroup', options: ['optout','optin'], format: SchedulingToken, required: true, defaultValue: configuration.email_scheduling || 'optout' },
            { label: 't(Channels)', name: 'channel_ids', type: 'checkboxgroup', entity: 'channel', endpoint: '/api/admin/truevail/agency/channels', valueKey: 'id', textKey: 'title', filter: { service: { $eq: 'email' } }, format: (channel) => (
              <ChannelToken channel={ channel } />
            ), defaultValue: configuration.email_channel_ids || [] }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleSuccess() {
    this.props.onDone()
  }

}

export default New
