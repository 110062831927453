import Input from '@admin/components/html/input'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'

class Searchbox extends React.Component {

  static contextTypes = {
    locale: PropTypes.object
  }

  static propTypes = {
    active: PropTypes.bool,
    autofocus: PropTypes.bool,
    color: PropTypes.string,
    icon: PropTypes.string,
    prompt: PropTypes.string,
    q: PropTypes.string,
    onAbort: PropTypes.func,
    onBegin: PropTypes.func,
    onChange: PropTypes.func,
    onEnd: PropTypes.func,
    onIcon: PropTypes.func,
    onType: PropTypes.func
  }

  static defaultProps = {
    autofocus: false,
    prompt: 't(Search)',
    q: '',
    onChange: (value) => {}
  }

  inputRef = React.createRef()

  _handleAbort = this._handleAbort.bind(this)
  _handleChange = _.debounce(this._handleChange.bind(this), 300)
  _handleIcon = this._handleIcon.bind(this)

  render() {
    const { icon, q } = this.props
    return (
      <div className={ this._getClass() }>
        <div className="maha-searchbox-container">
          { icon &&
            <div className="maha-searchbox-extra" onClick={ this._handleIcon }>
              <Icon icon={ `${icon}` } />
            </div>
          }
          <div className="maha-searchbox-input">
            <div className="maha-searchbox-icon">
              <Icon icon="search" />
            </div>
            <div className="maha-searchbox-field">
              <Input { ...this._getInput() } />
            </div>
            { q.length > 0 &&
              <div className="maha-searchbox-remove-icon" onClick={ this._handleAbort }>
                <Icon icon="times-circle" />
              </div>
            }
          </div>
        </div>
      </div>
    )
  }

  componentDidMount() {
    const { autofocus } = this.props
    if(autofocus) setTimeout(() => {
      if(!this.inputRef.current) return
      this.inputRef.current.focus()
    }, 750)
  }

  componentDidUpdate(prevProps) {
    const { q } = this.props
    if(q !== prevProps.q) this._handleChange(q)
  }

  _getClass() {
    const classes = ['maha-searchbox']
    if(this.props.color) classes.push(this.props.color)
    if(this.props.active) classes.push('active')
    return classes.join(' ')
  }

  _getInput() {
    const { t } = this.context.locale
    const { prompt, q } = this.props
    return {
      autoComplete: 'do-not-autofill',
      ref: this.inputRef,
      type: 'search',
      name: 'searchbox',
      id: 'searchbox',
      placeholder: t(prompt),
      value: q,
      onFocus: this._handleBegin.bind(this),
      onBlur: this._handleEnd.bind(this),
      onChange: this._handleType.bind(this)
    }
  }

  _handleIcon() {
    this.props.onIcon()
  }

  _handleBegin() {
    this.props.onBegin()
  }

  _handleChange(q) {
    this.props.onChange(q)
  }

  _handleEnd() {
    this.props.onEnd()
  }

  _handleType(value) {
    this.props.onType(value)
  }

  _handleAbort() {
    this.props.onAbort()
  }

}

export default Searchbox
