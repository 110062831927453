import Container from '@admin/components/container'
import ModalPanel from '@admin/components/modal_panel'
import ReactHtmlParser from 'react-html-parser'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import React from 'react'

class Article extends React.Component {

  static propTypes = {
    article: PropTypes.object,
    onArticle: PropTypes.func,
    onBack: PropTypes.func
  }

  _handleBack = this._handleBack.bind(this)
  _handleTransform = this._handleTransform.bind(this)

  render() {
    const { article } = this.props
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="maha-help-article">
          <div className="maha-help-article-body">
            <h1>{ article.title }</h1>
            { ReactHtmlParser(article.body, { transform: this._handleTransform }) }
          </div>
        </div>
      </ModalPanel>
    )
  }

  _getPanel() {
    return {
      color: 'green',
      leftItems: [
        { icon: 'chevron-left', handler: this._handleBack }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleLink(path) {
    this.props.onArticle(path)
  }

  _getLink(label, path) {
    return {
      label,
      className: 'link',
      handler: this._handleLink.bind(this, path.replace('.html', ''))
    }
  }

  _handleTransform(node, index) {
    const { attribs, children, name } = node
    if(name === 'a') {
      if(attribs.class === 'headerlink') return null
      return <Button { ...this._getLink(children[0].data, attribs.href) } />
    }
  }

}

const mapResources = (props, context) => ({
  article: `/api/admin/help/articles/${props.id}`
})

export default Container(mapResources)(Article)
