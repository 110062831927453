import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Identity extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    configuration: PropTypes.object,
    onBack: PropTypes.func,
    onDone: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: null
  }

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    if(!this.state.config) return null
    return <Form { ...this._getForm() } />
  }

  componentDidMount() {
    const { configuration } = this.props
    this.setState({
      config: configuration
    })
  }

  _getForm() {
    const { config } = this.state
    return {
      ref: this.formRef,
      title: 't(Business Information)',
      cancelIcon: 'chevron-left',
      saveText: null,
      buttons: [
        { label: 't(Done)', color: 'blue', handler: this._handleSubmit }
      ],
      onCancel: this._handleBack,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Business Name)', instructions: 't(Enter the official name of your business as recognized by your clients)', name: 'business_name', type: 'textfield', required: true, defaultValue: config.business_name || config.organization },
            { label: 't(Agency / Host Agency)', instructions: 't(Enter the name of your host agency if applicable)', name: 'host_agency', type: 'textfield', defaultValue: config.host_agency },
            { label: 't(Consortia)', instructions: 't(Indicate any travel consortia your business is affiliated with, if any)', name: 'consortia', type: 'textfield', defaultValue: config.consortia },
            { label: 't(Business Address)', instructions: 't(Provide the physical location of your business)', name: 'business_address', type: 'addressfield', required: true, defaultValue: config.business_address },
            { label: 't(Business Email)', instructions: 't(Enter the primary email address used for business communications)', name: 'business_email', type: 'emailfield', required: true, defaultValue: config.business_email || config.email },
            { label: 't(Business Phone)', instructions: 't(Provide the main contact number for client inquiries and business communication)', name: 'business_phone', type: 'phonefield', required: true, defaultValue: config.business_phone },
            { label: 't(Business Social Media)', instructions: 't(List your business\'s social media profiles for listing on your marketing services. We suggest providing only social media accounts you are active on)', type: 'segment', fields: [
              { name: 'facebook', type: 'urlfield', service: 'facebook', placeholder: 't(Enter Facebook URL)', defaultValue: config.facebook },
              { name: 'instagram', type: 'urlfield', service: 'instagram', placeholder: 't(Enter Instagram URL)', defaultValue: config.instagram },
              { name: 'linkedin', type: 'urlfield', service: 'linkedin', placeholder: 't(Enter LinkedIn URL)', defaultValue: config.linkedin },
              { name: 'youtube', type: 'urlfield', service: 'youtube', placeholder: 't(Enter YouTube URL)', defaultValue: config.youtube },
              { name: 'pinterest', type: 'urlfield', service: 'pinterest', placeholder: 't(Enter Pinterest URL)', defaultValue: config.pinterest },
              { name: 'tiktok', type: 'urlfield', service: 'tiktok', placeholder: 't(Enter TikTok URL)', defaultValue: config.tiktok },
              { name: 'threads', type: 'urlfield', service: 'threads', placeholder: 't(Enter Threads URL)', defaultValue: config.threads },
              { name: 'vimeo', type: 'urlfield', service: 'vimeo', placeholder: 't(Enter Vimeo URL)', defaultValue: config.vimeo },
              { name: 'x', type: 'urlfield', service: 'x', placeholder: 't(Enter X URL)', defaultValue: config.x }
            ] }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(config) {
    this.props.onDone(config)
  }

}

export default Identity
