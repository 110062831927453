import Dependencies from '@admin/components/dependencies'
import ReactQuill, { Quill } from 'react-quill'
import PropTypes from 'prop-types'
import React from 'react'

const map = {
  b: 'bold',
  em: 'italic',
  u: 'underline',
  ul: { list: 'bullet' },
  ol: { list: 'ordered' },
  a: 'link'
}

const getIcon = (icon, tooltip) => {
  return `<div data-tooltip="${tooltip}" data-inverted="true"><i class="fa fa-${icon}"></i></span>`
}

const icons = Quill.import('ui/icons')
icons.bold = getIcon('bold', 'Bold')
icons.italic = getIcon('italic', 'Italic')
icons.underline = getIcon('underline', 'Underline')
icons.link = getIcon('link', 'Insert Link')

class TextField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.string,
    placeholder: PropTypes.string,
    tabIndex: PropTypes.number,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    defaultValue: '',
    onChange: (value) => {},
    onReady: () => {}
  }

  quillRef = React.createRef()

  _handleChange = _.throttle(this._handleChange.bind(this), 500)
  _handlePaste = this._handlePaste.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  constructor(props) {
    super(props)
    const { value, defaultValue } = props
    const def = !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : ''
    this.state = {
      defaultValue: def,
      value: def
    }
  }

  render() {
    return (
      <div className="maha-htmlfield">
        <ReactQuill { ...this._getEditor() } />
      </div>
    )
  }

  componentDidMount() {
    this._handleInit()
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.value !== prevState.value) {
      this._handleChange()
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : ''
  }

  _getEditor() {
    const { tabIndex, placeholder } = this.props
    const { defaultValue } = this.state
    return {
      defaultValue,
      ref: this.quillRef,
      onChange: this._handleUpdate,
      placeholder,
      tabIndex,
      modules: {
        toolbar: {
          container: [Object.values(map)]
        }
      }
    }
  }

  _handleChange() {
    const { value } = this.state
    const sanitized = value.replace(/&lt;%-/g,'<%-').replace(/%&gt;/g,'%>')
    this.props.onChange(sanitized)
  }

  _handleInit() {
    const editor = this.quillRef.current.getEditor()
    editor.clipboard.addMatcher(Node.ELEMENT_NODE, this._handlePaste)
  }

  _handlePaste (node, delta) {
    let ops = []
    delta.ops.forEach(op => {
      delete op.attributes
      if(op.insert && typeof op.insert === 'string') ops.push(op)
    })
    delta.ops = ops
    return delta
  }

  _handleSet(value) {
    this.setState({ value })
  }

  _handleUpdate(value) {
    this.setState({ value })
  }

}

const dependencies = {
  styles: [
    { url: '/css/quill.snow.css' }
  ]
}

TextField = Dependencies(dependencies)(TextField)

export default TextField
