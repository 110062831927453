import Page from '@admin/components/page'
import Overview from './overview'
import Signup from './signup'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.advisor.team.title,
  manager: {
    path: `/admin/truevail/admin/advisors/${resources.advisor.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview advisor={ resources.advisor } />
      },
      {
        label: 't(Signup)',
        path: '/signup',
        show: resources.advisor.team.is_active && resources.advisor.signup !== null,
        panel: <Signup advisor={ resources.advisor } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  advisor: `/api/admin/truevail/admin/advisors/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
