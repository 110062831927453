import Configuration from '../configuration'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    advisor: PropTypes.object,
    configuration: PropTypes.object,
    onBack: PropTypes.func,
    onDone: PropTypes.func
  }

  state = {
    config: {}
  }

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { advisor, configuration } = this.props
    return {
      title: 't(New Sender)',
      method: 'POST',
      action: `/api/admin/truevail/agency/advisors/${advisor.id}/senders`,
      cancelIcon: 'chevron-left',
      onCancel: this._handleBack,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      before: <Configuration configuration={ configuration } fields={['email_strategy','from_name','from_email']} />,
      sections: [
        {
          fields: [
            { label: 't(From Name)', name: 'name', type: 'textfield', required: true, defaultValue: configuration.from_name },
            { label: 't(From Email)', name: 'email', type: 'emailfield', defaultValue: configuration.from_email }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSuccess() {
    this.props.onDone()
  }

}

export default New
