import StripeCardField from '@apps/finance/admin/components/paymentfield/stripe/cardfield'
import CardToken from '@apps/finance/admin/tokens/card'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    advisor: PropTypes.object
  }

  state = {
    config: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { programs } = this.context.admin
    const { advisor } = this.props
    const { config } = this.state
    return {
      title: 't(New Billing Subscription)',
      method: 'POST',
      action: `/api/admin/truevail/admin/advisors/${advisor.id}/subscriptions`,
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 'Subscription', type: 'segment', fields: [
              { name: 'trial', type: 'radiogroup', options: [
                { value: false, text: 't(This subscription does not have a trial period)' },
                { value: true, text: 't(This subscription has a trial period)' }
              ], required: true, defaultValue: false },
              ...config.trial ? [
                { type: 'fields', fields: [
                  { name: 'trial_started_on', type: 'datefield', required: true, defaultValue: moment().format('YYYY-MM-DD') },
                  { name: 'trial_ended_on', type: 'datefield', required: true, defaultValue: moment().add(2, 'months').endOf('month').format('YYYY-MM-DD') }  
                ] }
              ] : [
                { label: 't(Start Date)', name: 'started_on', type: 'datefield', required: true, defaultValue: moment().add(1, 'month').startOf('month').format('YYYY-MM-DD') }
              ]
            ] },
            { label: 't(Payment Method)', type: 'segment', fields: [
              { name: 'card_strategy', type: 'radiogroup', options: [
                { value: 'previous', text: 't(Use a previous card)' },
                { value: 'new', text: 't(Use a new card)' }
              ], defaultValue: 'previous' },
              { name: 'payment_method_id', type: 'dropdown', show: config.card_strategy === 'previous', endpoint: '/api/admin/finance/payment_methods', filter: { contact_id: { $eq: advisor.team.contact.id } }, valueKey: 'id', textKey: 'description', format: (payment_method) => (
                <CardToken payment_method={ payment_method } />
              ), required: true, selectFirst: true },
              { name: 'payment', type: StripeCardField, show: config.card_strategy === 'new', stripeAccount: programs[0].stripe_id }
            ] },
            { label: 't(Price)', name: 'price', type: 'moneyfield', required: true, defaultValue: 99.00 }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default New
