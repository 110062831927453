import ModalPanel from '@admin/components/modal_panel'
import Searchbox from '@admin/components/searchbox'
import Infinite from '@admin/components/infinite'
import Message from '@admin/components/message'
import PropTypes from 'prop-types'
import Results from './results'
import React from 'react'

class Articles extends React.Component {

  static propTypes = {
    onArticle: PropTypes.func,
    onClose: PropTypes.func
  }

  state = {
    q: ''
  }

  _handleClose = this._handleClose.bind(this)

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="maha-help-main">
          <div className="maha-help-search">
            <div className="maha-help-search-query">
              <Searchbox { ...this._getSearchbox() } />
            </div>
            <Infinite { ...this._getInfinite() } />
          </div>
        </div>
      </ModalPanel>
    )
  }

  _getPanel() {
    return {
      title: 't(Help Center)',
      color: 'green',
      leftItems: [
        { icon: 'chevron-left', handler: this._handleClose }
      ]
    }
  }

  _getSearch() {
    const { onArticle } = this.props
    return {
      valueKey: null,
      onArticle
    }
  }

  _getSearchbox() {
    return {
      prompt: 'Find an article...',
      onChange: this._handleType.bind(this)
    }
  }

  _getInfinite() {
    const { onArticle } = this.props
    const { q } = this.state
    const empty = {
      icon: 'times',
      title: 't(No Articles)',
      text: 't(There were no articles that matched your search)'
    }
    return {
      endpoint: '/api/admin/help/articles',
      empty: <Message { ...empty } />,
      notFound: <Message { ...empty } />,
      filter: { q },
      layout: Results,
      props: {
        onArticle
      }
    }
  }

  _handleClose() {
    this.props.onClose()
  }

  _handleType(q) {
    this.setState({ q })
  }

}

export default Articles
