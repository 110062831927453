import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    content: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { content } = this.props
    return {
      title: 't(Edit Email Article)',
      method: 'PATCH',
      endpoint: `/api/admin/truevail/agency/content/${content.id}/edit`,
      action: `/api/admin/truevail/agency/content/${content.id}`,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', required: true },
            { label: 't(Voice)', type: 'segment', fields: [
              { label: 'Perspective', type: 'dropdown', name: 'perspective', options: [
                { value: 'first-person plural', text: 't(First Person Plural)' },
                { value: 'first-person singular', text: 't(First Person Singular)' }
              ], defaultValue: 'first-person plural', required: true },
              { label: 'Language', type: 'dropdown', name: 'language', options: [
                { value: 'american english', text: 't(English) (t(American))' },
                { value: 'canadian english', text: 't(English) (t(Canadian))' },
                { value: 'french', text: 't(French)' },
                { value: 'german', text: 't(German)' },
                { value: 'italian', text: 't(Italian)' }
              ], defaultValue: 'american english', required: true }
            ], required: true },
            { label: 't(Tags)', name: 'tags', type: 'tagfield', endpoint: '/api/admin/tags' }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(agency) {
    this.context.modal.close()
  }

}

export default New
