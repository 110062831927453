import MJSONScreenshot from '@admin/components/mjson_screenshot'
import Date from '@admin/components/date'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import React from 'react'

const format = (rate) => {
  return rate > 0 ? numeral(rate).format('0.00%') : '0%'
}

const getScreenshot = (entity) => ({
  engine: entity.engine === 'mblocks' ? 'mblocks' : 'mjson',
  entity: `campaigns_email_campaigns/${entity.code}`,
  width: 100,
  height: 75,
  screenshoted_at: entity.screenshoted_at
})

const CompactCampaignToken = ({ email_campaign, compact }) => (
  <div className="campaign-token">
    <div className="campaign-token-preview">
      <div className="campaign-token-preview-inner">
        <MJSONScreenshot { ...getScreenshot(email_campaign) } />
      </div>
    </div>
    <div className="campaign-token-label">
      <div className="token-link">
        { email_campaign.title }
      </div>
      <div className="token-stats">
        <span className={ email_campaign.status }>{ email_campaign.status }</span>
        { email_campaign.status === 'scheduled' &&
          <span><Date datetime={ email_campaign.send_at } /></span>
        }
        { email_campaign.status === 'sent' &&
          <>
            <span>{ email_campaign.sent } sent</span>
            <span>{ format(email_campaign.open_rate) } open rate</span>
            <span>{ format(email_campaign.click_rate) } click rate</span>
            <span>{ format(email_campaign.bounce_rate) } bounce rate</span>
          </>
        }
      </div>
    </div>
  </div>
)

CompactCampaignToken.propTypes = {
  email_campaign: PropTypes.object,
  compact: PropTypes.bool
}

export default CompactCampaignToken
