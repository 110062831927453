import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const purposes = {
  now: {
    title: 't(Post immediately)',
    text: 't(Post this social campaign now)'
  },
  schedule: {
    title: 't(Schedule post)',
    text: 't(Schedule this social campaign to be posted at a later date)'
  },
  later: {
    title: 't(Schedule later)',
    text: 't(Don\'t post this social campaign now. You can schedule it later)'
  }
}

const SchedulingStrategyToken = ({ value }) => (
  <div className="token scheduling-token">
    <strong><T text={ purposes[value].title } /></strong><br />
    <T text={ purposes[value].text } />
  </div>
)

SchedulingStrategyToken.propTypes = {
  value: PropTypes.string
}

export default SchedulingStrategyToken
