import Button from '@admin/components/button'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import ColorToken from './color'
import LogoToken from './logo'
import React from 'react'

class Branding extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    configuration: PropTypes.object,
    onBack: PropTypes.func,
    onDone: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: null
  }

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    if(!this.state.config) return null
    return <Form { ...this._getForm() } />
  }

  componentDidMount() {
    const { configuration } = this.props
    this.setState({
      config: configuration
    })
  }

  _getForm() {
    const { config } = this.state
    return {
      ref: this.formRef,
      title: 't(Branding)',
      cancelIcon: 'chevron-left',
      saveText: null,
      buttons: [
        { label: 't(Done)', color: 'blue', handler: this._handleSubmit }
      ],
      onCancel: this._handleBack,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Logo)', instructions: 't(Your logo will be used across various services for consistent branding)', type: 'segment', fields: [
              { name: 'logo_strategy', type: 'radiogroup', options: ['upload','design','text'], format: LogoToken, defaultValue: config.logo_strategy || 'upload' },
              { name: 'logo_id', show: config.logo_strategy === 'upload', type: 'attachmentfield', prompt: 't(Upload Logo)', allow: { content_types: ['images'] }, multiple: false, required: true, defaultValue: config.logo_id },
            ] },
            { label: 't(Color Scheme)', instructions: 't(Describe the primary colors of your business branding, which will be used in your marketing materials)', type: 'segment', fields: [
              { name: 'color_strategy', type: 'radiogroup', options: ['choose','design'], format: ColorToken, defaultValue: config.color_strategy || 'choose' },
              { type: 'text', show: config.color_strategy === 'choose', value: null, format: () => (
                <>View our <Button { ...this._getColors() } /> to help you choose a specific color or specify a generic color description</>
              ) },
              { label: 't(Brand Color 1)', name: 'color1', show: config.color_strategy === 'choose', type: 'textfield', placeholder: 't(Enter Brand Color 1 (name, hex, or rgb))', defaultValue: config.color1 },
              { label: 't(Brand Color 2)', name: 'color2', show: config.color_strategy === 'choose', type: 'textfield', placeholder: 't(Enter Brand Color 2 (name, hex, or rgb))', defaultValue: config.color2 },
              { label: 't(Brand Color 3)', name: 'color3', show: config.color_strategy === 'choose', type: 'textfield', placeholder: 't(Enter Brand Color 3 (name, hex, or rgb))', defaultValue: config.color3 }  
            ] },
            { label: 't(Brand Book / Style Guide)', instructions: 't(If available, upload your brand book or style guide to ensure consistency in marketing efforts)', name: 'style_guide_id', type: 'attachmentfield', prompt: 'Upload Document', multiple: false, defaultValue: config.style_guide_id }
          ]
        }
      ]
    }
  }

  _getColors() {
    return {
      label: 'Color Choice Helper',
      className: 'link',
      link: '/imagecache/images/color-choice-helper.jpg'
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(config) {
    this.props.onDone(config)
  }

}

export default Branding
