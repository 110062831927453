import SocialPreview from '@admin/components/social_preview'
import ModalPanel from '@admin/components/modal_panel'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {
    social_campaign: PropTypes.object,
    post_at: PropTypes.string
  }

  formRef = React.createRef()

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  constructor(props) {
    super(props)
    const { social_campaign } = props
    const post_at = props.post_at || social_campaign.post_at || moment().add(1, 'day').format('YYYY-MM-DD [09:00]')
    this.state = {
      config: {
        date: moment(post_at).format('YYYY-MM-DD'),
        time: moment(post_at).format('HH:mm')
      }
    }
  }

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="campaigns-social-schedule-edit">
          <div className="campaigns-social-schedule-preview">
            <SocialPreview { ... this._getSocialPreview() } />
          </div>
          <div className="campaigns-social-schedule-edit-form">
            <Form { ...this._getForm() } />
          </div>
        </div>
      </ModalPanel>
    )
  }

  _getSocialPreview() {
    const { social_campaign } = this.props
    return { social_campaign }    
  }

  _getForm() {
    const { config } = this.state
    const { social_campaign } = this.props
    return {
      ref: this.formRef,
      action: `/api/admin/campaigns/social/${social_campaign.id}/schedule`,
      method: 'PATCH',
      showHeader: false,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { type: 'segment', fields: [
              { name: 'strategy', type: 'radiogroup', options: [
                { value: 'schedule', text: 't(Schedule Social Campaign)' },
                { value: 'now', text: 't(Post Immediately)' },
                ...social_campaign.status === 'scheduled' ? [
                  { value: 'unschedule', text: 't(Unschedule Social Campaign)' }
                ] : []
              ], defaultValue: social_campaign.status === 'scheduled' ? 'unschedule' : 'schedule', required: true },
              { name: 'post_at', type: 'datetimefield', show: config.strategy === 'schedule', required: true }
            ] }
          ]
        }
      ]
    }
  }

  _getPanel() {
    return {
      title: 't(Post Campaign)',
      leftItems: [
        { label: 't(Cancel)', handler: this._handleCancel }
      ],
      rightItems: [
        { label: 't(Save)', handler: this._handleSubmit }
      ]
    }
  }

  _getScreenshot(entity) {
    return {
      engine: 'mjson',
      entity: `campaigns_social_campaign_variants/${entity.code}`,
      width: 400,
      height: 300,
      screenshoted_at: entity.screenshoted_at,
      updated_at: entity.updated_at
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Edit
