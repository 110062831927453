import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Senders)',
  collection: {
    endpoint: '/api/admin/truevail/agency/senders',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Sender)', key: 'rfc822' },
      { label: 't(Email Verification)', key: 'email_status', primary: true, collapsing: true, format: (sender) => (
        <StatusToken status={ sender.email_status } />
      ) },
      { label: 't(On Behalf Of Verification)', key: 'on_behalf_of_status', primary: true, collapsing: true, format: (sender) => {
        return <StatusToken status={ sender.on_behalf_of ? sender.on_behalf_of_status : 'not applicable' } />
      } },
      { label: 't(Domain Verification)', key: 'sender_domain.domain_status', sort: 'domain_status', primary: true, collapsing: true, format: (sender) => {
        return <StatusToken status={ sender.sender_domain ? sender.sender_domain.domain_status : 'not applicable' } />
      } }
    ],
    criteria: {
      fields: [
        { label: 't(Sender)', fields: [
          { name: 'Sender Type', key: 'type', type: 'select', options: ['hosted','custom'] },
          { name: 'Email Verification', key: 'email_status', type: 'select', options: ['not sent','pending','expired','verified'] },
          { name: 'On Behalf Of Verification', key: 'on_behalf_of_status', type: 'select', options: ['not sent','pending','expired','verified'] },
          { name: 'Domain Verification', key: 'domain_status', type: 'select', options: ['pending','expired','verified','unverified'] }
        ] }
      ]
    },
    defaultSort: { key: 'name', order: 'asc' },
    empty: {
      title: 't(No Senders)',
      text: 't(There are no senders for your advisors)',
      icon: 'paper-plane-o'
    },
    entity: 'sender',
    onClick: (record) => {
      const { provider, host } = context
      host.window.open(`${provider.admin_host}/${record.team.subdomain}/team/senders/${record.id}`)
    }
  }
})

export default Panel(null, mapPropsToPanel)
