import EmailCampaignToken from '@apps/campaigns/admin/tokens/campaign/email'
import ProgramForm from '@apps/crm/admin/components/programform'
import Page from '@admin/components/page'
import Clone from './clone'
import React from 'react'
import New from './new'

const mapPropsToPage = (props, context) => ({
  title: 't(Email Campaigns)',
  access: { rights: { $oneOf: ['campaigns:view_email_campaigns','campaigns:manage_email_campaigns'] } },
  collection: {
    endpoint: '/api/admin/campaigns/email',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true, format: (email_campaign) => (
        <EmailCampaignToken email_campaign={ email_campaign } />
      ) },
      ...context.admin.team.has_programs ? [
        { label: 't(Program)', key: 'program.title', sort: 'program' }
      ] : []
    ],
    criteria: {
      fields: [
        { label: 't(Email Campaign)', fields: [
          { name: 't(Program)', key: 'program_id', type: 'select', endpoint: '/api/admin/programs', textKey: 'title', valueKey: 'id' }
        ] }
      ]
    },
    export: [
      { label: 't(ID)', key: 'id' },
      { label: 't(Title)', key: 'title' },
      { label: 't(Program)', key: 'program.title' },
      { label: 't(Sent)', key: 'sent' },
      { label: 't(Open Rate)', key: 'open_rate' },
      { label: 't(Click Rate)', key: 'click_rate' },
      { label: 't(Bounce Rate)', key: 'bounce_rate' },
      { label: 't(Status)', key: 'status' }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      icon: 'envelope-o',
      title: 't(No Email Campaigns)',
      text: 't(You have not yet created any email campaigns)',
      buttons: [
        { 
          label: 't(Create Email Campaign)', 
          access: { rights: { $oneOf: ['campaigns:manage_email_campaigns'] } },
          modal: <ProgramForm form={ New } props={{ fields: props.fields }} /> 
        }
      ]
    },
    recordTasks: (record) => [
      {
        label: 't(Clone Campaign)',
        access: {
          programs: { $canEdit: record.program.id },
          rights: { $oneOf: ['campaigns:manage_email_campaigns'] }
        },
        show: record.is_spam === false,
        modal: <Clone email_campaign={ record } />
      },
      {
        label: 't(Design Email)',
        access: {
          programs: { $canEdit: record.program.id },
          rights: { $oneOf: ['campaigns:manage_email_campaigns'] }
        },
        route: `/campaigns/${record.type}/${record.id}/design`
      }
    ],
    entity: 'campaign',
    onClick: (record) => context.router.push(`/campaigns/${record.type}/${record.id}`)
  },
  task: {
    icon: 'plus',
    tooltip: 't(Create Email Campaign)', 
    access: { 
      rights: { $oneOf: ['campaigns:manage_email_campaigns'] } 
    },
    modal: <ProgramForm form={ New } props={{ fields: props.fields }} />
  }
})

export default Page(null, mapPropsToPage)
