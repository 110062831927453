import SignupPageToken from '@apps/maha/admin/tokens/signup_page'
import Panel from '@admin/components/panel'
import React from 'react'
import New from './new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Signup Pages)',
  collection: {
    endpoint: '/api/admin/truevail/admin/signup_pages',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true, format: (signup_page) => (
        <SignupPageToken signup_page={ signup_page } />
      ) }
    ],
    defaultSort: { key: 'title', order: 'desc' },
    empty: {
      icon: 'file-o',
      title: 't(No Signup Pages)',
      text: 't(You have not yet created any signup pages)',
      buttons: [
        { label: 't(Create Signup Page)', modal: New }
      ]
    },
    entity: 'signup',
    onClick: (record) => context.router.push(`/admin/truevail/admin/signup_pages/${record.id}`)
  },
  task: {
    icon: 'plus',
    modal: New
  }
})

export default Panel(null, mapPropsToPanel)
