import Page from '@admin/components/page'
import Overview from './overview'
import Design from './design'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.signup_page.title,
  manager: {
    path: `/admin/truevail/admin/signup_pages/${resources.signup_page.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview signup_page={ resources.signup_page } />
      },
      {
        label: 't(Design)',
        path: '/design',
        panel: <Design signup_page={ resources.signup_page } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  signup_page: `/api/admin/truevail/admin/signup_pages/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
