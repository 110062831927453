import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const types = [
  { bounce_type: 'Undetermined', bounce_subtype: 'Undetermined', text: 't(Unknown)' },
  { bounce_type: 'Permanent', bounce_subtype: 'NoEmail', text: 't(Non-existant)' },
  { bounce_type: 'Permanent', bounce_subtype: 'Suppressed', text: 't(Suppressed)' },
  { bounce_type: 'Permanent', bounce_subtype: 'OnAccountSuppressionList', text: 't(Suppressed)' },
  { bounce_type: 'Permanent', bounce_subtype: 'General', text: 't(Undeliverable)' },
  { bounce_type: 'Transient', bounce_subtype: 'General', text: 't(Other)' },
  { bounce_type: 'Transient', bounce_subtype: 'MailboxFull', text: 't(Mailbox Full)' },
  { bounce_type: 'Transient', bounce_subtype: 'MessageTooLarge', text: 't(Message Too Large)' },
  { bounce_type: 'Transient', bounce_subtype: 'ContentRejected', text: 't(Content Rejected)' },
  { bounce_type: 'Transient', bounce_subtype: 'ContentRejected', text: 't(Attachment Rejected)' }
]

const BounceTypeToken = ({ bounce_type, bounce_subtype }) => {
  const type = _.find(types, { bounce_type, bounce_subtype }) || { bounce_type: 'Undetermined', text: 't(Unknown)' }
  return (
    <div className={`bounce-type-token ${bounce_type.toLowerCase()}`}>
      <T text={ type.text } />
    </div>
  )
}

BounceTypeToken.propTypes = {
  bounce_type: PropTypes.string,
  bounce_subtype: PropTypes.string
}

export default BounceTypeToken
