import Configuration from '../configuration'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    advisor: PropTypes.object,
    configuration: PropTypes.object,
    onBack: PropTypes.func,
    onDone: PropTypes.func
  }

  _handleBack = this._handleBack.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { advisor, configuration } = this.props
    return {
      title: 't(New List)',
      method: 'POST',
      action: `/api/admin/truevail/agency/advisors/${advisor.id}/lists`,
      cancelIcon: 'chevron-left',
      onCancel: this._handleBack,
      onSuccess: this._handleSuccess,
      ...advisor.signup ? {
        before: <Configuration configuration={ configuration } fields={['email_strategy','contacts_id']} />
      } : {},
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', defaultValue: 'Newsletter', required: true }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleSuccess() {
    this.props.onDone()
  }

}

export default New
