import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import VoiceToken from './voice'
import PhotoToken from './photo'
import React from 'react'

class Personalization extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {
    configuration: PropTypes.object,
    onBack: PropTypes.func,
    onDone: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: null
  }

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    if(!this.state.config) return null
    return <Form { ...this._getForm() } />
  }

  componentDidMount() {
    const { configuration } = this.props
    this.setState({
      config: configuration
    })
  }

  _getForm() {
    const { team } = this.context.admin
    const { config } = this.state
    return {
      ref: this.formRef,
      title: 't(Personalization)',
      cancelIcon: 'chevron-left',
      saveText: null,
      buttons: [
        { label: 't(Done)', color: 'blue', handler: this._handleSubmit }
      ],
      onCancel: this._handleBack,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Photo)', instructions: 't(Upload a professional or travel photo of yourself, which may be used for personalized marketing materials)', type: 'segment', fields: [
              { name: 'photo_strategy', type: 'radiogroup', options: ['upload','optout'], defaultValue: config.photo_strategy || 'upload', format: PhotoToken },
              { name: 'photo_id', show: config.photo_strategy === 'upload', type: 'attachmentfield', prompt: 'Upload Photo', allow: { content_types: ['images'] }, multiple: false, required: true, defaultValue: config.photo_id }                
            ], require: true },
            { label: 't(Public Domain)', instructions: 't(By default, we have created the domain address that will be used for your email newsletters, forms, landing pages and sharing links. You may edit this if you prefer a different option)', name: 'public_domain', type: 'domainfield', provider_id: 2, required: true, defaultValue: config.public_domain || team.public_domain },
            { label: 't(Voice)', instructions: 't(Select the style of voice of your content)', name: 'voice', type: 'radiogroup', options: ['individual_american','agency_american','individual_canadian','agency_canadian'], defaultValue: config.voice || 'individual_american', format: VoiceToken, required: true },
            { label: 't(Timezone)', instructions: 't(Select the timezone you prefer your content to be scheduled in)', name: 'timezone_id', type: 'lookup', endpoint: '/api/admin/timezones', valueKey: 'id', textKey: 'zone', defaultValue: config.timezone_id, required: true }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(config) {
    this.props.onDone(config)
  }

}

export default Personalization
