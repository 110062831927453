import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {
    agency: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { team } = this.context.admin
    const { agency } = this.props
    return {
      title: 't(Edit Agency)',
      method: 'PATCH',
      endpoint: `/api/admin/truevail/admin/agencies/${agency.id}/edit`,
      action: `/api/admin/truevail/admin/agencies/${agency.id}`,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', required: true },
            { label: 't(Logo)', name: 'logo_id', type: 'attachmentfield', allow: { types: ['photos'] }, multiple: false },
            { label: 't(Public Domain)', name: 'public_domain', type: 'domainfield', provider_id: 2, required: true },
            { label: 't(Advisor Domains)', name: 'domain_ids', type: 'checkboxgroup', endpoint: '/api/admin/domains', provider_id: team.provider.id, valueKey: 'id', textKey: 'name', multiple: true, required: true },
            { label: 't(Max Advisors)', name: 'advisors_max', type: 'numberfield', placeholder: 't(Leave blank for unlimited)' }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(agency) {
    this.context.modal.close()
  }

}

export default New
