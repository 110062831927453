import PaletteField from '@admin/components/mjson_designer/components/palettefield'
import FontsField from '@admin/components/mjson_designer/components/fontsfield'
import TypographyToken from './typography/token'
import EditTypography from './typography/edit'
import NewTypography from './typography/new'
import Form from '@admin/components/form'
import ButtonToken from './buttons/token'
import EditButton from './buttons/edit'
import NewButton from './buttons/new'
import PropTypes from 'prop-types'
import React from 'react'

class Theme extends React.PureComponent {

  static contextTypes = {
    form: PropTypes.object
  }

  static propTypes = {
    device: PropTypes.string,
    theme: PropTypes.string,
    type: PropTypes.string,
    version: PropTypes.object,
    website: PropTypes.object,
    onChange: PropTypes.func,
    onDone: PropTypes.func
  }

  formRef = React.createRef()

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  constructor(props) {
    super(props)
    const { version } = props
    this.state = {
      default: version.current,
      config: version.current.theme

    }
  }

  render() {
    return <Form { ...this._getForm() } />
  }

  componentDidUpdate(prevProps, prevState) {
    const { config } = this.state
    if(!_.isEqual(config, prevState.config)) {
      this._handleChange()
    }
    if(!_.isEqual(this.props.theme, prevProps.theme)) {
      this._handleSet(this.props.theme)
    }
  }

  _getForm() {
    const { device } = this.props
    const { config } = this.state
    return {
      ref: this.formRef,
      onChange: this._handleUpdate,
      onSuccess: this._handleDone,
      title: 't(Theme)',
      cancelText: null,
      saveText: null,
      buttons: [
        { label: 't(Cancel)', color: 'blue', basic: true, handler: this._handleCancel },
        { label: 't(Done)', color: 'blue', handler: this._handleSubmit }
      ],
      sections: [
        {
          label: 't(Colors)',
          instructions: 't(Colorsets allow you to define complementary set of colors for your content.)',
          collapsing: true,
          collapsed: true,
          fields: [
            { name: 'palette', type: PaletteField, value: config.palette }
          ]
        },
        {
          label: 't(Fonts)',
          instructions: 't(Fontsets allow you to define a complementary set of fonts for your content.)',
          collapsing: true,
          collapsed: true,
          fields: [
            { name: 'fonts', type: FontsField, device, theme: config, value: config.fonts }
          ]
        },
        {
          label: 't(Typography)',
          instructions: 't(Typography presets allow you to define reuseable typography for your content.)',
          collapsing: true,
          collapsed: true,
          fields: [
            { name: 'typography', type: 'collectionfield', cloneable: false, entity: 'preset', device, theme: config, value: config.typography, newForm: NewTypography, editForm: EditTypography, token: TypographyToken }
          ]
        },
        {
          label: 't(Buttons)',
          instructions: 't(Typography presets allow you to define reuseable button styles for your content.)',
          collapsing: true,
          collapsed: true,
          fields: [
            { name: 'buttons', type: 'collectionfield', cloneable: false, entity: 'preset', device, theme: config, value: config.buttons, newForm: NewButton, editForm: EditButton, token: ButtonToken }
          ]
        }
      ]
    }
  }

  _getNormalized() {
    const { version } = this.props
    const { config } = this.state
    return {
      entity: version.current.entity,
      theme: config
    }
  }

  _handleCancel() {
    this.props.onDone(this.state.default)
  }

  _handleChange() {
    const normalized = this._getNormalized()
    this.props.onChange(normalized)
  }

  _handleDone() {
    const normalized = this._getNormalized()
    this.props.onDone(normalized)
  }

  _handleSet(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleUpdate(config) {
    this.setState({
      config: {
        ...this.state.config,
        ...config
      }
    })
  }

}

export default Theme
