import Content from '@apps/forms/admin/tokens/content'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import Details from '@admin/components/details'
import Transition from '../transition'
import PropTypes from 'prop-types'
import Edit from '../edit'
import React from 'react'

const Overview = ({ deal, fields, stages }) => {

  const contact = {
    label: deal.contact.display_name,
    className: 'link',
    route: `/admin/crm/contacts/${deal.contact.id}`
  }

  const pipeline = {
    label: deal.pipeline.title,
    className: 'link',
    route: `/admin/sales/pipelines/${deal.pipeline.id}`
  }

  const transition = {
    label: 't(change)',
    className: 'link',
    modal: <Transition deal={ deal } />
  }

  const details = {
    audits: `sales_deals/${deal.id}`,
    comments: `sales_deals/${deal.id}`,
    sections: [
      {
        title: 't(Deal Details)',
        items: [
          { label: 't(Title)', content: deal.title },
          { label: 't(Description)', content: deal.description },
          { label: 't(Contact)', content: <Button { ...contact } />  },
          { label: 't(Pipeline)', content: <Button { ...pipeline } />  },
          { label: 't(Stage)', content: (
            <>
              { deal.stage.title } (<Button { ...transition } />)
            </>
          ) },
          { label: 't(Owner)', content: deal.owner?.full_name  },
          { label: 't(Amount)', show: deal.pricing_type === 'fixed', content: deal.amount, format: 'currency' },
          { label: 't(Annual Recurring Revenue)', show: deal.pricing_type === 'recurring', content: deal.annual_recurring_revenue, format: 'currency' },
          { label: 't(Closed On)', show: !!deal.closed_at, content: deal.closed_at, format: 'date' },
          { label: 't(Closes On)', show: !deal.closed_at, content: deal.closes_on, format: 'date' },
          ...fields.filter(field => {
            return field.type !== 'text'
          }).map(field => ({
            label: field.name.value,
            content: <Content data={ deal.values } field={ field } />
          }))
        ]
      }
    ]
  }

  if(deal.deleted_at !== null) {
    details.alert = { color: 'red', message: 't(This deal was deleted)' }
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  deal: PropTypes.object,
  fields: PropTypes.array,
  pipeline: PropTypes.object,
  stages: PropTypes.array
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview deal={ props.deal } fields={ props.fields } stages={ props.stages } />,
  tasks: {
    items: [
      { label: 't(Edit Deal)', modal: <Edit deal={ props.deal } fields={ props.fields } /> },
      { label: 't(Change Stage)', modal: <Transition deal={ props.deal } /> },
      { 
        label: 't(Delete Deal)',
        confirm: 't(Are you sure you want to delete this deal?)',
        request: {
          endpoint: `/api/admin/sales/deals/${props.deal.id}`,
          method: 'DELETE',
          onSuccess: () => {
            context.router.goBack()
            context.flash.set('success', 't(The deal was successfully deleted)')
          },
          onFailure: () => this.context.flash.set('success', 't(Unable to delete this deal)')
        }
      }
    ]
  }
})

const mapResourcesToPanel = (props, context) => ({
  fields: `/api/admin/sales_pipelines/${props.pipeline.id}/fields`,
  stages: `/api/admin/sales/pipelines/${props.pipeline.id}/stages`
})

export default Panel(mapResourcesToPanel, mapPropsToPanel)
