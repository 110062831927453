import Configuration from '../configuration'
import UserToken from '@admin/tokens/user'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Advisor extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {
    advisor: PropTypes.object,
    configuration: PropTypes.object,
    onBack: PropTypes.func,
    onDone: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: {}
  }

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { team } = this.context.admin
    const { advisor, configuration } = this.props
    return {
      ref: this.formRef,
      title: 't(Edit Advisor)',
      method: 'PATCH',
      endpoint: `/api/admin/truevail/agency/advisors/${advisor.id}/edit`,
      action: `/api/admin/truevail/agency/advisors/${advisor.id}`,
      cancelIcon: 'chevron-left',
      saveText: null,
      buttons: [
        { label: 't(Done)', color: 'blue', handler: this._handleSubmit }
      ],
      onCancel: this._handleBack,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      before: <Configuration configuration={ configuration } fields={['business_name','business_name','logo_id','public_domain','timezone']} />,
      sections: [
        {
          fields: [
            { label: 't(Name)', name: 'name', type: 'textfield', required: false },
            { label: 't(Organization)', name: 'organization', type: 'textfield', required: false },
            { label: 't(Team Title)', name: 'title', type: 'textfield', required: false },
            { label: 't(Logo)', name: 'logo_id', type: 'attachmentfield', multiple: false },
            { label: 't(Public Domain)', name: 'public_domain', type: 'domainfield', provider_id: team.provider.id, required: true },
            { label: 't(Timezone)', name: 'timezone_id', type: 'lookup', endpoint: '/api/admin/timezones', valueKey: 'id', textKey: 'zone', required: true },
            { label: 't(Reviewers)', name: 'reviewer_ids', type: 'checkboxgroup', entity: 'user', endpoint: `/api/admin/truevail/agency/advisors/${advisor.id}/users`, valueKey: 'id', textKey: 'full_name', filter: { is_active: { $eq: true }, type: { $eq: 'standard' } }, multiple: true, deselectable: true, format: UserToken }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(config) {
    this.props.onDone(config)
  }

}

export default Advisor
