export const onChooseTeam = (id, redirect) => ({
  type: 'CHOOSE_TEAM',
  id,
  redirect
})

export const onSetAccount = (account) => ({
  type: 'SET_ACCOUNT',
  account
})

export const onSetActive = (active) => ({
  type: 'SET_ACTIVE',
  active
})

export const onSetRedirect = (redirect) => ({
  type: 'SET_REDIRECT',
  redirect
})

export const onSetSession = (session) => ({
  type: 'SET_SESSION',
  apps: session.apps,
  features: session.features,
  ip: session.ip,
  programs: session.programs,
  rights: session.rights,
  team: session.team,
  token: session.token,
  user: session.user,
  session
})

export const onSetTeams = (teams) => ({
  type: 'SET_TEAMS',
  teams
})

export const onSignin = (account) => ({
  type: 'SIGNIN',
  account
})

export const onSignout = () => ({
  type: 'SIGNOUT'
})
