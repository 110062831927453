import Searchbox from '@admin/components/searchbox'
import Infinite from '@admin/components/infinite'
import PropTypes from 'prop-types'
import Results from './results'
import React from 'react'

class List extends React.Component {

  static propTypes = {
    contact: PropTypes.object,
    deal: PropTypes.object,
    email: PropTypes.object,
    email_campaign: PropTypes.object,
    order: PropTypes.object,
    program: PropTypes.object,
    registration: PropTypes.object,
    response: PropTypes.object,
    selected: PropTypes.object,
    subscription: PropTypes.object,
    usage: PropTypes.object,
    user: PropTypes.object,
    onChoose: PropTypes.func
  }

  state = {
    q: ''
  }

  _handleType = this._handleType.bind(this)

  render() {
    return (
      <div className="email-reader-list">
        <Searchbox { ...this._getSearchbox() } />
        <Infinite { ...this._getInfinite() } />
      </div>
    )
  }

  _getInfinite() {
    const { contact, deal, email, email_campaign, order, program, registration, response, subscription, selected, usage, user, onChoose } = this.props
    const perspective = this._getPerspective()
    const { q } = this.state
    return {
      endpoint: '/api/admin/emails',
      query: {
        ...usage ? { usage_id: usage.id } : {},
        ...deal ? { deal_id: deal.id } : {},
        ...email ? { email_id: email.id } : {},
        ...email_campaign ? { email_campaign_id: email_campaign.id } : {},
        ...contact ? { contact_id: contact.id } : {},
        ...order ? { order_id: order.id } : {},
        ...program ? { program_id: program.id } : {},
        ...registration ? { registration_id: registration.id } : {},
        ...response ? { response_id: response.id } : {},
        ...subscription ? { subscription_id: subscription.id } : {},
        ...user ? { user_id: user.id } : {}
      },
      filter: {
        ...q.length > 0 ? { q } : {}
      },
      layout: Results,
      props: {
        perspective,
        selected,
        onChoose
      }
    }
  }

  _getPerspective() {
    const { contact, deal, email_campaign, order, program, registration, response, subscription, usage, user } = this.props
    if(email_campaign) return 'program'
    if(subscription) return 'program'
    if(registration) return 'program'
    if(response) return 'program'
    if(program) return 'program'
    if(contact) return 'contact'
    if(usage) return 'contact'
    if(order) return 'program'
    if(deal) return 'program'
    if(user) return 'user'
  }

  _getSearchbox() {
    return {
      prompt: 'Search',
      onChange: this._handleType
    }
  }

  _handleType(q) {
    this.setState({ q })
  }

}

export default List
