import ProgramForm from '@apps/crm/admin/components/programform'
import ContactToken from '@apps/crm/admin/tokens/contact'
import New from '@apps/finance/admin/views/credits/new'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Credits)',
  access: { rights: { $oneOf: ['finance:view_revenue','finance:manage_revenue'] } },
  collection: {
    endpoint: '/api/admin/finance/credits',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Contact)', key: 'customer.display_name', sort: 'customer', primary: true, format: ({ customer }) => {
        return <ContactToken { ...customer } />
      } },
      ...context.admin.team.has_programs ? [
        { label: 't(Program)', key: 'program.title', sort: 'program', collapsing: true }
      ] : [],
      { label: 't(Balance)', key: 'balance', collapsing: true, primary: true, align: 'right', format: (credit) => (
        <>{credit.balance} / {credit.amount}</>
      ) }
    ],
    empty: {
      title: 't(No Credits)',
      text: 't(There are no credits for this contact)',
      icon: 'dollar',
      buttons: [{
        label: 't(Create Credit)',
        access: { rights: { $oneOf: ['finance:manage_revenue'] } },
        modal: <ProgramForm form={ New } />
      }]
    },
    entity: 'payments',
    defaultSort: { key: 'created_at', order: 'desc' },
    onClick: (record) => context.router.push(`/admin/finance/credits/${record.id}`)
  },
  task: {
    icon: 'plus',
    tooltip: 't(Create Credit)',
    access: { rights: { $oneOf: ['finance:manage_revenue'] } },
    modal: <ProgramForm form={ New } />
  }
})

export default Panel(null, mapPropsToPanel)
