import ModalPanel from '@admin/components/modal_panel'
import CopyText from '@admin/components/copytext'
import Img from '@admin/components/html/img'
import PropTypes from 'prop-types'
import React from 'react'

class QRCode extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {
    shortlink: PropTypes.object
  }

  _handleClose = this._handleClose.bind(this)

  render() {
    const { shortlink } = this.props
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="maha-shortlink-token-modal">
          <div className="maha-shortlink-token-modal-qr">
            <Img src={`${shortlink.short_url}.png`} />
          </div>
          <div className="maha-shortlink-token-modal-link">
            <CopyText text={`${shortlink.short_url}.png`} />
          </div>
        </div>
      </ModalPanel>
    )
  }

  _getPanel() {
    return {
      title: 't(QR Code)',
      rightItems: [
        { label: 't(Done)', handler: this._handleClose }
      ]
    }
  }

  _handleClose() {
    this.context.modal.close()
  }

}

export default QRCode
