import ResponsiveField from '@admin/components/mjson_designer/components/responsivefield'
import OptionsField from '@admin/components/form/optionsfield'
import Img from '@admin/components/html/img'
import { normalizeConfig } from '../utils'
import Form from '@admin/components/form'
import * as styles from '../styles'
import settings from '../settings'
import PropTypes from 'prop-types'
import React from 'react'

const Options = (props) => <ResponsiveField { ...props } component={ OptionsField } />

class EditGalleryBlock extends React.Component {

  static propTypes = {
    device: PropTypes.string,
    entity: PropTypes.object,
    theme: PropTypes.object,
    type: PropTypes.string,
    website: PropTypes.object,
    onChange: PropTypes.func,
    onDone: PropTypes.func
  }

  formRef = React.createRef()

  _handleCancel = this._handleCancel.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  constructor(props) {
    super(props)
    const { entity } = props
    this.state = {
      default: entity,
      config: entity
    }
  }

  render() {
    return <Form { ...this._getForm() } />
  }

  componentDidUpdate(prevProps, prevState) {
    const { config } = this.state
    if(!_.isEqual(config, prevState.config)) {
      this._handleChange()
    }
  }

  _getForm() {
    const { device, entity, theme, type, website } = this.props
    const { config } = this.state
    return {
      ref: this.formRef,
      onChange: this._handleUpdate,
      onSuccess: this._handleDone,
      showHeader: false,
      buttons: [
        { label: 't(Cancel)', color: 'blue', basic: true, handler: this._handleCancel },
        { label: 't(Done)', color: 'blue', handler: this._handleSubmit }
      ],
      tabs: [
        {
          label: 't(Content)',
          sections: [
            {
              fields: [
                { label: 'Items', name: 'content.items', type: 'collectionfield', fields: [
                  { label: 't(Image)', name: 'image', type: 'mediafield', ratio: 16/9, website, required: true },
                  { label: 't(Caption)', name: 'caption', type: 'textarea' }
                ], token: ({ value }) => (
                  <div className="token">
                    <Img src={`/imagecache/fit=cover&w=200&h=100${value.image.asset.path}`} width="200" height="100" />
                  </div>
                ), defaultValue: [], value: config.content.items }
              ]
            },
            {
              label: 'Options',
              fields: [
                { label: 't(Display)', name: 'content.display', type: 'radiogroup', options: [
                  { value: 'grid', text: 't(Grid)' },
                  { value: 'stacked', text: 't(Stacked)' },
                  { value: 'slider', text: 't(Slider)' }
                ], defaultValue: 'grid', value: config.content.display },
                { label: 't(Columns)', name: 'content.columns', type: Options, options: [1,2,3,4,5,6,7,8], canHover: false, include: config.content.display !== 'stacked', defaultValue: 3, value: config.content.columns }
              ]
            }
          ]
        },
        {
          label: 't(Styles)',
          sections: [
            {
              label: 't(Item)',
              instructions: 't(Specify how items behave within this gallery)',
              collapsing: true,
              collapsed: true,
              fields: [
                { label: 't(Gap)', name: 'styles.gap', device, type: styles.Spacing, value: config.styles.gap }
              ]
            },
            styles.SliderSection(config, device, theme, website, type),
            styles.BackgroundSection(config, device, theme, website, type),
            styles.BoxShadowSection(config, device, theme, website, type),
            styles.BorderSection(config, device, theme, website, type),
            styles.SizingSection(config, device, theme, website, type, true, true),
            styles.SpacingSection(config, device, theme, website, type),
            styles.TransformSection(config, device, theme, website, type),
            styles.AnimationSection(config, device, theme, website, type),
            styles.FiltersSection(config, device, theme, website, type),
            styles.OverflowSection(config, device, theme, website, type),
            styles.VisibilitySection(config, device, theme, website, type)
          ]
        },
        {
          label: 't(Settings)',
          sections: settings(config, type, entity)
        }
      ]
    }
  }

  _getNormalized() {
    const { entity } = this.props
    const { config } = this.state
    return normalizeConfig({
      ...entity,
      meta: config.meta,
      settings: config.settings,
      styles: config.styles,
      content: config.content
    })
  }

  _handleCancel() {
    this.props.onDone(this.state.default)
  }

  _handleChange() {
    const config = this._getNormalized()
    this.props.onChange(config)
  }

  _handleDone() {
    const config = this._getNormalized()
    this.props.onDone(config)
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleUpdate(config) {
    this.setState({
      config: {
        ...this.state.config,
        ...config
      }
    })
  }

}

export default EditGalleryBlock
