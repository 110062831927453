import PropTypes from 'prop-types'
import React from 'react'

const Icon = (props) => {

  const { icon, ...additionalProps } = props

  const mergedProps = {
    ...additionalProps,
    className: `fa fa-${icon}`

  }

  return (
    <div className="maha-icon">
      <i { ...mergedProps } />
    </div>
  )

}

Icon.propTypes = {
  icon: PropTypes.string
}

export default Icon