import Personalization from '../configure/personalization'
import ModalPanel from '@admin/components/modal_panel'
import Additional from '../configure/additional'
import Img from '@admin/components/html/img'
import Branding from '../configure/branding'
import Identity from '../configure/identity'
import Website from '../configure/website'
import List from '@admin/components/list'
import Social from '../configure/social'
import Email from '../configure/email'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'
import _ from 'lodash'

class Overview extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    requirements: PropTypes.object,
    configuration: PropTypes.object,
    onDone: PropTypes.func,
    onTask: PropTypes.func
  }

  _handleDone = this._handleDone.bind(this)
  _handleTask = this._handleTask.bind(this)
  
  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="truevail-configure-overview">
          <List { ...this._getList() } />
        </div>
      </ModalPanel>
    )
  }

  _getCompleted() {
    const { configuration } = this.props
    const tasks = this._getTasks()
    return tasks.reduce((completed, task) => {
      return !completed ? false : configuration[task.status] === 'completed'
    }, true)
  }

  _getList() {
    const { user } = this.context.admin
    const { configuration } = this.props
    const tasks = this._getTasks()
    return {
      items: tasks,
      header: (
        <div className="truevail-configure-overview-header">
          <div className="truevail-configure-overview-header-image">
            <Img src="https://assets.mahaplatform.com/imagecache/fit=cover&w=1000&h=250/assets/08dab79852a8a1440b5dd7ea8148b179/halaszbastya.jpg.webp" />
          </div>
          <h1>Hi {user.first_name},</h1>
          <p><T text="t(To proceed with setting up your requested services, we need some additional information from you. Please complete the requirements below.)" /></p>
        </div>
      ),
      format: (task) => (
        <div className="truevail-configure-task-token-details">
          <div className="truevail-configure-task-token-description">
            <strong><T text={ task.title } /></strong><br />
            <T text={ task.description } />
          </div>
          <div className={`truevail-configure-task-token-status ${configuration[task.status] || 'incomplete'}`}>
            { configuration[task.status] || 'incomplete' }
          </div>
        </div>
      ),
      onClick: this._handleTask
    }
  }

  _getPanel() {
    const completed = this._getCompleted()
    return {
      title: 't(Additional Setup)',
      buttons: [
        { label: 't(Complete Setup)', color: 'blue', disabled: !completed, handler: this._handleDone }
      ]
    }
  }

  _getStatus(status) {
    return status === 'completed' ? 'completed' : 'incomplete'
  }

  _getTasks() {
    const { requirements } = this.props
    return [
      { title: 't(Business Information)', code: 'identity', description: 't(Tell us about your business and how you can be found)', modal: Identity, status: 'identity_status' },
      { title: 't(Branding)', code: 'branding', description: 't(Tell us about your branding, so we can brand your content)',  modal: Branding, status: 'brand_status' },
      { title: 't(Personalization)', code: 'personalization', description: 't(Tell us your preferences, so we can personalize your content)',  modal: Personalization, status: 'personalization_status' },
      { title: 't(Email Newsletters)', code: 'email', description: 't(Select newsletter service preferences. Setup your mailing list and configure your sending frequency)', modal: Email, status: 'email_status' },
      { title: 't(Social Media)', code: 'social_media', description: 't(Select social media service preferences. Connect your social media accounts and configure your posting frequency)', modal: Social, status: 'social_status' },
      { title: 't(Website)', code: 'website', description: 't(Tell us about your website or view website design purchasing options)',  modal: Website, status: 'website_status' },
      { title: 't(Additional Information)', code: 'additional', description: 't(Provide any additional information you\'d like us to know)',  modal: Additional, status: 'additional_status' }
    ].filter(item => {
      return _.includes(requirements, item.code)
    })
  }

  _handleDone() {
    this.props.onDone()
  }

  _handleTask(task) {
    this.props.onTask(task)
  }

}

export default Overview
