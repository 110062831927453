import ModalPanel from '@admin/components/modal_panel'
import Personalization from './personalization'
import Img from '@admin/components/html/img'
import List from '@admin/components/list'
import Additional from './additional'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import Branding from './branding'
import Identity from './identity'
import Website from './website'
import Social from './social'
import Email from './email'
import React from 'react'

class Overview extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    configuration: PropTypes.object,
    onDone: PropTypes.func,
    onTask: PropTypes.func
  }

  _handleDone = this._handleDone.bind(this)
  _handleTask = this._handleTask.bind(this)
  
  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="truevail-configure-overview">
          <List { ...this._getList() } />
        </div>
      </ModalPanel>
    )
  }

  _getCompleted() {
    const { configuration } = this.props
    const tasks = this._getTasks()
    return tasks.reduce((completed, task) => {
      return !completed ? false : configuration[task.status] === 'completed'
    }, true)
  }

  _getList() {
    const { user } = this.context.admin
    const { configuration } = this.props
    const tasks = this._getTasks()
    return {
      items: tasks,
      header: (
        <div className="truevail-configure-overview-header">
          <div className="truevail-configure-overview-header-image">
            <Img src="https://assets.mahaplatform.com/imagecache/fit=cover&w=1000&h=250/assets/08dab79852a8a1440b5dd7ea8148b179/halaszbastya.jpg.webp" />
          </div>
          <h1>Welcome {user.first_name},</h1>
          <p>Thank you for joining Truevail! To ensure that we create content that truly represents your brand and preferences, we require some information to personalize your account. You may opt-in or out of our done-for-you services based on your markering needs.</p>
        </div>
      ),
      format: (task) => (
        <div className="truevail-configure-task-token-details">
          <div className="truevail-configure-task-token-description">
            <strong><T text={ task.title } /></strong><br />
            <T text={ task.description } />
          </div>
          <div className={`truevail-configure-task-token-status ${configuration[task.status] || 'incomplete'}`}>
            { configuration[task.status] || 'incomplete' }
          </div>
        </div>
      ),
      onClick: this._handleTask
    }
  }

  _getPanel() {
    const completed = this._getCompleted()
    return {
      title: 't(Account Setup)',
      buttons: [
        { label: 't(Complete Setup)', color: 'blue', disabled: !completed, handler: this._handleDone }
      ]
    }
  }

  _getStatus(status) {
    return status === 'completed' ? 'completed' : 'incomplete'
  }

  _getTasks() {
    return [
      { title: 't(Business Information)', description: 't(Tell us about your business and how you can be found)', modal: Identity, status: 'identity_status' },
      { title: 't(Branding)', description: 't(Tell us about your branding, so we can brand your content)',  modal: Branding, status: 'brand_status' },
      { title: 't(Personalization)', description: 't(Tell us your preferences, so we can personalize your content)',  modal: Personalization, status: 'personalization_status' },
      { title: 't(Email Newsletters)', description: 't(Select newsletter service preferences. Setup your mailing list and configure your sending frequency)', modal: Email, status: 'email_status' },
      { title: 't(Social Media)', description: 't(Select social media service preferences. Connect your social media accounts and configure your posting frequency)', modal: Social, status: 'social_status' },
      { title: 't(Website)', description: 't(Tell us about your website or view website design purchasing options)',  modal: Website, status: 'website_status' },
      { title: 't(Additional Information)', description: 't(Provide any additional information you\'d like us to know)',  modal: Additional, status: 'additional_status' }
    ]
  }

  _handleDone() {
    this.props.onDone()
  }

  _handleTask(task) {
    this.props.onTask(task)
  }

}

export default Overview
