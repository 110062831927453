import Stack from '@admin/components/stack'
import PropTypes from 'prop-types'
import Articles from './articles'
import Article from './article'
import React from 'react'

class Help extends React.Component {

  static contextTypes = {
    help: PropTypes.object
  }

  stackRef = React.createRef()

  _handleArticle = this._handleArticle.bind(this)
  _handleClose = this._handleClose.bind(this)
  _handlePop = this._handlePop.bind(this)
  _handlePush = this._handlePush.bind(this)

  render() {
    return (
      <div className="maha-help">
        <Stack { ...this._getStack() } />
      </div>
    )
  }

  _getArticles() {
    return {
      onArticle: this._handleArticle,
      onClose: this._handleClose
    }
  }

  _getArticle(id) {
    return {
      id,
      onArticle: this._handleArticle,
      onBack: this._handlePop
    }
  }

  _getStack() {
    return {
      display_name: 'help_articles',
      initial: [
        { component: Articles, props: this._getArticles() }
      ],
      ref: this.stackRef
    }
  }

  _handleArticle(id) {
    this._handlePush(Article, this._getArticle(id))
  }

  _handleClose() {
    this.context.help.pop()
  }

  _handlePop() {
    this.stackRef.current.pop()
  }

  _handlePush(component, props) {
    this.stackRef.current.push({ component, props })
  }


}

export default Help
