import SocialTypeToken from '@apps/truevail/admin/tokens/social_type'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Details extends React.PureComponent {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  state = {
    config: {}
  }

  formRef = React.createRef()

  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, disabled: true },
        { label: 't(Next)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', required: true },
            { label: 't(Type)', name: 'type', type: 'radiogroup', options: ['photo','video','link','story','reel'], format: SocialTypeToken, defaultValue: 'photo' },
            { label: 't(Voice)', type: 'segment', fields: [
              { label: 'Perspective', type: 'dropdown', name: 'perspective', options: [
                { value: 'first-person plural', text: 't(First Person Plural)' },
                { value: 'first-person singular', text: 't(First Person Singular)' }
              ], defaultValue: 'first-person plural', required: true },
              { label: 'Language', type: 'dropdown', name: 'language', options: [
                { value: 'american english', text: 't(English) (t(American))' },
                { value: 'canadian english', text: 't(English) (t(Canadian))' },
                { value: 'french', text: 't(French)' },
                { value: 'german', text: 't(German)' },
                { value: 'italian', text: 't(Italian)' }
              ], defaultValue: 'american english', required: true }
            ], required: true },
            { label: 't(Tags)', name: 'tags', type: 'tagfield', endpoint: '/api/admin/tags' }
          ]
        }
      ]
    }
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(social_campaign) {
    this.props.onNext(social_campaign)
  }

}

export default Details
