import Container from '@admin/components/container'
import Stack from '@admin/components/stack'
import PropTypes from 'prop-types'
import Overview from './overview'
import React from 'react'

class Setup extends React.PureComponent {

  static contextTypes = {
    flash: PropTypes.object,
    modal: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    advisor: PropTypes.object,
    configuration: PropTypes.object,
    signup: PropTypes.object
  }

  stackRef = React.createRef()

  _handleCancel = this._handleCancel.bind(this)
  _handlePop = this._handlePop.bind(this)
  _handlePush = this._handlePush.bind(this)
  _handleTask = this._handleTask.bind(this)

  render() {
    return <Stack { ...this._getStack() } />
  }

  componentDidMount() {
    this._handleOverview()
  }

  getProps() {
    const { advisor } = this.props
    return {
      advisor,
      onCancel: this._handleCancel,
      onTask: this._handleTask
    }
  }

  _getStack() {
    return {
      display_name: 'setup',
      ref: this.stackRef
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleDone(task, setup) {
    this._handlePop()
  }

  _handleOverview() {
    this._handlePush(Overview, this.getProps.bind(this))
  }

  _handlePop(index = -1) {
    this.stackRef.current.pop(index)
  }

  _handlePush(component, props) {
    this.stackRef.current.push({ component, props })
  }

  _handleTask(task) {
    const { advisor, configuration } = this.props
    this._handlePush(task.modal, {
      advisor,
      configuration,
      onBack: this._handlePop,
      onDone: this._handleDone.bind(this, task),
      onPush: this._handlePush
    })
  }

}

const mapResources = (props, context) => ({
  configuration: `/api/admin/truevail/agency/advisors/${props.advisor.id}/configuration`
})

export default Container(mapResources)(Setup)