import SubscriptionProfilesToken from '@apps/truevail/admin/tokens/subscription_profiles'
import TimeframeToken from '@apps/truevail/admin/tokens/timeframe'
import Button from '@admin/components/button'
import AssetToken from '@admin/tokens/asset'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import React from 'react'

class Configuration extends React.PureComponent {

  static propTypes = {
    configuration: PropTypes.object,
    fields: PropTypes.array,
    onDone: PropTypes.func,
    onTask: PropTypes.func
  }

  state = {
    expanded: false
  }

  _handleToggle = this._handleToggle.bind(this)
  
  render() {
    const { expanded } = this.state
    return (
      <div className="truevail-setup-configuration">
        <Button { ...this._getExpand() } />
        { expanded &&
          <Details { ...this._getDetails() } />
        }
      </div>
    )
  }

  _getDetails() {
    const { fields, configuration } = this.props
    return {
      sections: [
        {
          title: 't(Signup Configuration)',
          items: [
            { label: 't(Business Name)', show: _.includes(fields, 'business_name'), content: configuration.business_name },
            { label: 't(Agency / Host Agency)', show: _.includes(fields, 'host_agency'), content: configuration.host_agency },
            { label: 't(Consortia)', show: _.includes(fields, 'consortia'), content: configuration.consortia },
            { label: 't(Business Address)', show: _.includes(fields, 'business_address'), content: configuration.business_address ? configuration.business_address.description : null },
            { label: 't(Business Email)', show: _.includes(fields, 'business_email'), content: configuration.business_email },
            { label: 't(Business Phone)', show: _.includes(fields, 'business_phone'), content: configuration.business_phone },
            { label: 'Facebook', show: _.includes(fields, 'facebook'), content: configuration.facebook, format: 'link' },
            { label: 'Instagram', show: _.includes(fields, 'instagram'), content: configuration.instagram, format: 'link' },
            { label: 'LinkedIn', show: _.includes(fields, 'linkedin'), content: configuration.linkedin, format: 'link' },
            { label: 'YouTube', show: _.includes(fields, 'youtube'), content: configuration.youtube, format: 'link' },
            { label: 'Pinterest', show: _.includes(fields, 'pinterest'), content: configuration.pinterest, format: 'link' },
            { label: 'TikTok', show: _.includes(fields, 'tiktok'), content: configuration.tiktok, format: 'link' },
            { label: 'Threads', show: _.includes(fields, 'threads'), content: configuration.threads, format: 'link' },
            { label: 'Vimeo', show: _.includes(fields, 'vimeo'), content: configuration.vimeo, format: 'link' },
            { label: 'X', show: _.includes(fields, 'x'), content: configuration.x, format: 'link' },
            { label: 't(Logo Strategy)', show: _.includes(fields, 'logo_strategy'), content: configuration.logo_strategy },
            { label: 't(Logo)', show: _.includes(fields, 'logo_id'), content: configuration.logo_id ? <AssetToken { ...configuration.logo } preview={ true } /> : null },
            { label: 't(Color Strategy)', show: _.includes(fields, 'color_strategy'), content: configuration.color_strategy },
            { label: 't(Color 1)', show: _.includes(fields, 'color1'), content: configuration.color1 },
            { label: 't(Color 2)', show: _.includes(fields, 'color2'), content: configuration.color2 },
            { label: 't(Color 3)', show: _.includes(fields, 'color3'), content: configuration.color3 },
            { label: 't(Brand Book / Style Guide)', show: _.includes(fields, 'style_guide_id'), content: configuration.style_guide_id ? <AssetToken { ...configuration.style_guide } /> : null },
            { label: 't(Photo Strategy)', show: _.includes(fields, 'photo_strategy'), content: configuration.photo_strategy },
            { label: 't(Photo)', show: _.includes(fields, 'photo_id'), content: configuration.photo_id ? <AssetToken { ...configuration.photo } preview={ true } /> : null },
            { label: 't(Public Domain)', show: _.includes(fields, 'public_domain'), content: configuration.public_domain },
            { label: 't(Voice)', show: _.includes(fields, 'voice'), content: configuration.voice },
            { label: 't(Timezone)', show: _.includes(fields, 'timezone'), content: configuration.timezone },
            { label: 't(Email Strategy)', show: _.includes(fields, 'email_strategy'), content: configuration.email_strategy },
            { label: 't(Channels)', show: _.includes(fields, 'email_channels'), content: configuration.email_channel_ids },
            { label: 't(Scheduling)', show: _.includes(fields, 'email_scheduling'), content: configuration.email_scheduling },
            { label: 't(From Name)', show: _.includes(fields, 'from_name'), content: configuration.from_name },
            { label: 't(From Email)', show: _.includes(fields, 'from_email'), content: configuration.from_email },
            { label: 't(Signature Strategy)', show: _.includes(fields, 'signature_strategy'), content: configuration.signature_strategy },
            { label: 't(Signature)', show: _.includes(fields, 'signature_id'), content: configuration.signature_id ? <AssetToken { ...configuration.signature } /> : null },
            { label: 't(Contacts)', show: _.includes(fields, 'contacts_id'), content: configuration.contacts_id ? <AssetToken { ...configuration.contacts } /> : null },
            { label: 't(Social Media Strategy)', show: _.includes(fields, 'social_strategy'), content: configuration.social_strategy },
            { label: 't(Channels)', show: _.includes(fields, 'social_channels'), content: configuration.social_channel_ids },
            { label: 't(Scheduling)', show: _.includes(fields, 'social_scheduling'), content: configuration.social_scheduling },
            { label: 't(Timeframe)', show: _.includes(fields, 'social_timeframe'), content: configuration.social_timeframe ? <TimeframeToken value={ configuration.social_timeframe } /> : null },
            { label: 't(Profiles)', show: _.includes(fields, 'social_profiles'), content: configuration.social_profiles ? <SubscriptionProfilesToken subscription_profiles={ configuration.social_profiles } /> : null },
            { label: 't(Website Strategy)', show: _.includes(fields, 'website_strategy'), content: configuration.website_strategy },
            { label: 't(Website)', show: _.includes(fields, 'website'), content: configuration.website, format: 'link' },
            { label: 't(Notes)', show: _.includes(fields, 'notes'), content: configuration.notes }
          ]
        }
      ]
    }
  }

  _getExpand() {
    return {
      label: 't(Toggle Signup Configuration)',
      className: 'link',
      handler: this._handleToggle
    }
  }

  _handleToggle() {
    const { expanded } = this.state
    this.setState({
      expanded: !expanded
    })
  }

}

export default Configuration
