import SocialSchedulingToken from '@apps/truevail/admin/components/configure/social/scheduling'
import EmailSchedulingToken from '@apps/truevail/admin/components/configure/email/scheduling'
import SocialStrategyToken from '@apps/truevail/admin/components/configure/social/strategy'
import EmailStrategyToken from '@apps/truevail/admin/components/configure/email/strategy'
import VoiceToken from '@apps/truevail/admin/components/configure/personalization/voice'
import PhotoToken from '@apps/truevail/admin/components/configure/personalization/photo'
import SignatureToken from '@apps/truevail/admin/components/configure/email/signature'
import WebsiteToken from '@apps/truevail/admin/components/configure/website/website'
import ColorToken from '@apps/truevail/admin/components/configure/branding/color'
import ProfileSetupField from '@apps/truevail/admin/components/profilesetupfield'
import LogoToken from '@apps/truevail/admin/components/configure/branding/logo'
import TimeFrameField from '@apps/truevail/admin/components/timeframefield'
import ChannelToken from '@apps/truevail/admin/tokens/channel'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {
    advisor: PropTypes.object
  }

  state = {
    config: {
      configuration: {}
    }
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { programs } = this.context.admin
    const { advisor } = this.props
    const { config } = this.state
    return {
      title: 't(Edit Configuration)',
      endpoint: `/api/admin/truevail/agency/advisors/${advisor.id}/configuration/edit`,
      action: `/api/admin/truevail/agency/advisors/${advisor.id}/configuration`,
      method: 'PATCH',
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          label: 't(Business Information)',
          fields: [
            { label: 't(Business Name)', name: 'configuration.business_name', type: 'textfield' },
            { label: 't(Agency / Host Agency)', name: 'configuration.host_agency', type: 'textfield' },
            { label: 't(Consortia)', name: 'configuration.consortia', type: 'textfield' },
            { label: 't(Business Address)', name: 'configuration.business_address', type: 'addressfield' },
            { label: 't(Business Email)', name: 'configuration.business_email', type: 'emailfield' },
            { label: 't(Business Phone)', name: 'configuration.business_phone', type: 'phonefield'},
            { label: 't(Business Social Media)', type: 'segment', fields: [
              { name: 'configuration.facebook', type: 'urlfield', service: 'facebook', placeholder: 't(Enter Facebook URL)' },
              { name: 'configuration.instagram', type: 'urlfield', service: 'instagram', placeholder: 't(Enter Instagram URL)' },
              { name: 'configuration.linkedin', type: 'urlfield', service: 'linkedin', placeholder: 't(Enter LinkedIn URL)' },
              { name: 'configuration.youtube', type: 'urlfield', service: 'youtube', placeholder: 't(Enter YouTube URL)' },
              { name: 'configuration.pinterest', type: 'urlfield', service: 'pinterest', placeholder: 't(Enter Pinterest URL)' },
              { name: 'configuration.tiktok', type: 'urlfield', service: 'tiktok', placeholder: 't(Enter TikTok URL)'},
              { name: 'configuration.threads', type: 'urlfield', service: 'threads', placeholder: 't(Enter Threads URL)' },
              { name: 'configuration.vimeo', type: 'urlfield', service: 'vimeo', placeholder: 't(Enter Vimeo URL)' },
              { name: 'configuration.x', type: 'urlfield', service: 'x', placeholder: 't(Enter X URL)' }
            ] }
          ]
        },
        {
          label: 't(Branding)',
          fields: [
            { label: 't(Logo)', type: 'segment', fields: [
              { name: 'configuration.logo_strategy', type: 'radiogroup', options: ['upload','design','text'], format: LogoToken },
              { name: 'configuration.logo_id', show: config.configuration.logo_strategy === 'upload', type: 'attachmentfield', prompt: 't(Upload Logo)', allow: { content_types: ['images'] }, multiple: false }
            ] },
            { label: 't(Color Scheme)', type: 'segment', fields: [
              { name: 'configuration.color_strategy', type: 'radiogroup', options: ['choose','design'], format: ColorToken },
              { label: 't(Brand Color 1)', name: 'configuration.color1', show: config.configuration.color_strategy === 'choose', type: 'textfield', placeholder: 't(Enter Brand Color 1 (name, hex, or rgb))' },
              { label: 't(Brand Color 2)', name: 'configuration.color2', show: config.configuration.color_strategy === 'choose', type: 'textfield', placeholder: 't(Enter Brand Color 2 (name, hex, or rgb))' },
              { label: 't(Brand Color 3)', name: 'configuration.color3', show: config.configuration.color_strategy === 'choose', type: 'textfield', placeholder: 't(Enter Brand Color 3 (name, hex, or rgb))' }
            ] },
            { label: 't(Brand Book / Style Guide)', name: 'configuration.style_guide_id', type: 'attachmentfield', prompt: 't(Upload Document)', multiple: false }
          ]
        },
        {
          label: 't(Personalization)',
          fields: [
            { label: 't(Photo)', type: 'segment', fields: [
              { name: 'configuration.photo_strategy', type: 'radiogroup', options: ['upload','optout'], format: PhotoToken },
              { name: 'configuration.photo_id', show: config.configuration.photo_strategy === 'upload', type: 'attachmentfield', prompt: 'Upload Photo', allow: { content_types: ['images'] }, multiple: false }                
            ], require: true },
            { label: 't(Public Domain)', name: 'configuration.public_domain', type: 'domainfield' },
            { label: 't(Voice)', name: 'configuration.voice', type: 'radiogroup', options: ['individual_american','agency_american','individual_canadian','agency_canadian'], format: VoiceToken },
            { label: 't(Timezone)', name: 'configuration.timezone_id', type: 'lookup', endpoint: '/api/admin/timezones', valueKey: 'id', textKey: 'zone' }
          ]
        },
        {
          label: 't(Email Newsletters)',
          fields: [
            { label: 't(Service)', type: 'segment', fields: [
              { name: 'configuration.email_strategy', type: 'radiogroup', options: ['optin','optout'], format: EmailStrategyToken },
              { label: 't(Channels)', name: 'configuration.email_channel_ids', type: 'checkboxgroup', entity: 'channel', endpoint: '/api/admin/truevail/agency/channels', valueKey: 'id', textKey: 'title', filter: { service: { $eq: 'email' }, type: { $eq: 'public' } }, deselectable: true, format: (channel) => (
                <ChannelToken channel={ channel } />
              ), defaultValue: config.configuration.email_channel_ids },
              { label: 't(Scheduling)', name: 'configuration.email_scheduling', type: 'radiogroup', show: config.configuration.email_strategy === 'optin', options: ['optout','optin'], defaultValue: 'optout', format: EmailSchedulingToken }  
            ] },
            { label: 't(Sender)', type: 'segment', fields: [
              { label: 't(From Name)', name: 'configuration.from_name', type: 'textfield', placeholder: 't(Enter From Name (example: Mary from Happy Traveler))' },
              { label: 't(From Email)', name: 'configuration.from_email', type: 'textfield', placeholder: 't(Enter From Email (example: mary@happytraveler.com))' }
            ] },
            { label: 't(Signature)', type: 'segment', fields: [
              { name: 'configuration.signature_strategy', type: 'radiogroup', options: ['upload','text','none'], format: SignatureToken },
              { label: 't(Signature)', name: 'configuration.signature_id', show: config.configuration.signature_strategy === 'upload', type: 'attachmentfield', prompt: 'Upload Signature', allow: { content_types: ['images'] }, multiple: false  }
            ] },
            { label: 't(Contact List)', name: 'configuration.contacts_id', instructions: 't(CSV, XLS, or XSLX)', type: 'attachmentfield', prompt: 'Upload Contact List', allow: { extensions: ['csv','xls','xlsx'] }, multiple: false  }
          ]
        },
        {
          label: 't(Social Media)',
          fields: [
            { label: 't(Service)', type: 'segment', fields: [
              { name: 'configuration.social_strategy', type: 'radiogroup', options: ['optin','optout'], format: SocialStrategyToken },
              { label: 't(Social Profiles)', name: 'social_profiles', type: ProfileSetupField, program: programs[0] },
              { label: 't(Channels)', name: 'configuration.social_channel_ids', type: 'checkboxgroup', entity: 'channel', endpoint: '/api/admin/truevail/agency/channels', valueKey: 'id', textKey: 'title', filter: { service: { $eq: 'social' }, type: { $eq: 'public' } }, deselectable: true, format: (channel) => (
                <ChannelToken channel={ channel } />
              ), defaultValue: config.configuration.social_channel_ids },
              { label: 't(Scheduling)', name: 'configuration.social_scheduling', type: 'radiogroup', show: config.configuration.social_strategy === 'optin', options: ['optout','optin'], defaultValue: 'optout', format: SocialSchedulingToken },
              { label: 't(Timeframe)', name: 'configuration.social_timeframe', type: TimeFrameField, show: config.configuration.social_strategy === 'optin' && config.configuration.social_scheduling === 'optout' }
            ] }
          ]
        },
        {
          label: 't(Website)',
          fields: [
            { label: 't(Service)', type: 'segment', fields: [
              { name: 'configuration.website_strategy', type: 'radiogroup', options: ['url','design','optout'], format: WebsiteToken },
              { name: 'configuration.website', show: config.configuration.website_strategy === 'url', type: 'urlfield' }
            ] }
          ]
        },
        {
          label: 't(Additional Information)',
          fields: [
            { label: 't(Notes)', name: 'configuration.notes', type: 'textarea', placeholder: 't(Enter any additional information you this we should know about you or your business)' }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Edit