import SocialCampaignField from '@apps/truevail/admin/components/social_campaign_field'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.Component {

  static propTypes = {
    item: PropTypes.object,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onDone: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)

  constructor(props) {
    super(props)
    this.state = {
      config: props.item
    }
  }

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { config } = this.state
    return {
      ref: this.formRef,
      onChange: this._handleChange,
      onSuccess: this._handleDone,
      title: 't(Edit Social Campaign)',
      cancelText: null,
      saveText: null,
      buttons: [
        { label: 't(Cancel)', color: 'blue', basic: true, handler: this._handleCancel },
        { label: 't(Done)', color: 'blue', handler: this._handleSubmit }
      ],
      sections: [
        {
          fields: [
            { label: 't(Social Campaign)', name: 'social_campaign', type: SocialCampaignField, valueKey: null, compareKey: 'id', required: true, defaultValue: config.social_campaign },
            { label: 't(Status)', name: 'status', type: 'radiogroup', options: [
              { value: 'published', text: 't(Published)' },
              { value: 'draft', text: 't(Draft)' }
            ], defaultValue: config.status || 'published' },
            { label: 't(Voice)', type: 'segment', fields: [
              { type: 'radiogroup', name: 'voice', options: [
                { value: 'none', text: 't(Dont change the voice)' },
                { value: 'translate', text: 't(Translate the text to another voice)' }
              ], defaultValue: config.voice || 'none' },
              { label: 'Perspective', type: 'dropdown', show: config.voice === 'translate', name: 'perspective', options: [
                { value: 'first-person singular', text: 't(First Person Singular)' },
                { value: 'first-person plural', text: 't(First Person Plural)' }
              ], defaultValue: config.perspective || 'first-person singular' },
              { label: 'Language', type: 'dropdown', show: config.voice === 'translate', name: 'language', options: [
                { value: 'american english', text: 't(English) (t(American))' },
                { value: 'canadian english', text: 't(English) (t(Canadian))' },
                { value: 'french', text: 't(French)' },
                { value: 'german', text: 't(German)' },
                { value: 'italian', text: 't(Italian)' }
              ], defaultValue: config.language || 'american english' }
            ] }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.props.onCancel()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleDone(config) {
    this.props.onDone(config)
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

}

export default Edit
