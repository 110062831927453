import ResponsiveField from '@admin/components/mjson_designer/components/responsivefield'
import IconStyleField from '@admin/components/mjson_designer/components/iconstylefield'
import SegmentsField from '@admin/components/mjson_designer/components/segmentsfield'
import WebRange from '@admin/components/mjson_designer/components/webrange'
import { normalizeConfig } from '../utils'
import Form from '@admin/components/form'
import * as styles from '../styles'
import settings from '../settings'
import PropTypes from 'prop-types'
import React from 'react'

const Range = (props) => <ResponsiveField { ...props } component={ WebRange } />

class Modal extends React.PureComponent {

  static contextTypes = {
    form: PropTypes.object
  }

  static propTypes = {
    device: PropTypes.string,
    entity: PropTypes.object,
    theme: PropTypes.object,
    type: PropTypes.string,
    website: PropTypes.object,
    onChange: PropTypes.func,
    onDone: PropTypes.func
  }

  formRef = React.createRef()

  _handleCancel = this._handleCancel.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  constructor(props) {
    super(props)
    const { entity } = props
    this.state = {
      default: entity,
      config: entity
    }
  }

  render() {
    return <Form { ...this._getForm() } />
  }

  componentDidUpdate(prevProps, prevState) {
    const { config } = this.state
    if(!_.isEqual(config, prevState.config)) {
      this._handleChange()
    }
  }

  _getForm() {
    const { device, entity, type, theme, website } = this.props
    const { config } = this.state
    return {
      ref: this.formRef,
      onChange: this._handleUpdate,
      onSuccess: this._handleDone,
      showHeader: false,
      buttons: [
        { label: 't(Cancel)', color: 'blue', basic: true, handler: this._handleCancel },
        { label: 't(Done)', color: 'blue', handler: this._handleSubmit }
      ],
      tabs: [
        {
          label: 't(Content)',
          sections: [
            {
              fields: [
                { label: 't(Rows)', name: 'content.children', type: SegmentsField, entity: 'page_row', theme, defaultValue: config.content.children }
              ]
            },
            {
              label: 'Options',
              fields: [
                { label: 't(Page Load)', type: 'segment', fields: [
                  { type: 'checkbox', name: 'content.showAfterLoad', prompt: 't(Show After Page Load)', defaultValue: false, value: config.content.showAfterLoad },
                  { label: 't(Delay)', name: 'content.showAfterLoadDelay', type: WebRange, units: [
                    { name: 'ms', min: 0, max: 300, step: 50 }
                  ], include: config.content.showAfterLoad, nullValues: ['0ms'], defaultValue: '0ms', value: config.content.showAfterLoadDelay }
                ] },
                { label: 't(Scroll)', type: 'segment', fields: [
                  { type: 'checkbox', name: 'content.showOnScroll', prompt: 't(Show on Scroll)', defaultValue: false, value: config.content.showOnScroll },
                  { label: 't(Scroll Depth)', name: 'content.showOnScrollDepth', type: 'dropdown', options: [
                    { value: 25, text: '25% of the page' },
                    { value: 50, text: '50% of the page' },
                    { value: 75, text: '75% of the page' },
                    { value: 100, text: '100% of the page' }
                  ], include: config.content.showOnScroll, defaultValue: 25, value: config.content.showOnScrollDepth }
                ] },
                { label: 't(Frequency)', name: 'content.frequency', type: 'dropdown', options: [
                  { value: 1, text: 'Once a day' },
                  { value: 3, text: 'Once every 3 days' },
                  { value: 7, text: 'Once every 7 days' },
                  { value: 14, text: 'Once every two weeks' },
                  { value: 30, text: 'Once a month' }
                ], defaultValue: 1, value: config.content.frequency }
              ]
            }
          ]
        },
        {
          label: 't(Styles)',
          sections: [
            {
              label: 't(Modal)',
              collapsing: true,
              collapsed: true,
              instructions: 't(Specify how the modal should appear on the page)',
              fields: [
                { label: 't(Width)', name: 'styles.modalWidth', device, type: Range, units: [
                  { name: 'px', min: 0, max: 1200, step: 1 }
                ], nullValues: ['auto'], defaultValue: 'auto', value: config.styles.modalWidth },
                { label: 't(Close Icon)', name: 'styles.closeIcon', type: IconStyleField, device, theme, value: config.styles.closeIcon }
              ]
            },
            styles.BackgroundSection(config, device, theme, website, type),
            styles.BoxShadowSection(config, device, theme, website, type),
            styles.BorderSection(config, device, theme, website, type),
            styles.SizingSection(config, device, theme, website, type, false, true),
            styles.SpacingSection(config, device, theme, website, type),
            styles.TransformSection(config, device, theme, website, type),
            styles.AnimationSection(config, device, theme, website, type),
            styles.FiltersSection(config, device, theme, website, type),
            styles.OverflowSection(config, device, theme, website, type),
            styles.VisibilitySection(config, device, theme, website, type)
          ]
        },
        {
          label: 't(Settings)',
          sections: settings(config, type, entity)
        }
      ]
    }
  }

  _getNormalized() {
    const { entity } = this.props
    const { config } = this.state
    return normalizeConfig({
      ...entity,
      meta: config.meta,
      settings: config.settings,
      styles: config.styles,
      content: config.content
    })
  }

  _handleCancel() {
    this.props.onDone(this.state.default)
  }

  _handleChange() {
    const config = this._getNormalized()
    this.props.onChange(config)
  }

  _handleDone() {
    const config = this._getNormalized()
    this.props.onDone(config)
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleUpdate(config) {
    this.setState({
      config: {
        ...this.state.config,
        ...config
      }
    })
  }

}

export default Modal
