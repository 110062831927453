import HelpArticleToken from '@admin/tokens/help_article'
import Icon from '@admin/components/icon'
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

class Results extends React.Component {

  static propTypes = {
    records: PropTypes.array,
    onArticle: PropTypes.func
  }

  render() {
    const { records  } = this.props
    const articles = records.sort((a,b) => {
      return a.title > b.title ? 1 : -1
    })
    return (
      <div className="maha-help-search-results">
        { articles.map((result, i) => (
          <div className="maha-help-search-result" key={ `result_${i}` } onClick={ this._handleClick.bind(this, result) }>
            <div className="maha-help-search-result-token">
              <HelpArticleToken { ...result } />
            </div>
            <div className="maha-help-search-result-proceed">
              <Icon icon="chevron-right" />
            </div>
          </div>
        )) }
      </div>
    )
  }

  _handleClick(article) {
    this.props.onArticle(article.id)
  }

}

export default Results
