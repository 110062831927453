import TemplateToken from '@apps/crm/admin/tokens/template'
import Container from '@admin/components/container'
import ModalPanel from '@admin/components/modal_panel'
import Message from '@admin/components/message'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import React from 'react'
import New from './new'

class Templates extends React.Component {

  static contextTypes = {
    flash: PropTypes.object,
    modal: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    advisor: PropTypes.object,
    configuration: PropTypes.object,
    templates: PropTypes.array,
    onBack: PropTypes.func,
    onPush: PropTypes.func,
    onDone: PropTypes.func
  }

  state = {
    config: {}
  }

  _handleAdd = this._handleAdd.bind(this)
  _handleBack = this._handleBack.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    const { templates } = this.props
    return (
      <ModalPanel { ...this._getPanel() }>
        { templates.length > 0 ?
          <div className="truevail-setup-panel">
            <div className="truevail-setup-items">
              <div className="truevail-setup-items-items">
                { templates.map((template, index) => (
                  <div className="truevail-setup-items-item" key={`template_${index}`}>
                    <div className="truevail-setup-items-item-token">
                      <TemplateToken template={ template } />
                    </div>
                    <Button { ...this._getRemove(template) } />
                  </div>
                )) }
              </div>
              <Button { ...this._getAdd() } />
            </div>
          </div> :
          <Message { ...this._getEmpty() } />
        }
      </ModalPanel>
    )
  }

  _getAdd() {
    return {
      label: '+ t(add template)',
      className: 'link',
      handler: this._handleAdd
    }
  }

  _getEmpty() {
    return {
      icon: 'copy',
      title: 't(No Templates)',
      text: 't(This advisor does not yet have any templates)',
      buttons: [
        { 
          label: 't(Create Template)', 
          handler: this._handleAdd
        }
      ]
    }
  }

  _getPanel() {
    return {
      title: 't(Templates)',
      leftItems: [
        { icon: 'chevron-left', handler: this._handleBack }
      ]
    }
  }

  _getRemove(template) {
    return {
      icon: 'times',
      className: 'truevail-setup-items-item-action',
      confirm: 't(Are you sure you want to delete this template?)',
      handler: this._handleRemove.bind(this, template)
    }
  }

  _handleAdd() {
    const { advisor, configuration, onBack } = this.props
    const props = {
      advisor,
      configuration,
      onBack,
      onDone: onBack
    }
    this.props.onPush(<New { ...props } />)
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleRemove(template) {
    const { advisor } = this.props
    this.context.network.request({
      endpoint: `/api/admin/truevail/agency/advisors/${advisor.id}/templates/${template.id}`,
      method: 'DELETE',
      onFailure: () => this.context.flash.set('error', 't(Unable to delete template)')
    })
  }

  _handleSuccess(config) {
    this.props.onDone(config)
  }

}

const mapResources = (props, context) => ({
  templates: `/api/admin/truevail/agency/advisors/${props.advisor.id}/templates`
})

export default Container(mapResources)(Templates)