import EmailCampaignField from '@apps/truevail/admin/components/email_campaign_field'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Import extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {}

  state = {
    config: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { config } = this.state
    return {
      title: 't(Import Email Campaign)',
      method: 'POST',
      action: '/api/admin/truevail/agency/email_campaigns',
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Email Campaign)', name: 'email_campaign_id', type: EmailCampaignField, required: true },
            { label: 't(Voice)', type: 'segment', fields: [
              { type: 'radiogroup', name: 'voice', options: [
                { value: 'none', text: 't(Dont change the voice)' },
                { value: 'translate', text: 't(Translate the text to another voice)' }
              ], defaultValue: 'none'},
              { label: 'Perspective', type: 'dropdown', show: config.voice === 'translate', name: 'perspective', options: [
                { value: 'first-person singular', text: 't(First Person Singular)' },
                { value: 'first-person plural', text: 't(First Person Plural)' }
              ], defaultValue: 'first-person singular' },
              { label: 'Language', type: 'dropdown', show: config.voice === 'translate', name: 'language', options: [
                { value: 'american english', text: 't(English) (t(American))' },
                { value: 'canadian english', text: 't(English) (t(Canadian))' },
                { value: 'french', text: 't(French)' },
                { value: 'german', text: 't(German)' },
                { value: 'italian', text: 't(Italian)' }
              ], defaultValue: 'american english' }
            ] }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSuccess(email_campaigns) {
    this.context.router.push(`/admin/truevail/agency/email_campaigns/${email_campaigns.id}`)
    this.context.modal.close()
  }

}

export default Import
