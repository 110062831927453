import Details from '@admin/components/details'
import Panel from '@admin/components/panel'
import Date from '@admin/components/date'
import Status from '@admin/tokens/status'
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

const Sending = ({ email_campaign, warmings }) => {

  let date = null

  const details = {
    before: (
      <div className="maha-details-status info">
        <strong>Emails Sent:</strong> { warmings.reduce((total, warming) => total + warming.sent_count, 0) } / { warmings.reduce((total, warming) => total + warming.total_count, 0) }<br />
        <strong>Initial Sending Rate:</strong> { email_campaign.initial_sending_rate } emails / hour / recipient domain <span className="small alert">*</span><br />
        <span className="small"><span className="alert">*</span> <span className="light">e.g. Gmail, Yahoo/AOL, Outlook/Hotmail, other</span></span>
      </div>
    ),
    sections: [
      {
        items: [
          { component: (
            <div className="maha-table">
              <table>
                <thead>
                  <tr>
                    <td>Time</td>
                    <td className="collapsing">Sent</td>
                    <td className="collapsing">Status</td>
                  </tr>
                </thead>
                <tbody>
                  { warmings.map((warming, index) => {
                    const is_new = moment(date).format('YYYY-MM-DD') !== moment(warming.hour_start).format('YYYY-MM-DD')
                    const fragment = (
                      <Fragment key={`warming_${index}`}>
                        { is_new &&
                          <tr className="heading">
                            <td colSpan="3">
                              <Date datetime={ warming.hour_start } date_format="dddd, MMMM D, YYYY" show={['date']} />
                            </td>
                          </tr>
                        }
                        <tr>
                          <td>
                            <Date datetime={ warming.hour_start } time_format="h:mmA" show={['time']} /> - <Date datetime={ warming.hour_end } show={['time']} />
                          </td>
                          <td className="right">{ warming.sent_count }/{ warming.total_count }</td>
                          <td className="unpadded">
                            <Status status={ warming.sent_count === 0 ? 'not started' : warming.sent_count === warming.total_count ? 'completed' : 'in progress' } />
                          </td>
                        </tr>
                      </Fragment>
                    )
                    if(is_new) date = warming.hour_start
                    return fragment
                  }) }
                </tbody>
              </table>
            </div>
          ) }
        ]
      }
    ]
  }

  return <Details { ...details } />

}

Sending.propTypes = {
  email_campaign: PropTypes.object,
  warmings: PropTypes.array
}

const mapResourcesToPanel = (props, context) => ({
  warmings: `/api/admin/campaigns/email/${props.email_campaign.id}/warming`
})

const mapPropsToPanel = (props, context) => ({
  title: 't(Warming)',
  panel: <Sending email_campaign={ props.email_campaign } warmings={ props.warmings } />
})

export default Panel(mapResourcesToPanel, mapPropsToPanel)




// import Panel from '@admin/components/panel'
// import Date from '@admin/components/date'
// import Icon from '@admin/components/icon'
// import React from 'react'

// const mapPropsToPanel = (props, context) => ({
//   title: 't(Opens)',
//   access: {},
//   collection: {
//     endpoint: `/api/admin/campaigns/email/${props.email_campaign.id}/warming`,
//     table: [
//       { label: 't(Time)', key: 'start_hour', primary: true, format: (warming) => (
//         <div className="token">
//           <Icon icon={ warming.sent_count === warming.total_count ? 'check-circle' : 'circle-o' } /> <Date datetime={ warming.hour_start } /> - <Date datetime={ warming.hour_end } show={['time']} />
//         </div>
//       ) },
//       { label: 't(Sent)', key: 'sent_count', primary: true, collapsing: true, format: (warming) => (
//         <>{ warming.sent_count } / { warming.total_count }</>
//       ) }
//     ],
//     defaultSort: { key: 'hour_start', order: 'asc' },
//     empty: {
//       icon: 'chart',
//       title: 't(No Warming Statistics)',
//       text: 't(There were no warming statistics for this email campaign)'
//     },
//     entity: 'statistics'
//   }
// })

// export default Panel(null, mapPropsToPanel)
