const colorsets = [
  { light: '#FFFFFF', dark: '#192b42', base: '#b2a385', accent1: '#5fa3ab', accent2: '#4a868d' },
  { light: '#FFFFFF', dark: '#1d1d1d', base: '#91cc3d', accent1: '#01a6e2', accent2: '#76a92d' },
  { light: '#FFFFFF', dark: '#241f66', base: '#7ed7da', accent1: '#5471b4', accent2: '#3a3c67' },
  { light: '#FFFFFF', dark: '#21243D', base: '#FF7C7C', accent1: '#88E1F2', accent2: '#FFD082' },
  { light: '#FFFFFF', dark: '#2e3a5a', base: '#ebbb34', accent1: '#f7c743', accent2: '#d1a62e' },
  { light: '#FFFFFF', dark: '#202222', base: '#28a8e3', accent1: '#e85f54', accent2: '#01aebb' },
  { light: '#FFFFFF', dark: '#23364f', base: '#f87694', accent1: '#fafbfc', accent2: '#a23aa2' },
  { light: '#FFFFFF', dark: '#192b42', base: '#3e3b58', accent1: '#59c4be', accent2: '#0993b8' },
  { light: '#fff9e8', dark: '#2d2d2d', base: '#ffc534', accent1: '#ffd366', accent2: '#b160f5' },
  { light: '#FFFFFF', dark: '#222222', base: '#1bb580', accent1: '#f9f9f9', accent2: '#008f5d' },
  { light: '#FFFFFF', dark: '#141414', base: '#676767', accent1: '#d8d8d8', accent2: '#676767' },
  { light: '#FFFFFF', dark: '#1d2930', base: '#af8f66', accent1: '#fbf4ea', accent2: '#967b57' },
  { light: '#FFFFFF', dark: '#002c4a', base: '#014364', accent1: '#0ea1c0', accent2: '#17d8e9' },
  { light: '#FFFFFF', dark: '#252758', base: '#7129da', accent1: '#5b74d2', accent2: '#5b74d2' },
  { light: '#FFFFFF', dark: '#2e2a2d', base: '#e18340', accent1: '#edc047', accent2: '#3f2b57' },
  { light: '#FFFFFF', dark: '#272727', base: '#187dee', accent1: '#f5f5f5', accent2: '#1f2c35' },
  { light: '#FFFFFF', dark: '#241f66', base: '#7ed7da', accent1: '#5471b4', accent2: '#3a3c67' },
  { light: '#FFFFFF', dark: '#21243D', base: '#FF7C7C', accent1: '#88E1F2', accent2: '#88E1F2' },
  { light: '#FFFFFF', dark: '#1c1e1e', base: '#ff4d30', accent1: '#1dcad3', accent2: '#f03719' },
  { light: '#FFFFFF', dark: '#3772ad', base: '#137feb', accent1: '#e1ebf5', accent2: '#2f78c2' },
  { light: '#FFFFFF', dark: '#3e414b', base: '#41c27f', accent1: '#48db8f', accent2: '#2f61e7' }
].map(colorset => ({
  value: colorset
}))

export default colorsets
