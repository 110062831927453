import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const service_types = {
  inactive: {
    title: 't(Inactive)',
    text: 't(This advisor is no longer active and will not show up in your searches)'
  },
  pending: {
    title: 't(Pending)',
    text: 't(Advisor can sign in, but all they can do is fill out the setup dialog)'
  },
  configured: {
    title: 't(Configured)',
    text: 't(Advisor can sign in, but they will just see the "Configuring Your Account" spinning dialog)'
  },
  unconfigured: {
    title: 't(Unconfigured)',
    text: 't(Advisor did not complete their configuration within 30 days)'
  },
  active: {
    title: 't(Active)',
    text: 't(Advisor is set up and free to use their account)'
  }
}

const AdvisorStatusToken = ({ value }) => (
  <div className="token">
    <strong><T text={ service_types[value].title } /></strong><br />
    <T text={ service_types[value].text } />
  </div>
)

AdvisorStatusToken.propTypes = {
  value: PropTypes.string
}

export default AdvisorStatusToken
