import Import from '@admin/components/import'
import PropTypes from 'prop-types'
import React from 'react'

class AdvisorImport extends React.Component {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {}

  render() {
    return <Import { ...this._getImport() } />
  }

  _getImport() {
    const { team } = this.context.admin
    return {
      defaults: {
        config: {
          owner_id: team.id
        }
      },
      entity: 'advisor',
      object_type: 'truevail_advisors'
    }
  }

}

export default AdvisorImport
