import Stack from '@admin/components/stack'
import PropTypes from 'prop-types'
import Main from './main'
import React from 'react'

class MultiForm extends React.Component {

  static childContextTypes = {
    form: PropTypes.object
  }

  static contextTypes = {
    network: PropTypes.object
  }

  static propTypes = {
    action: PropTypes.string,
    defaultValue: PropTypes.object,
    endpoint: PropTypes.string,
    formatData: PropTypes.func,
    getSteps: PropTypes.func,
    method: PropTypes.string,
    saveText: PropTypes.string,
    title: PropTypes.string,
    props: PropTypes.object,
    onCancel: PropTypes.func,
    onSuccess: PropTypes.func
  }

  static defaultProps = {
    defaultValue: {},
    formatData: (data) => data,
    method: 'POST',
    saveText: 't(Save)',
    title: 't(Form)'
  }

  stackRef = React.createRef()

  state = {
    entity: null,
    errors: null,
    formdata: null,
    message: null,
    status: 'pending',
    step: -1
  }

  _handlePop = this._handlePop.bind(this)
  _handlePush = this._handlePush.bind(this)
  _handleSave = this._handleSave.bind(this)
  _handleSetStep = this._handleSetStep.bind(this)
  _handleUpdateData = this._handleUpdateData.bind(this)

  render() {
    return <Stack { ...this._getStack() } />
  }

  componentDidMount() {
    const { defaultValue, endpoint } = this.props
    if(endpoint) return this._handleFetch()
    this._handleSetData(defaultValue)
  }

  componentDidUpdate(prevProps, prevState) {
    const { entity, formdata, status } = this.state
    if(status !== prevState.status) {
      if(status === 'success') this.props.onSuccess(entity, formdata)
      if(status === 'ready') this._handlePush(Main, this._getMain.bind(this))
    }
  }

  getChildContext() {
    return {
      form: {
        push: this._handlePush,
        pop: this._handlePop
      }
    }
  }

  _getMain() {
    const { action, endpoint, formatData, getSteps, method, props, saveText, title, onCancel, onSuccess } = this.props
    const { errors, formdata, message, status, step  } = this.state
    return {
      action,
      endpoint,
      errors,
      formdata,
      formatData,
      message,
      method,
      props,
      saveText,
      status,
      step,
      steps: getSteps(formdata),
      title,
      onCancel,
      onSave: this._handleSave,
      onSetStep: this._handleSetStep,
      onSuccess,
      onUpdateData: this._handleUpdateData
    }
  }

  _getStack() {
    return {
      display_name: 'multiform',
      ref: this.stackRef
    }
  }

  _handleFetch() {
    const { endpoint } = this.props
    this.setState({
      status: 'loading'
    })
    this.context.network.request({
      endpoint,
      method: 'GET',
      onSuccess: ({ data }) => {
        this.setState({
          formdata: data,
          status: 'ready'
        })
      },
      onFailure: () => {
        this.setState({
          status: 'failure'
        })
      }
    })
  }

  _handlePop(index = -1) {
    this.stackRef.current.pop(index)
  }

  _handlePush(component, props) {
    this.stackRef.current.push({ component, props })
  }

  _handleSave(endpoint, method, body) {
    this.setState({
      status: 'loading'
    })
    this.context.network.request({
      endpoint,
      method,
      body,
      onSuccess: ({ data }) => {
        this.setState({
          entity: data,
          status: 'success'
        })
      },
      onFailure: ({ errors, message }) => {
        this.setState({
          errors: errors || {},
          message,
          status: 'failure'
        })
      }
    })
  }

  _handleSetStep(step) {
    this.setState({
      message: null,
      step
    })
  }

  _handleSetData(formdata) {
    this.setState({
      status: 'ready',
      formdata: formdata || {}
    })
  }

  _handleUpdateData(formdata, step) {
    this.setState({
      formdata,
      step
    })
  }

}

export default MultiForm
