import Workflows from './automation/workflows'
import Button from '@admin/components/button'
import Page from '@admin/components/page'
import Unsubscribes from './unsubscribes'
import Emails from './automation/emails'
import Performance from './performance'
import Activities from './activities'
import Deliveries from './deliveries'
import Complaints from './complaints'
import T from '@admin/components/t'
import Overview from './overview'
import Bounces from './bounces'
import Unopens from './unopens'
import Sending from './sending'
import Design from './design'
import Links from './links'
import Opens from './opens'
import Spam from './spam'
import React from 'react'

const more = {
  label: 't(Learn more about our warmup process)',
  className: 'link',
  link: 'https://truevail.helpscoutdocs.com/article/97-understanding-our-new-email-warming-strategy'
}

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.email_campaign.title,
  manager: {
    path: `/admin/campaigns/email/${resources.email_campaign.id}`,
    alert: resources.email_campaign.is_spam ? {
      style: 'error',
      message: (
        <>
          <strong><T text="t(This email has been flagged for questionable content!)" /></strong> <T text="t(You will not be able to send this campaign until you have addressed the underlying issues. Please visit the 'SPAM Report' tab for more details.)" />
        </>
      )
    } : resources.email_campaign.status === 'warming' ? {
      style: 'warning',
      message: (
        <>Your campaign from { resources.email_campaign.sender.email } is currently in the warming process. This helps improve deliverability and open rates. Performance data will be incomplete until the warming process is finished.<br /><Button { ...more } /></>
      )
    } : null,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview email_campaign={ resources.email_campaign } />
      },
      {
        label: 't(Design)',
        path: '/design',
        panel: <Design email_campaign={ resources.email_campaign } />
      },
      {
        label: 't(SPAM Report)',
        show: resources.email_campaign.is_spam === true,
        path: '/spam_report',
        panel: <Spam email_campaign={ resources.email_campaign } />
      },
      {
        label: 't(Automation)',
        access: { rights: { $allOf: ['automation:access_app'] } },
        children: [
          {
            label: 't(Workflows)',
            path: '/workflows',
            panel: <Workflows email_campaign={ resources.email_campaign } />
          },
          {
            label: 't(Emails)',
            path: '/emails',
            panel: <Emails email_campaign={ resources.email_campaign } />
          }
        ]
      },
      {
        label: 't(Sending)',
        show: ['warming','sending','sent'].includes(resources.email_campaign.status),
        path: '/sending',
        panel: <Sending email_campaign={ resources.email_campaign } />
      },
      {
        label: 't(Activities)',
        show: ['warming','sending','sent'].includes(resources.email_campaign.status),
        path: '/activities',
        panel: <Activities email_campaign={ resources.email_campaign } />
      },
      {
        label: 't(Bounces)',
        show: ['warming','sending','sent'].includes(resources.email_campaign.status),
        path: '/bounces',
        panel: <Bounces email_campaign={ resources.email_campaign } />
      },
      {
        label: 't(Complaints)',
        show: ['warming','sending','sent'].includes(resources.email_campaign.status),
        path: '/complaints',
        panel: <Complaints email_campaign={ resources.email_campaign } />
      },
      {
        label: 't(Deliveries)',
        show: ['warming','sending','sent'].includes(resources.email_campaign.status),
        path: '/deliveries',
        panel: <Deliveries email_campaign={ resources.email_campaign } />
      },
      {
        label: 't(Links)',
        show: ['warming','sending','sent'].includes(resources.email_campaign.status),
        path: '/links',
        panel: <Links email_campaign={ resources.email_campaign } />
      },
      {
        label: 't(Opens)',
        show: ['warming','sending','sent'].includes(resources.email_campaign.status),
        path: '/opens',
        panel: <Opens email_campaign={ resources.email_campaign } />
      },
      {
        label: 't(Unopens)',
        show: ['warming','sending','sent'].includes(resources.email_campaign.status),
        path: '/unopens',
        panel: <Unopens email_campaign={ resources.email_campaign } />
      },
      {
        label: 't(Unsubscribes)',
        show: ['warming','sending','sent'].includes(resources.email_campaign.status),
        path: '/unsubscribes',
        panel: <Unsubscribes email_campaign={ resources.email_campaign } />
      },
      {
        label: 't(Performance)',
        show: resources.email_campaign.status === 'sent',
        path: '/performance',
        panel: <Performance email_campaign={ resources.email_campaign } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  email_campaign: `/api/admin/campaigns/email/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
