import { normalizeConfig } from '../utils'
import Form from '@admin/components/form'
import * as styles from '../styles'
import PropTypes from 'prop-types'
import settings from '../settings'
import React from 'react'

class EditSidebarBlock extends React.PureComponent {

  static propTypes = {
    device: PropTypes.string,
    entity: PropTypes.object,
    theme: PropTypes.object,
    type: PropTypes.string,
    website: PropTypes.object,
    onChange: PropTypes.func,
    onDone: PropTypes.func
  }

  formRef = React.createRef()

  _handleCancel = this._handleCancel.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  constructor(props) {
    super(props)
    const { entity } = props
    this.state = {
      default: entity,
      config: entity
    }
  }

  render() {
    return <Form { ...this._getForm() } />
  }

  componentDidUpdate(prevProps, prevState) {
    const { config } = this.state
    if(!_.isEqual(config, prevState.config)) {
      this._handleChange()
    }
  }

  _getForm() {
    const { device, entity, theme, type, website } = this.props
    const { config } = this.state
    return {
      ref: this.formRef,
      onChange: this._handleUpdate,
      onSuccess: this._handleDone,
      showHeader: false,
      buttons: [
        { label: 't(Cancel)', color: 'blue', basic: true, handler: this._handleCancel },
        { label: 't(Done)', color: 'blue', handler: this._handleSubmit }
      ],
      tabs: [
        {
          label: 't(Content)',
          sections: [
            {
              fields: [
                { label: 'Color Scheme', name: 'content.scheme', type: 'dropdown', options: [
                  { value: null, text: 't(No Color)' },
                  { value: '#FFFFFF', text: 't(White)' },
                  ...website.styles.colors.map(color => (
                    { value: color.hex, text: color.name }
                  ))
                ], defaultValue: '#FFFFFF', value: config.content.background },
                { label: 't(Title)', type: 'segment', fields: [
                  { label: 't(Icon)', name: 'content.icon', type: 'dropdown', options: [{ value:'none', text: 't(No Icon)' },'heart-o','star-o','file-o','comment-o','envelope-o','folder-open-o','bookmark-o','thumbs-o-up','check-circle-o','circle-o','clock-o','search','youtube-play','calendar','gift','rss','youtube','facebook','twitter','pencil','map-marker','usd','warning','cutlery','lightbulb-o','leaf'], defaultValue: 'none', value: config.content.icon },
                  { name: 'content.title', type: 'textfield', placeholder: 'Enter title', value: config.content.title }
                ] },
                { label: 't(Body)', type: 'segment', fields: [
                  { name: 'content.type', type: 'dropdown', options: [
                    { value: 'html', text: 't(Rich Text)' },
                    { value: 'body', text: 't(Code)' }
                  ], defaultValue: 'text', value: config.content.type },
                  ...config.content.type === 'html' ? [
                    { name: 'content.body', type: 'htmlfield', toggleable: true, value: config.content.body }
                  ] : [
                    { name: 'content.body', type: 'codefield', toggleable: true, value: config.content.body }
                  ]
                ] }
              ]
            }
          ]
        },
        {
          label: 't(Styles)',
          sections: [
            {
              label: 't(Title)',
              collapsing: true,
              collapsed: true,
              fields: [
                { label: 't(Text)', name: 'styles.title_text', type: styles.Text, website, canHover: false, canResponsive: false, marginLeft: false, value: config.styles.title_text },
                { label: 't(Border)', name: 'styles.title_border', device, website, type: styles.Border, value: config.styles.title_border }
              ]
            },
            styles.TextSection(config, device, theme, website, type, 'text'),
            styles.BackgroundSection(config, device, theme, website, type),
            styles.BoxShadowSection(config, device, theme, website, type),
            styles.BorderSection(config, device, theme, website, type),
            styles.SizingSection(config, device, theme, website, type, true, true),
            styles.SpacingSection(config, device, theme, website, type),
            styles.TransformSection(config, device, theme, website, type),
            styles.AnimationSection(config, device, theme, website, type),
            styles.FiltersSection(config, device, theme, website, type),
            styles.OverflowSection(config, device, theme, website, type),
            styles.VisibilitySection(config, device, theme, website, type)
          ]
        },
        {
          label: 't(Settings)',
          sections: settings(config, type, entity)
        }
      ]
    }
  }

  _getNormalized() {
    const { entity } = this.props
    const { config } = this.state
    return normalizeConfig({
      ...entity,
      meta: config.meta,
      settings: config.settings,
      styles: config.styles,
      content: config.content
    })
  }

  _handleCancel() {
    this.props.onDone(this.state.default)
  }

  _handleChange() {
    const config = this._getNormalized()
    this.props.onChange(config)
  }

  _handleDone() {
    const config = this._getNormalized()
    this.props.onDone(config)
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleUpdate(config) {
    this.setState({
      config: {
        ...this.state.config,
        ...config
      }
    })
  }

}

export default EditSidebarBlock
