import ModalPanel from '@admin/components/modal_panel'
import PropTypes from 'prop-types'
import Releases from './releases'
import React from 'react'

class Changelog extends React.Component {

  static propTypes = {
    buttons: PropTypes.array,
    onBack: PropTypes.func,
    onCancel: PropTypes.func
  }

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <Releases />
      </ModalPanel>
    )
  }

  _getPanel() {
    const { buttons, onBack, onCancel } = this.props
    return {
      title: 't(Changelog)',
      leftItems: [
        ...onBack ? [
          { icon: 'chevron-left', handler: onBack }
        ] : onCancel ? [
          { icon: 'times', handler: onCancel }
        ] : []
      ],
      ...buttons ? { buttons } : {}
    }
  }

}

export default Changelog
