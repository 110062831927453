import ResponsiveField from '@admin/components/mjson_designer/components/responsivefield'
import LinkedField from '@admin/components/mjson_designer/components/linkedfield'
import React from 'react'

const spacing = {
  units: [
    { name: 'px', min: 0, max: 500, step: 1 }
  ],
  types: ['top','right','bottom','left'],
  nullValues: ['0px'],
  defaultValue: '0px'
}

export const Spacing = (props) => <ResponsiveField { ...props } { ...spacing } component={ LinkedField } />

export const SpacingSection = (config, device, theme, website, type) => ({
  label: 't(Spacing)',
  instructions: 't(Specify the box model style and layout properties for this entity)',
  collapsing: true,
  collapsed: true,
  fields: [
    { label: 't(Margin)', name: 'styles.margin', device, type: Spacing, value: config.styles.margin },
    { label: 't(Padding)', name: 'styles.padding', device, type: Spacing, value: config.styles.padding }
  ]
})
