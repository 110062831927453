
import ModalPanel from '@admin/components/modal_panel'
import Img from '@admin/components/html/img'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Configuring extends React.PureComponent {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {}

  _handleDone = this._handleDone.bind(this)

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="truevail-configure-configuring">
          <div className="truevail-configure-configuring-header">
            <div className="truevail-configure-overview-header-image">
              <Img src="https://assets.mahaplatform.com/imagecache/fit=cover&w=1000&h=250/assets/08dab79852a8a1440b5dd7ea8148b179/halaszbastya.jpg.webp" />
            </div>
          </div>
          <div className="truevail-configure-configuring-body">
            <div className="truevail-configure-configuring-text">
              <h2><T text="t(Submission Successful!)" /></h2>
              <p><T text="t(Received & Processing: Your information has been received and currently being processed.)" /></p>
              <h2><T text="t(Next Steps:)" /></h2>
              <p>1. <T text="t(Review & Setup: Our team is reviewing your details and integrating them into your service setup.)" /></p>
              <p>2. <T text="t(Confirmation & Instructions: You'll get a confirmation email with any necessary instructions once setup is complete.)" /></p>
              <h2><T text="t(Need Help?)" /></h2>
              <p><T text={`t(Contact us at <a href="mailto:support@truevail.com">support@truevail.com</a> for assistance or further information.)`} /></p>
            </div>
          </div>
        </div>
      </ModalPanel>
    )
  }


  _getPanel() {
    return {
      title: 't(Setup Complete)',
      buttons: [
        {
          label: 't(Go to Dashboard)',
          color: 'blue',
          handler: this._handleDone
        }
      ]
    }
  }

  _handleDone() {
    this.context.modal.close()
  }

}

export default Configuring
