import SocialCampaignToken from '@apps/truevail/admin/tokens/social_campaign'
import EntityChooser from '@admin/components/entity_chooser'
import InputTokens from '@admin/components/input_tokens'
import Button from '@admin/components/button'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class SocialCampaignField extends React.PureComponent {

  static contextTypes = {
    form: PropTypes.object
  }

  static propTypes = {
    defaultValue: PropTypes.array,
    required: PropTypes.bool,
    value: PropTypes.array,
    valueKey: PropTypes.string,
    onChange: PropTypes.func,
    onReady: PropTypes.func,
    onValid: PropTypes.func
  }

  static defaultProps = {
    valueKey: 'id'
  }

  state = {
    social_campaign: null
  }

  _handleBack = this._handleBack.bind(this)
  _handleClear = this._handleClear.bind(this)
  _handleClick = this._handleClick.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    const { social_campaign } = this.state
    return (
      <div className="maha-input">
        <div className="maha-input-field" onClick={ this._handleClick }>
          { social_campaign ?
            <InputTokens { ...this._getInputTokens() } /> :
            <div className="maha-input-placeholder">
              <T text="t(Select Social Campaign)" />
            </div>            
          }
        </div>
        { social_campaign &&
          <Button { ...this._getClear() } />          
        }
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { social_campaign } = this.state
    if(!_.isEqual(social_campaign, prevState.social_campaign)) {
      this._handleChange()
    }
  }

  _getChooser() {
    const { social_campaign } = this.state
    return {
      defaultValue: social_campaign,
      endpoint: '/api/admin/truevail/agency/social_campaigns/truevail',
      entity: 'social campaign',
      format: (social_campaign) => (
        <SocialCampaignToken social_campaign={ social_campaign } />
      ),
      preview: (entity) => `truevail_social_campaign_variants/${entity.variants[0].code}`,
      onBack: this._handleBack,
      onChoose: this._handleUpdate
    }
  }

  _getClear() {
    return {
      icon: 'times',
      className: 'maha-input-action',
      handler: this._handleClear
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getInputTokens() {
    const { social_campaign } = this.state
    return {
      tokens: [social_campaign],
      textKey: 'title'
    }
  }

  _handleBack() {
    this.context.form.pop()
  }

  _handleChange() {
    const { social_campaign } = this.state
    const { valueKey } = this.props
    this.props.onChange(social_campaign ? (valueKey ? social_campaign?.[valueKey] : social_campaign) : null)
  }

  _handleClear() {
    this.setState({ 
      social_campaign: null
    })
  }

  _handleClick() {
    this.context.form.push(<EntityChooser { ...this._getChooser() } />)
  }

  _handleSet(social_campaign) {
    this.setState({ social_campaign })
  }

  _handleUpdate(social_campaign) {
    this.setState({ social_campaign })
    this.context.form.pop()
  }

}

export default SocialCampaignField
