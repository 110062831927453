import Resumable from 'resumablejs'
import PropTypes from 'prop-types'
import Preview from './preview'
import React from 'react'

class FileField extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    action: PropTypes.string,
    defaultValue: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.array
    ]),
    button: PropTypes.element,
    disabled: PropTypes.bool,
    endpoint: PropTypes.string,
    files: PropTypes.array,
    multiple: PropTypes.bool,
    multiplePrompt: PropTypes.string,
    prompt: PropTypes.string,
    status: PropTypes.string,
    token: PropTypes.string,
    tabIndex: PropTypes.number,
    types: PropTypes.array,
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.array
    ]),
    onAddFile: PropTypes.func,
    onChange: PropTypes.func,
    onChangeFile: PropTypes.func,
    onLoadFiles: PropTypes.func,
    onBusy: PropTypes.func,
    onReady: PropTypes.func,
    onRemoveFile: PropTypes.func,
    onSetFiles: PropTypes.func,
    onUploadBegin: PropTypes.func,
    onUploadComplete: PropTypes.func,
    onUploadFailure: PropTypes.func,
    onUploadProcess: PropTypes.func,
    onUploadProgress: PropTypes.func,
    onUploadSuccess: PropTypes.func
  }

  static defaultProps = {
    action: '/api/admin/assets/upload',
    defaultValue: null,
    disabled: false,
    endpoint: '/api/admin/assets',
    multiple: false,
    multiplePrompt: 't(Choose Another File)',
    prompt: 't(Choose File)',
    tabIndex: 0,
    onBusy: () => {},
    onChange: () => {},
    onReady: () => {},
    onSet: () => {}
  }

  buttonRef = React.createRef()

  state = {
    previews: {}
  }

  _handleFileAdded = this._handleFileAdded.bind(this)
  _handleUploadProgress = this._handleUploadProgress.bind(this)
  _handleUploadSuccess = this._handleUploadSuccess.bind(this)
  _handleUploadFailure = this._handleUploadFailure.bind(this)
  _handleUploadComplete = this._handleUploadComplete.bind(this)

  render() {
    const { button, files, multiple, multiplePrompt, prompt, tabIndex } = this.props
    return (
      <div className={ this._getClass() } tabIndex={ tabIndex }>
        <div className="maha-filefield-tokens">
          { files.map((file, index) => (
            <Preview key={`filefield_${index}`} { ...this._getFile(file, index) } />
          ))}
        </div>
        { (files.length === 0 || multiple === true) &&
          <div className="maha-filefield-button" ref={ this.buttonRef }>
            { button ? button :
              <div className="ui browse button">
                { files.length === 0 ? prompt :  multiplePrompt }
              </div>
            }
          </div>
        }
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    if(defaultValue) return this._handleFetchFiles(defaultValue)
    this._handleInit()
  }

  componentDidUpdate(prevProps) {
    const { files, multiple, value, onChange } = this.props
    if(!_.isEqual(value, prevProps.value)) onChange(value)
    if(files.length > prevProps.files.length) {
      if(files.filter(file => file.status === 'added').length > 0) {
        this._handleUploadBegin()
      }
    } else if(files.length < prevProps.files.length && !multiple) {
      this._handleInit()
    }
  }

  _getClass() {
    const { status } = this.props
    const classes = ['maha-filefield', status]
    return classes.join(' ')
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getFile(file, index) {
    return {
      file,
      preview: this.state.previews[file.uniqueIdentifier],
      onRemove: this._handleRemoveFile.bind(this, index)
    }
  }

  _getToken() {
    const { admin } = this.context
    return this.props.token || admin.token || null
  }

  _handleFileAdded(file) {
    const fileReader = new FileReader()
    this.props.onAddFile(file.uniqueIdentifier, file.file.name, file.file.size, file.file.type, file.chunks.length)
    if(!file.file.type.match(/(jpeg|jpg|gif|png)/)) return
    fileReader.readAsDataURL(file.file)
    fileReader.onload = this._handleImagePreview.bind(this, file.file.uniqueIdentifier)
  }

  _handleImagePreview(uid, e) {
    this.setState({
      previews: {
        ...this.state.previews,
        [uid]: e.target.result
      }
    })
  }

  _handleInit() {
    const { action, files, multiple, types } = this.props
    const token = this._getToken()
    this.resumable = new Resumable({
      target: action,
      chunkSize: 1024 * 128,
      permanentErrors: [204, 400, 404, 409, 415, 500, 501],
      maxFiles: multiple ? undefined : 1,
      fileType: types,
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    this.resumable.on('fileAdded', this._handleFileAdded)
    this.resumable.on('fileProgress', this._handleUploadProgress)
    this.resumable.on('fileSuccess', this._handleUploadSuccess)
    this.resumable.on('error', this._handleUploadFailure)
    this.resumable.on('complete', this._handleUploadComplete)
    if(multiple || (!multiple && files.length === 0)) {
      this.resumable.assignBrowse(this.buttonRef.current)
      this.resumable.assignDrop(this.buttonRef.current)
    }
    this.props.onReady()
  }

  _handleFetchFiles(ids) {
    const { endpoint } = this.props
    const token = this._getToken()
    this.context.network.request({
      method: 'GET',
      endpoint,
      query: {
        $filter: {
          id: {
            $in: _.castArray(ids)
          }
        }
      },
      token,
      onSuccess: ({ data }) => {
        this.props.onSetFiles(data.map(file => ({
          fileName: file.file_name,
          fileSize: file.file_size,
          contentType: file.content_type,
          status: 'success',
          progress: 0,
          uploadedChunks: 0,
          totalChunks: file.chunks_total,
          asset: file
        })))
        this.props.onReady()
      },
      onFailure: () => {}
    })
  }

  _handleRemoveFile(index) {
    const file = this.props.files[index]
    this.props.onRemoveFile(index)
    if(!file.uniqueIdentifier) return
    const resumableFile = this.resumable.getFromUniqueIdentifier(file.uniqueIdentifier)
    this.resumable.removeFile(resumableFile)
  }

  _handleUploadBegin() {
    this.resumable.upload()
    this.props.onUploadBegin()
    this.props.onBusy(true)
  }

  _handleUploadComplete() {
    this.props.onUploadComplete()
  }

  _handleUploadFailure(file, message) {
    this.props.onUploadFailure(message)
    this.props.onBusy(false)
  }

  _handleUploadProgress(file) {
    this.props.onUploadProgress(file.file.uniqueIdentifier, file.progress())
  }

  _handleUploadSuccess(file, message) {
    const asset = JSON.parse(message)
    this.props.onUploadSuccess(file.file.uniqueIdentifier, asset)
    this.props.onBusy(false)
  }

}

export default FileField
