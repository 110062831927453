import { getCode } from '@core/utils/codes'
import { flatten } from 'flat'

export const getPath = (config, id, path) => {
  if(id == null) return null
  const value = path ? _.get(config, path) : config
  if(_.isArray(value)) {
    return value.reduce((found, entity, index) => {
      if(found) return found
      const subpath = path ?  `${path}[${index}]` : `[${index}]`
      if(_.get(entity, 'id') === id) return subpath
      return getPath(config, id, subpath)
    }, null)
  } else if(_.isPlainObject(value)) {
    return Object.keys(value).reduce((found, key) => {
      if(found) return found
      const entity = value[key]
      const subpath = path ? `${path}.${key}` : key
      if(_.get(entity, 'id') === id) return subpath
      return getPath(config, id, subpath)
    }, null)
  }
  return null
}

export const getPathParts = (path) => {
  const matches = path.match(/^(.*)\[(.*)\]/)
  const parentPath = matches[1]
  const index = parseInt(matches[2])
  return [parentPath,index]
}

const copyEntity = (config) => {
  return JSON.parse(JSON.stringify(config))
}

export const getEntity = (config, id) => {
  const path = getPath(config, id)
  return _.get(config, path)
}

const get = (config, path) => {
  return _.get(config, path)
}

const set = (config, path, obj) => {
  return _.set(config, path, obj)
}

const insertAt = (array, index, data) => [
  ...array.reduce((inserted, element, i) => [
    ...inserted,
    ...i === index ? [data] : [],
    element
  ], []),
  ...index === array.length ? [data] : []
]

const removeAt = (array, index) => {
  return array.filter((element, i) => {
    return i !== index
  })
}

export const find = (config, object, path) => {
  const flat = flatten(object)
  const value = path ? _.get(config, path) : config
  const found = Object.keys(flat).reduce((found, key) => {
    return found ? _.get(value, key) === flat[key]: false
  }, true)
  if(found) return value
  if(_.isArray(value)) {
    return value.reduce((found, value, index) => {
      if(found) return found
      const subpath = path ?  `${path}[${index}]` : `[${index}]`
      return find(config, object, subpath)
    }, null)
  } else if(_.isPlainObject(value)) {
    return Object.keys(value).reduce((found, key) => {
      if(found) return found
      const subpath = path ? `${path}.${key}` : key
      return find(config, object, subpath)
    }, null)
  }
  return null
}

const cloneObject = (obj) => {
  if(_.isPlainObject(obj)) {
    return Object.keys(obj).reduce((cloned, key) => ({
      ...cloned,
      [key]: key === 'id' ? getCode(5) : cloneObject(obj[key])
    }), {})
  } else if(_.isArray(obj)) {
    return obj.map(value => cloneObject(value))
  } else {
    return obj
  }
}

export const append = (document, id, obj) => {
  const config = copyEntity(document)
  const path = getPath(config, id)
  const parentPath = `${path ? `${path}.` : ''}content.children`
  const parentObj = get(config, parentPath)
  const index = parentObj.length
  set(config, parentPath, insertAt(parentObj, index, obj))
  return config
}

export const prepend = (document, id, obj) => {
  const config = copyEntity(document)
  const path = getPath(config, id)
  const parentPath = `${path ? `${path}.` : ''}content.children`
  const parentObj = get(config, parentPath)
  set(config, parentPath, insertAt(parentObj, 0, obj))
  return config
}

export const insert = (document, id, index, obj) => {
  const config = copyEntity(document)
  const path = getPath(config, id)
  const parentPath = `${path ? `${path}.` : ''}content.children`
  const parentObj = get(config, parentPath)
  set(config, parentPath, insertAt(parentObj, index, obj))
  return config
}

export const clone  = (document, id) => {
  const config = copyEntity(document)
  const path = getPath(config, id)
  const [parentPath,index] = getPathParts(path)
  const parentObj = get(config, parentPath)
  const obj = get(config, path)
  const cloned = cloneObject(obj)
  set(config, parentPath, insertAt(parentObj, index + 1, cloned))
  return config
}

export const move = (document, from, toParent, toIndex) => {
  const config = copyEntity(document)
  const fromPath = getPath(config, from)
  const toParentPath = getPath(config, toParent) + '.content.children'
  const [fromParentPath,fromIndex] = getPathParts(fromPath)
  const fromObj = get(config, fromPath)
  const toParentObj = get(config, toParentPath)
  const newFromIndex = fromParentPath === toParentPath && fromIndex > toIndex ? fromIndex + 1 : fromIndex
  set(config, toParentPath, insertAt(toParentObj, toIndex, fromObj))
  const fromParentObj = get(config, fromParentPath) 
  set(config, fromParentPath, removeAt(fromParentObj, newFromIndex))
  return config
}

export const remove = (document, id) => {
  const config = copyEntity(document)
  const path = getPath(config, id)
  const [parentPath,index] = getPathParts(path)
  const parentObj = get(config, parentPath)
  set(config, parentPath, removeAt(parentObj,index))
  return config
}

export const update = (document, id, newObj) => {
  const config = copyEntity(document)
  const path = getPath(config, id)
  if(!path) return copyEntity(newObj)
  set(config, path, newObj)
  return config
}

export const unlink = (document, id) => {
  const config = copyEntity(document)
  const path = getPath(config, id)
  const obj = get(config, path)
  set(config, path, obj.content.children[0])
  return config
}
