import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    advisor: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { advisor } = this.props
    return {
      title: 't(New Email Campaign)',
      method: 'POST',
      action: `/api/admin/truevail/agency/advisors/${advisor.id}/email_campaigns`,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Email Template)', name: 'email_template_id', type: 'lookup', endpoint: '/api/admin/truevail/agency/email_templates', valueKey: 'id', textKey: 'title', required: true },
            { label: 't(Advisor Template)', name: 'template_id', type: 'dropdown', endpoint: `/api/admin/truevail/agency/advisors/${advisor.id}/templates`, valueKey: 'id', textKey: 'title', selectFirst: true, required: true },
            { label: 't(Sender)', name: 'sender_id', type: 'dropdown', endpoint: `/api/admin/truevail/agency/advisors/${advisor.id}/senders`, valueKey: 'id', textKey: 'rfc822', selectFirst: true, required: true }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(email_campaigns) {
    this.context.modal.close()
  }

}

export default New
