import Details from '@admin/components/details'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import Edit from '../edit'
import React from 'react'

const Overview = ({ agency }) => {

  const contact = agency.team.contact ? {
    className: 'link',
    label: agency.team.contact.display_name,
    route: `/admin/crm/contacts/${agency.team.contact.id}`
  } : null

  const subscription = agency.team.subscription ? {
    className: 'link',
    label: agency.team.subscription.plan.title,
    route: `/admin/subscriptions/services/${agency.team.subscription.service_id}/subscriptions/${agency.team.subscription.id}`
  } : null

  const details = {
    audits: `truevail_agencies/${agency.id}`,
    comments: `truevail_agencies/${agency.id}`,
    sections: [
      {
        title: 't(Agency Details)',
        items: [
          { label: 't(Name)', content: agency.name },
          { label: 't(Organization)', content: agency.organization },
          { label: 't(Public Domain)', content: agency.team.public_domain },
          { label: 't(Advisor Domains)', content: agency.domains.map((domain,index) => (
            <div key={`domain_${index}`}>{ domain.name }</div>
          )) },
          { label: 't(Contact)', content: contact ? <Button { ...contact } /> : null },
          { label: 't(Billing Subscription)', content: subscription ? <Button { ...subscription } /> : null },
          { label: 't(Max Advisors)', content: agency.advisors_max || 'UNLIMITED' }
        ]
      }
    ]
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  agency: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview agency={ props.agency } />,
  tasks: {
    items: [
      {
        label: 't(Edit Agency)',
        modal: <Edit agency={ props.agency } />
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
