import Container from '@admin/components/container'
import Button from '@admin/components/button'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Deal extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    config: PropTypes.object,
    pipelines: PropTypes.array,
    program: PropTypes.object,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onDone: PropTypes.func,
    onTokens: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: null
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)

  render() {
    if(!this.state.config) return null
    return <Form { ...this._getForm() } />
  }

  componentDidMount() {
    this.setState({
      config: {
        ...this.props.config || {}
      }
    })
  }

  _getForm() {
    const { admin } = this.context
    const { program } = this.props
    const { config } = this.state
    return {
      ref: this.formRef,
      title: 't(Create a Deal)',
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSubmit: this._handleDone,
      cancelIcon: 'chevron-left',
      saveText: null,
      buttons: [
        { label: 't(Cancel)', color: 'blue', basic: true, handler: this._handleCancel },
        { label: 't(Done)', color: 'blue', handler: this._handleSubmit }
      ],
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', required: true, defaultValue: config.title, after: <Button { ...this._getTokens() } />  },
            { label: 't(Owner)', name: 'owner_id', type: 'lookup', endpoint: '/api/admin/users', valueKey: 'id', textKey: 'full_name', defaultValue: config.owner_id || admin.user.id },
            { label: 't(Pipeline)', name: 'pipeline_id', type: 'lookup', required: true, prompt: 'Choose a pipeline', endpoint: '/api/admin/sales/pipelines', filter: { program_id: { $eq: program.id } }, valueKey: 'id', textKey: 'title', defaultValue: config.pipeline_id },
            ...config.pipeline_id ? [
              { label: 't(Stage)', name: 'stage_id', type: 'dropdown', endpoint: `/api/admin/sales/pipelines/${config.pipeline_id}/stages`, valueKey: 'id', textKey: 'title', required: true, defaultValue: config.stage_id }
            ] : [
              { label: 't(Stage)', type: 'textfield', disabled: true, required: true }
            ],
            { label: 't(Pricing)', type: 'segment', fields: [
              { name: 'pricing_type', type: 'radiogroup', options: [
                { value: 'fixed', text: 't(Fixed Price)' },
                { value: 'recurring', text: 't(Recurring Revenue)' }
              ], required: true, defaultValue: config.pricing_type || 'fixed' },
              { label: 't(Amount)', name: 'amount', show: config.pricing_type === 'fixed', type: 'moneyfield', defaultValue: config.amount },
              { label: 't(Annual Recurring Revenue)', name: 'annual_recurring_revenue', show: config.pricing_type === 'recurring', type: 'moneyfield', defaultValue: config.annual_recurring_revenue }
            ] }
          ]
        }
      ]
    }
  }

  _getTokens() {
    const { onTokens } = this.props
    return {
      label: 't(you can use these tokens in the title)',
      className: 'link',
      handler: onTokens
    }
  }

  _handleCancel() {
    this.props.onCancel()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleDone(config) {
    const { pipelines } = this.props
    const pipeline = _.find(pipelines, { id: config.pipeline_id })
    this.props.onDone({
      ...config,
      pipeline: pipeline ? {
        id: pipeline.id,
        title: pipeline.title
      } : null
    })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

}
const mapResources = (props, context) => ({
  pipelines: '/api/admin/sales/pipelines'
})

export default Container(mapResources)(Deal)
