import DatesField from '@apps/truevail/admin/components/datesfield'
import Container from '@admin/components/container'
import Form from '@admin/components/form'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Publish extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    advisors: PropTypes.array,
    email_campaign: PropTypes.object
  }

  state = {
    config: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { advisors, email_campaign } = this.props
    const { config } = this.state
    return {
      title: 't(Publish Email Campaigns)',
      method: 'PATCH',
      action: `/api/admin/truevail/agency/email_campaigns/${email_campaign.id}/publish`,
      saveText: 'Publish',
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Publish To)', type: 'segment', fields: [
              { name: 'publish_strategy', type: 'radiogroup', options: [
                { value: 'all', text: 't(All Advisors)' },
                { value: 'channels', text: 't(Channels)' },
                { value: 'custom', text: 't(Selected Advisors)' }
              ], defaultValue: 'all' },
              { label: 't(Channels)', name: 'channel_ids', type: 'lookup', multiple: true, show: config.publish_strategy === 'channels', endpoint: '/api/admin/truevail/agency/channels', filter: { service: { $eq: 'email' } }, valueKey: 'id', textKey: 'title', format: (channel) => (
                <div className="token">{ channel.title }</div>
              ), required: true },
              { label: 't(Advisors)', name: 'advisor_ids', type: 'checkboxgroup', show: config.publish_strategy === 'custom', options: advisors.map(advisor => ({
                value: advisor.id,
                text: advisor.team.title                  
              })), deselectable: true, defaultSelected: true, height: 200, defaultValue: advisors.map(advisor => advisor.id), required: true }  
            ], required: true },
            { label: 't(Scheduling)', type: 'segment', fields: [
              { name: 'scheduling_strategy', type: 'radiogroup', options: [
                { value: 'subscription', text: 't(Publish and schedule content based on the advisor\'s subscription preference)' },
                { value: 'optout', text: 't(Publish and schedule content for all selected advisors)' },
                { value: 'optin', text: 't(Publish, but do not schedule content for all selected advisors )' }
              ], defaultValue: 'subscription' },
              { label: 't(Engagement)', name: 'engagement', type: 'checkboxgroup', options: [
                { value: 'engaged', text: 't(Engaged - Contact opened or clicked on one of your last 4 emails)' },
                { value: 'disengaged', text: 't(Disengaged - Contact opened or clicked on one of your last 5 - 8 emails)' },
                { value: 'inactive', text: 't(Inactive - Contact has not opened or clicked on any of your last 8 emails)' }
              ], defaultValue: ['engaged','disengaged'], show: config.scheduling_strategy !== 'optin', required: true },
              { label: 't(Scheduling Dates)', name: 'scheduling_dates', type: DatesField, show: config.scheduling_strategy !== 'optin', required: true },
              { label: 't(Send At)', name: 'time', type: 'timefield', defaultValue: '9:00AM', after: (
                <span className="alert"><T text={'Emails will be scheduled in the advisor\'s timezone'} /></span>
              ), show: config.scheduling_strategy !== 'optin', required: true }
            ], required: true },
            { label: 't(Preview Email)', type: 'segment', fields: [
              { name: 'preview_strategy', type: 'radiogroup', options: [
                { value: 'send', text: 't(Send preview to agency reviewers)' },
                { value: 'nothing', text: 't(Do nothing)' }
              ], defaultValue: 'send' },
              { name: 'preview_introduction', type: 'htmlfield', toolbar: ['b','em','u','ol','ul','a'], show: config.preview_strategy === 'send', placeholder: 't(Enter an optional email introduction)' }
            ], required: true }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSuccess(result) {
    this.context.modal.close()
  }

}

const mapResources = (props, context) => ({
  advisors: {
    endpoint: '/api/admin/truevail/agency/advisors',
    query: { service: 'email' },
    sort: 'name'
  }
})

export default Container(mapResources)(Publish)
