import Infinite from '@admin/components/infinite'
import { DropTarget } from 'react-dnd'
import PropTypes from 'prop-types'
import Results from './results'
import numeral from 'numeral'
import React from 'react'

class Stage extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    connectDropTarget: PropTypes.func,
    fields: PropTypes.array,
    isOver: PropTypes.bool,
    pipeline: PropTypes.object,
    q: PropTypes.string,
    stage: PropTypes.object,
    onTransition: PropTypes.func
  }

  render() {
    const { connectDropTarget, stage } = this.props
    return (
      <div className="sales-dealboard-stage">
        <div className="sales-dealboard-stage-header">
          <div className="sales-dealboard-stage-header-label">
            <strong>{ stage.title }</strong><br />
            <div className="sales-dealboard-stage-header-progress">
              { Array(10).fill(0).map((i,j) => (
                <div className={ this._getProgress(stage.probability, (j + 1) * 10) } key={j} />
              ))}
            </div>
            Revenue: { numeral(stage.revenue).format('$0,0.00') }
          </div>
          <div className="sales-dealboard-stage-header-total">
            { stage.deals_count }
          </div>
        </div>
        { connectDropTarget(
          <div className="sales-dealboard-stage-body">
            <Infinite { ...this._getInfinite() } />
          </div>
        ) }
      </div>
    )
  }

  _getInfinite() {
    const { fields, pipeline, q, stage, onTransition } = this.props
    const { team } = this.context.admin
    return {
      endpoint: `/api/admin/sales/pipelines/${pipeline.id}/stages/${stage.id}/deals`,
      refresh: `/teams/${team.id}/admin/sales/pipelines/${pipeline.id}/stages/${stage.id}/deals`,
      ...q.length > 0 ? {
        filter: { q }
      } : {},
      layout: Results,
      empty: null,
      notFound: null,
      props: {
        fields,
        pipeline,
        stage,
        onTransition
      }
    }
  }

  _getProgress(probability, value) {
    const { stage } = this.props
    const classes = ['sales-dealboard-stage-header-progress-bar']
    if(stage.probability === 100) classes.push('won')
    else if(stage.probability === 0) classes.push('lost')
    else if(value <= stage.probability) classes.push('inprogress')
    return classes.join(' ')
  }

}

const target = {
  drop: (props, monitor, component) => {
    const item = monitor.getItem()
    props.onTransition(item.deal, item.deal.stage, props.stage)
  }
}

const targetCollector = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop()
})

Stage = DropTarget('ITEM', target, targetCollector)(Stage)

export default Stage
