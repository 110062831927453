import { useLocaleContext } from '@admin/components/locale'
import IconToken from '@admin/tokens/icon'
import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const DragToken = (props) => {

  const locale = useLocaleContext()?.locale || {}

  const { action, noun_project_id, entity, icon, label, social, type } = props

  const handleDragStart = (e) => {
    e.stopPropagation()
    e.dataTransfer.dropEffect = 'all'
    e.dataTransfer.setData(entity, entity)
    e.dataTransfer.setData('action', action)
    e.dataTransfer.setData('entity', entity)
    e.dataTransfer.setData('label', locale.t(label))
    e.dataTransfer.setData('type', type)
    // const dragImage = document.createElement('div')
    // const dragIcon = document.createElement('i')
    // dragImage.className = `flowchart-designer-block-draglayer ${entity}`
    // dragIcon.className = `fa fa-${icon}`
    // dragImage.appendChild(dragIcon)
    // document.body.appendChild(dragImage)
    // e.dataTransfer.setDragImage(dragImage, 20, 20)
  }

  const dragToken = {
    className: 'maha-drag-token',
    draggable: true,
    onDragStart: handleDragStart
  }

  const classes = ['maha-drag-token-icon']
  if(type) classes.push(type)
  if(entity) classes.push(entity)

  return (
    <div { ...dragToken }>
      <div className={ classes.join(' ')}>
        { noun_project_id &&
          <IconToken icon={{noun_project_id}} fg='FFFFFF' />        
        }
        { social &&
          <IconToken icon={{social}} fg='FFFFFF' />        
        }
        { icon &&
          <Icon icon={ icon } />        
        }
      </div>
      <div className="maha-drag-token-label">
        <T text={ label } />
      </div>
    </div>
  )
}

DragToken.propTypes = {
  entity: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string
}

export default DragToken