import TriggerToken from '@apps/automation/admin/tokens/trigger'
import Search from '@admin/components/search'
import PropTypes from 'prop-types'
import React from 'react'

class Trigger extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    maha: PropTypes.object
  }

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  _handleChoose = this._handleChoose.bind(this)

  render() {
    return <Search { ...this._getSearch() } />
  }

  _getSearch() {
    return {
      options: this._getTriggers(),
      group: true,
      search: false,
      format: TriggerToken,
      label: 'trigger',
      onChange: this._handleChoose,
      valueKey: 'value',
      textKey: 'value'
    }
  }

  _getTriggers() {
    const { admin, maha } = this.context
    const { triggers } = this.props.formdata
    return maha.triggers.filter(trigger => {
      const app = _.includes(admin.rights, `${trigger.app}:access_app`)
      const included = triggers ? _.includes(triggers, `${trigger.app}:${trigger.code}`) : true
      return app && included && trigger.manual !== false
    }).map(trigger => ({ value: trigger, group: trigger.appTitle }))
  }

  _handleChoose(trigger) {
    this.props.onNext({
      trigger: `${trigger.app}:${trigger.code}`
    })
  }

}

export default Trigger
