import ModalPanel from '@admin/components/modal_panel'
import Unassigned from './unassigned'
import PropTypes from 'prop-types'
import Assigned from './assigned'
import React from 'react'

class Assign extends React.Component {

  static contextTypes = {
    flash: PropTypes.object,
    modal: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    social_batch: PropTypes.object
  }

  state = {
    assigned: null
  }

  _handleAdd = this._handleAdd.bind(this)
  _handleCancel = this._handleCancel.bind(this)
  _handleMove = this._handleMove.bind(this)
  _handleRemove = this._handleRemove.bind(this)
  _handleSave = this._handleSave.bind(this)

  render() {
    if(!this.state.assigned) return null
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="truevail-social-batch-campaigns">
          <Unassigned { ...this._getUnassigned() } />
          <Assigned { ...this._getAssigned() } />
        </div>
      </ModalPanel>
    )
  }

  componentDidMount() {
    this._handleFetch()
  }

  componentDidUpdate(prevProps) {
  }

  _getAssigned() {
    const { assigned } = this.state
    return {
      assigned,
      onMove: this._handleMove,
      onRemove: this._handleRemove
    }
  }

  _getPanel() {
    return {
      title: 't(Assign Social Campaigns)',
      leftItems: [
        { label: 't(Cancel)', handler: this._handleCancel }
      ],
      rightItems: [
        { label: 't(Save)', handler: this._handleSave }
      ]
    }
  }

  _getUnassigned() {
    const { social_batch } = this.props
    const { assigned } = this.state
    return {
      assigned,
      social_batch,
      onAdd: this._handleAdd
    }
  }

  _handleAdd(social_campaign) {
    this.setState({
      assigned: [
        ...this.state.assigned,
        social_campaign
      ]
    })
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleFetch() {
    const { social_batch } = this.props
    this.context.network.request({
      endpoint: `/api/admin/truevail/agency/social_batches/${social_batch.id}/social_campaigns`,
      method: 'GET',
      onSuccess: ({ data }) => {
        this.setState({
          assigned: data
        })
      },
      onFailure: () => this.context.flash.set('error', 't(Unable to load assignments)')
    })
  }

  _handleMove(from, to) {
    const { assigned } = this.state
    this.setState({
      assigned: (from < to) ? [
        ...assigned.slice(0, from),
        ...assigned.slice(from + 1, to + 1),
        assigned[from],
        ...assigned.slice(to + 1)
      ] : [
        ...assigned.slice(0, to),
        assigned[from],
        ...assigned.slice(to, from),
        ...assigned.slice(from + 1)
      ]
    })
  }

  _handleRemove(index) {
    const { assigned } = this.state
    this.setState({
      assigned: assigned.filter((a, i) => {
        return i !== index
      })
    })
  }

  _handleSave() {
    const { social_batch } = this.props
    const { assigned } = this.state
    this.context.network.request({
      endpoint: `/api/admin/truevail/agency/social_batches/${social_batch.id}/social_campaigns`,
      method: 'PATCH',
      body: {
        social_campaign_ids: assigned.map(social_campaign => {
          return social_campaign.id
        })
      },
      onSuccess: () => this.context.modal.close(),
      onFailure: () => this.context.flash.set('error', 't(Unable to save assignments)')
    })
  }

  _handleUpdate() {
  }

}

export default Assign
