import AdvisorStatusToken from '@apps/truevail/admin/tokens/advisor_status'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Advisor extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    advisor: PropTypes.object,
    onBack: PropTypes.func,
    onDone: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: {}
  }

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { advisor } = this.props
    return {
      ref: this.formRef,
      title: 't(Edit Advisor Status)',
      method: 'PATCH',
      endpoint: `/api/admin/truevail/agency/advisors/${advisor.id}/edit`,
      action: `/api/admin/truevail/agency/advisors/${advisor.id}/status `,
      cancelIcon: 'chevron-left',
      saveText: null,
      buttons: [
        { label: 't(Done)', color: 'blue', handler: this._handleSubmit }
      ],
      onCancel: this._handleBack,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Status)', name: 'status', type: 'radiogroup', options: ['inactive','pending','configured','unconfigured','active'], format: AdvisorStatusToken, required: true }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(config) {
    this.props.onDone(config)
  }

}

export default Advisor
