import SocialCampaigns from './social_campaigns'
import EmailCampaigns from './email_campaigns'
import SocialBatches from './social_batches'
import StandardUsers from './standard_users'
import Subscriptions from './subscriptions'
import Configuration from './configuration'
import SupportUsers from './support_users'
import Page from '@admin/components/page'
import Overview from './overview'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.advisor.team.title,
  manager: {
    path: `/admin/truevail/agency/advisors/${resources.advisor.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview advisor={ resources.advisor } />
      },
      {
        label: 't(Configuration)',
        path: '/configuration',
        show: resources.advisor.team.is_active,
        panel: <Configuration advisor={ resources.advisor } />
      },
      {
        label: 't(Email Campaigns)',
        path: '/email_campaigns',
        show: resources.advisor.team.is_active,
        panel: <EmailCampaigns advisor={ resources.advisor } />
      },
      {
        label: 't(Social Batches)',
        path: '/social_batches',
        show: resources.advisor.team.is_active,
        panel: <SocialBatches advisor={ resources.advisor } />
      },
      {
        label: 't(Social Campaigns)',
        path: '/social_campaigns',
        show: resources.advisor.team.is_active,
        panel: <SocialCampaigns advisor={ resources.advisor } />
      },
      {
        label: 't(Subscriptions)',
        path: '/subscriptions',
        show: resources.advisor.team.is_active,
        panel: <Subscriptions advisor={ resources.advisor } />
      },
      {
        label: 't(Support Users)',
        path: '/support_users',
        show: resources.advisor.team.is_active,
        panel: <SupportUsers advisor={ resources.advisor } />
      },
      {
        label: 't(Users)',
        path: '/standard_users',
        show: resources.advisor.team.is_active,
        panel: <StandardUsers advisor={ resources.advisor } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  advisor: `/api/admin/truevail/agency/advisors/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
