import MJSONScreenshot from '@admin/components/mjson_screenshot'
import VoiceToken from '@apps/truevail/admin/tokens/voice'
import TagsToken from '@apps/crm/admin/tokens/tags'
import PropTypes from 'prop-types'
import React from 'react'

const getScreenshot = (entity) => ({
  engine: 'mjson',
  entity: `maha_contents/${entity.code}`,
  width: 100,
  height: 75,
  screenshoted_at: entity.screenshoted_at
})

const ContentToken = ({ content }) => (
  <div className="campaign-token">
    <div className="campaign-token-preview">
      <div className="campaign-token-preview-inner">
        <MJSONScreenshot { ...getScreenshot(content) } />
      </div>
    </div>
    <div className="campaign-token-label">
      <div className="token-link">
        { content.title }
      </div>
      { content.owner &&
        <div className="token-stats">
          <span>Agency: { content.owner.title }</span>
        </div>
      }
      { content.provider &&
        <div className="token-stats">
          <span>Provider: { content.provider.title }</span>
        </div>    
      }
      { content.team &&
        <div className="token-stats">
          <span>Team: { content.team.title }</span>
        </div>    
      }
      <div className="token-stats">
        <VoiceToken perspective={ content.perspective } language={ content.language } />
      </div>
      { content.tags.length > 0 &&
        <div className="token-stats">
          <TagsToken tags={ content.tags } />
        </div>
      }
    </div>
  </div>
)

ContentToken.propTypes = {
  content: PropTypes.object
}

export default ContentToken
