import ModalPanel from '@admin/components/modal_panel'
import Changelog from '@admin/components/changelog'
import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import Articles from './articles'
import React from 'react'

const sections = [
  { title: 't(Articles)', text: 't(Step by step how-to instructions)', component: Articles },
  { title: 't(Changelog)', text: 't(History of updates to the platform)', component: Changelog },
  { title: 't(Uptime)', text: 't(Monitor of platform availability)', link: 'https://stats.uptimerobot.com/o66MxtXvwz' }
]

class Help extends React.Component {

  static contextTypes = {
    help: PropTypes.object,
    host: PropTypes.object
  }

  static propTypes = {}

  _handleClose = this._handleClose.bind(this)

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="maha-results">
          { sections.map((section, index) => (
            <div className="maha-result" key={`section_${index}`} onClick={ this._handleClick.bind(this, section) }>
              <div className="maha-result-details">
                <strong><T text={ section.title } /></strong><br />
                <span><T text={ section.text } /></span>
              </div>
              <div className="maha-result-proceed">
                <Icon icon="chevron-right" />
              </div>
            </div>
          )) }
        </div>
      </ModalPanel>
    )
  }

  _getPanel() {
    return {
      title: 't(Help Center)',
      color: 'green',
      leftItems: [
        { icon: 'remove', handler: this._handleClose }
      ]
    }
  }

  _handleClick(section) {
    if(section.link) return this.context.host.window.open(section.link)
    this.context.help.push(section.component, {
      onBack: this._handleBack
    })
  }

  _handleBack() {
    this.context.help.pop()
  }

  _handleClose() {
    this.context.help.toggle()
  }

}

export default Help
