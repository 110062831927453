import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import TeamToken from '@admin/tokens/team'
import Import from './import'
import React from 'react'
import New from './new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Advisors)',
  collection: {
    endpoint: '/api/admin/truevail/agency/advisors',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Advisor)', key: 'team.title', sort: 'title',  primary: true, format: (advisor) => (
        <TeamToken { ..._.omit(advisor.team, 'is_active') } />
      ) },
      { label: 't(Status)', key: 'team.status', sort: 'status', collapsing: true, format: StatusToken }
    ],
    defaultSort: { key: 'title', order: 'asc' },
    criteria: {
      fields: [
        { label: 't(Advisor)', fields: [
          { name: 'Status', key: 'status', type: 'select', options: ['pending','configured','active','inactive','canceled'], format: StatusToken  }
        ] }
      ]
    },
    empty: {
      icon: 'user',
      title: 't(No Advisors)',
      text: 't(You have not yet created any advisors)',
      buttons: [
        { label: 't(Import Advisors)', modal: Import },
        { label: 't(Create Advisor)', modal: New }
      ]
    },
    entity: 'advisor',
    onClick: (record) => context.router.push(`/admin/truevail/agency/advisors/${record.id}`)
  },
  tasks: {
    icon: 'plus',
    items: [
      {
        label: 't(Add Advisor)',
        modal: New
      }, 
      {
        label: 't(Import Advisors)',
        modal: Import
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
