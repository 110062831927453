import { CSSTransition } from 'react-transition-group'
import AssetIcon from '@admin/components/asset_icon'
import Icon from '@admin/components/icon'
import Resumable from 'resumablejs'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import React from 'react'

export const UploaderContext = React.createContext()
UploaderContext.displayName = 'UploaderContext'

export const useUploaderContext = () => React.useContext(UploaderContext)

class Uploader extends React.Component {

  static childContextTypes = {
    uploader: PropTypes.object
  }

  static contextTypes = {
    admin: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    browseTarget: PropTypes.any,
    children: PropTypes.any,
    dropTarget: PropTypes.any
  }

  uploadRef = React.createRef()
  handler = null

  state = {
    uploads: []
  }

  _handleAdd = this._handleAdd.bind(this)
  _handleAssignBrowse = this._handleAssignBrowse.bind(this)
  _handleAssignDrop = this._handleAssignDrop.bind(this)
  _handleBrowse = this._handleBrowse.bind(this)
  _handleProcessed = this._handleProcessed.bind(this)
  _handleProgress = this._handleProgress.bind(this)
  _handleUploaded = this._handleUploaded.bind(this)

  render() {
    const { uploads } = this.state
    return (
      <UploaderContext.Provider value={ this.getChildContext() }>
        <div className="maha-uploader">
          { this.props.children }
          <CSSTransition in={ uploads.length > 0 } classNames="expanded" timeout={ 150 } mountOnEnter={ true } unmountOnExit={ true }>
            <div className="maha-uploads">
              { uploads.map((upload, index) => (
                <div className="maha-upload" key={`upload_${index}`}>
                  <div className="maha-upload-progress">
                    <div className="loader" style={ this._getProgress(upload) }></div>
                    <div className="maha-upload-icon">
                      <AssetIcon content_type={ upload.resumeable.file.type } />
                    </div>
                    <div className="maha-upload-name">
                      { upload.resumeable.file.name }
                    </div>
                    { _.includes(['added','uploading'], upload.status) &&
                      <div className="maha-upload-action" onClick={ this._handleRemove.bind(this, upload.resumeable) }>
                        <Icon icon="times" />
                      </div>
                    }
                  </div>
                </div>
              ))}
            </div>
          </CSSTransition>
          <div ref={ this.uploadRef } />
        </div>
      </UploaderContext.Provider>
    )
  }

  componentDidMount() {
    const { token } = this.context.admin
    const { browseTarget, dropTarget } = this.props
    this.resumable = new Resumable({
      target: '/api/admin/assets/upload',
      chunkSize: 1024 * 128,
      permanentErrors: [204, 400, 404, 409, 415, 500, 501],
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    this.resumable.on('fileAdded', this._handleAdd)
    this.resumable.on('fileProgress', this._handleProgress)
    this.resumable.on('fileSuccess', this._handleUploaded)
    this.resumable.assignBrowse(this.uploadRef.current)
    if(browseTarget) this.resumable.assignBrowse(browseTarget)
    if(dropTarget) this.resumable.assignDrop(dropTarget)
  }

  getChildContext() {
    return {
      uploader: {
        browse: this._handleBrowse,
        assignBrowse: this._handleAssignBrowse,
        assignDrop: this._handleAssignDrop
      }
    }
  }

  _getProgress(upload) {
    return {
      width: numeral(upload.progress).format('0%')
    }
  }

  _handleAdd(resumeable) {
    const { uploads } = this.state
    this.setState({
      uploads: [
        ...uploads,
        {
          resumeable,
          status: 'added',
          progress: 0,
          asset: null
        }
      ]
    })
    this.resumable.upload()
  }

  _handleAssignBrowse(ref, handler = null) {
    this.resumable.assignBrowse(ref)
    this.handler = handler
  }

  _handleAssignDrop(ref, handler = null) {
    this.resumable.assignDrop(ref)
    this.handler = handler
  }

  _handleBrowse(handler = null) {
    this.handler = handler
    this.uploadRef.current.click()
  }

  _handleJoin(upload) {
    const { admin, network } = this.context
    const { team } = admin
    const handler = this._handleProcessed.bind(this, upload)
    this._handleUpdate(upload.resumeable, { handler })
    network.subscribe({ 
      channel: `/teams/${team.id}/admin/assets/${upload.asset.id}`, 
      action: 'refresh', 
      handler 
    })
  }

  _handleLeave(upload) {
    const { admin, network } = this.context
    const { team } = admin
    network.unsubscribe({ 
      channel: `/teams/${team.id}/admin/assets/${upload.asset.id}`, 
      action: 'refresh', 
      handler: upload.handler 
    })
  }

  _handleProgress(resumeable) {
    this._handleUpdate(resumeable, {
      status: 'uploading',
      progress: resumeable.progress()
    })
  }

  _handleProcessed(upload) {
    this._handleLeave(upload)
    if(this.handler) this.handler(upload.asset)
    this._handleRemove(upload.resumeable)
  }

  _handleRemove(resumeable) {
    const { uploads } = this.state
    this.setState({
      uploads: uploads.filter(upload => {
        return upload.resumeable.uniqueIdentifier !== resumeable.uniqueIdentifier
      })
    })
    this.resumable.removeFile(resumeable)
  }

  _handleUpdate(resumeable, values, callback = () => {}) {
    const { uploads } = this.state
    this.setState({
      uploads: uploads.map(upload => ({
        ...upload,
        ...upload.resumeable.uniqueIdentifier === resumeable.uniqueIdentifier ? values : {}
      }))
    }, callback)
  }

  _handleUploaded(resumeable, message) {
    const asset = JSON.parse(message).data
    this._handleUpdate(resumeable, { asset }, () => {
      const upload = this.state.uploads.find(upload => upload.resumeable.uniqueIdentifier === resumeable.uniqueIdentifier)
      if(asset.status === 'assembled') return this._handleJoin(upload)
      if(this.handler) this.handler(asset)
      this._handleRemove(resumeable)
    })
  }

}

export default Uploader
