import BounceTypeToken from '@apps/automation/admin/tokens/bounce_type'
import ContactToken from '@apps/crm/admin/tokens/contact'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Bounces)',
  access: {},
  collection: {
    endpoint: `/api/admin/campaigns/email/${props.email_campaign.id}/bounces`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Contact)', key: 'contact.display_name', sort: 'last_name', primary: true, format: (email) => <ContactToken { ...email.contact } property="rfc822" /> },
      { label: 't(Bounce Type)', key: 'bounce_type', primary: true, padded: true, collapsing: true, format: BounceTypeToken }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    export: [
      { label: 't(ID)', key: 'id' },
      { label: 't(First Name)', key: 'contact.first_name' },
      { label: 't(Last Name)', key: 'contact.last_name' },
      { label: 't(Email)', key: 'contact.email' },
      { label: 't(Bounce Type)', key: 'bounce_type' },
      { label: 't(Bounce Subtype)', key: 'bounce_subtype' },
      { label: 't(Bounce Reason)', key: 'bounce_reason' }
    ],
    empty: {
      icon: 'exclamation',
      title: 't(No Bounces)',
      text: 't(There were no bounces for this email)'
    },
    entity: 'email',
    onClick: (record) => context.router.push(`/emails/${record.code}`)
  }
})

export default Panel(null, mapPropsToPanel)
