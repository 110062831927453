import ModalPanel from '@admin/components/modal_panel'
import IFrame from '@admin/components/html/iframe'
import Message from '@admin/components/message'
import Search from '@admin/components/search'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import React from 'react'

class EntityChooser extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.object,
    endpoint: PropTypes.string,
    entity: PropTypes.string,
    format: PropTypes.func,
    preview: PropTypes.func,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChoose: PropTypes.func
  }

  state = {
    entity: null
  }

  _handleChange = this._handleChange.bind(this)
  _handleChoose = this._handleChoose.bind(this)

  render() {
    const { entity } = this.state
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="maha-entity-chooser">
          <div className="maha-entity-chooser-list">
            <Search { ...this._getSearch() } />
          </div>
          <div className="maha-entity-chooser-preview">
            { entity ?
              <>
                <div className="maha-entity-chooser-preview-body">
                  <IFrame { ...this._getIFrame() } />
                </div>
                <div className="maha-entity-chooser-preview-footer">
                  <Button { ...this._getChoose() } />
                </div>
              </> :
              <Message { ...this._getMessage() } />
            }
          </div>
        </div>
      </ModalPanel>
    )    
  }

  _getChoose() {
    const { entity } = this.props
    return {
      label: `t(Choose) ${entity}`,
      color: 'blue',
      handler: this._handleChoose
    }
  }

  _getIFrame() {
    const { entity } = this.state
    const { preview } = this.props
    return {
      src: `/mjson/dynamic/${preview(entity)}?mode=show`
    }
  }

  _getMessage() {
    const { entity } = this.props
    return {
      icon: 'comment',
      title: `t(Choose) ${entity}`,
      text: 't(Choose an item to see a preview)'
    }
  }

  _getPanel() {
    const { entity, onBack, onCancel } = this.props
    return {
      label: `t(Choose) ${entity}`,
      leftItems: [
        ...onBack? [
          { icon: 'chevron-left', handler: onBack }
        ] : [
          { label: 't(Cancel)', handler: onCancel }
        ]
      ]
    }
  }

  _getSearch() {
    const { defaultValue, endpoint, entity, format } = this.props
    return {
      defaultValue,
      endpoint,
      entity: entity,
      format,
      valueKey: null,
      textKey: 'title',
      onChange: this._handleChange
    }
  }

  _handleChange(entity) {
    this.setState({ entity })
  }

  _handleChoose() {
    const { entity } = this.state
    this.props.onChoose(entity)
  }

}

export default EntityChooser
