
import ModalPanel from '@admin/components/modal_panel'
import Message from '@admin/components/message'
import Img from '@admin/components/html/img'
import PropTypes from 'prop-types'
import React from 'react'

class Configuring extends React.PureComponent {

  static propTypes = {}

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="truevail-configure-configuring">
          <div className="truevail-configure-configuring-header">
            <div className="truevail-configure-overview-header-image">
              <Img src="https://assets.mahaplatform.com/imagecache/fit=cover&w=1000&h=250/assets/08dab79852a8a1440b5dd7ea8148b179/halaszbastya.jpg.webp" />
            </div>
          </div>
          <div className="truevail-configure-configuring-body">
            <Message { ...this._getMessage() } />
          </div>
        </div>
      </ModalPanel>
    )
  }

  _getMessage() {
    return {
      icon: 'circle-o-notch fa-spin',
      title: 't(Configuring Your Account)',
      text: 't(Congratulations on finishing your account setup! Our team is configuring your account and you\'ll be notified as soon as it\'s complete. Please monitor your email for any outreach and updates on your services)'
    }
  }

  _getPanel() {
    return {
      title: 't(Setup Complete)'
    }
  }

}

export default Configuring
