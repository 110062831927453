import ChannelToken from '@apps/truevail/admin/tokens/channel'
import SchedulingToken from './scheduling'
import Form from '@admin/components/form'
import SignatureToken from './signature'
import StrategyToken from './strategy'
import PropTypes from 'prop-types'
import React from 'react'

class Email extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    configuration: PropTypes.object,
    onBack: PropTypes.func,
    onDone: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: null
  }

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    if(!this.state.config) return null
    return <Form { ...this._getForm() } />
  }

  componentDidMount() {
    const { configuration } = this.props
    this.setState({
      config: configuration
    })
  }

  _getForm() {
    const { config } = this.state
    return {
      ref: this.formRef,
      title: 't(Email Newsletters)',
      cancelIcon: 'chevron-left',
      saveText: null,
      buttons: [
        { label: 't(Done)', color: 'blue', handler: this._handleSubmit }
      ],
      onCancel: this._handleBack,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Service)', instructions: 't(Opt-in or Opt-out of the done-for-you email newsletter campaign service)', type: 'segment', fields: [
              { name: 'email_strategy', type: 'radiogroup', options: ['optin','optout'], defaultValue: config.email_strategy || 'optin', format: StrategyToken, required: true },
              { label: 't(Channels)', instructions: 't(Choose which email channels you\'d like to subscribe to)', name: 'email_channel_ids', type: 'checkboxgroup', entity: 'channel', endpoint: '/api/admin/truevail/advisor/channels', valueKey: 'id', textKey: 'title', filter: { service: { $eq: 'email' }, type: { $eq: 'public' } }, deselectable: true, format: (channel) => (
                <ChannelToken channel={ channel } />
              ), defaultValue: config.email_channel_ids },
              { label: 't(Scheduling)', instructions: 't(Choose preferred method of your campaign scheduling. You will always receive a preview of your content approximately one week before the campaign scheduling date)', name: 'email_scheduling', type: 'radiogroup', show: config.email_strategy === 'optin', options: ['optout','optin'], defaultValue: config.email_scheduling || 'optout', format: SchedulingToken, required: true }  
            ] },
            { label: 't(Sender)', instructions: 't(Enter the sender details to be used on your email campaigns)', type: 'segment', fields: [
              { label: 't(From Name)', instructions: 't(Provide the name that should appear as the sender of the emails)', name: 'from_name', type: 'textfield', required: true, defaultValue: config.from_name, placeholder: 't(Enter From Name (example: Mary from Happy Traveler))' },
              { label: 't(From Email)', instructions: 't(Provide the email that should appear as the sender of the emails)', name: 'from_email', type: 'textfield', required: true, defaultValue: config.from_email, placeholder: 't(Enter From Email (example: mary@happytraveler.com))' }
            ], required: true },
            { label: 't(Personal Signature)', instructions: 't(Your personal signature will be used as a sign off at the end of your emails)', type: 'segment', fields: [
              { name: 'signature_strategy', type: 'radiogroup', options: ['upload','text','none'], defaultValue: config.signature_strategy || 'upload', format: SignatureToken, required: true },
              { label: 't(Signature)', name: 'signature_id', show: config.signature_strategy === 'upload', type: 'attachmentfield', prompt: 'Upload Signature', allow: { content_types: ['images'] }, multiple: false, required: true, defaultValue: config.signature_id  }
            ], required: true },
            { label: 't(Contact List)', instructions: 't(Provide your contact list to be subscribed to your email newsletter list) (CSV, XLS, or XSLX)', name: 'contacts_id', type: 'attachmentfield', prompt: 'Upload Contact List', allow: { extensions: ['csv','xls','xlsx'] }, multiple: false, defaultValue: config.contacts_id  }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(config) {
    this.props.onDone(config)
  }

}

export default Email
