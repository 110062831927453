import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class LinkField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.object,
    value: PropTypes.object,
    website: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    link: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    if(!this.state.link) return null
    return (
      <div className="mjson-designer-stylefield">
        <Form { ...this._getForm() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props
    const { link } = this.state
    if(!_.isEqual(link, prevState.link)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : {}
  }

  _getForm() {
    const { link } = this.state
    const options = this._getLinkStrategies()
    return {
      inline: true,
      onChange: this._handleUpdate,
      sections: [
        {
          fields: [
            { name: 'strategy', type: 'dropdown', options, direction: 'downward', defaultValue: link.strategy },
            ...this._getLinkStrategy()
          ]
        }
      ]
    }
  }

  _getLinkStrategies() {
    const { website } = this.props
    const strategies = [
      { value: 'none', text: 't(Nothing)' },
      { value: 'anchor', text: 't(Scroll to Anchor)' },
      { value: 'web', text: 't(Web Address)' },
      { value: 'email', text: 't(Email Address)' },
      { value: 'phone', text: 't(Phone Number)' },
      { value: 'asset', text: 't(File)' },
      ...website ? [
        { value: 'page', text: 't(Page)' }
      ] : [],
      { value: 'form', text: 't(Form)' },
      { value: 'landing_page', text: 't(Landing Page)' },
      { value: 'store', text: 't(Store)' },
      { value: 'other', text: 't(Other)' }
    ]
    return strategies
  }

  _getLinkStrategy() {
    const { website } = this.props
    const { link } = this.state
    if(link.strategy === 'web') {
      return [
        { entity: 't(URL)', name: 'url', type: 'textfield', defaultValue: link.url  }
      ]
    } else if(link.strategy === 'email') {
      return [
        { entity: 't(Email)', name: 'email', type: 'textfield', defaultValue: link.email  }
      ]
    } else if(link.strategy === 'phone') {
      return [
        { entity: 't(Phone Number)', name: 'phone', type: 'textfield', defaultValue: link.phone  }
      ]
    } else if(link.strategy === 'page') {
      return [
        { entity: 't(Page)', name: 'page_id', type: 'lookup', endpoint: `/api/admin/websites/websites/${website.id}/pages`, valueKey: 'id', textKey: 'title', defaultValue: link.page_id }
      ]
    } else if(link.strategy === 'form') {
      return [
        { entity: 't(Form)', name: 'form_id', type: 'lookup', endpoint: '/api/admin/forms/forms', valueKey: 'id', textKey: 'title', defaultValue: link.form_id }
      ]
    } else if(link.strategy === 'landing_page') {
      return [
        { entity: 't(Landing Page)', name: 'landing_page_id', type: 'lookup', endpoint: '/api/admin/forms/landing_pages', valueKey: 'id', textKey: 'description', defaultValue: link.landing_page_id }
      ]
    } else if(link.strategy === 'store') {
      return [
        { entity: 't(Store)', name: 'store_id', type: 'lookup', search: false, endpoint: '/api/admin/stores/stores', valueKey: 'id', textKey: 'title', defaultValue: link.store_id }
      ]
    } else if(link.strategy === 'asset') {
      return [
        { name: 'asset_id', type: 'attachmentfield', prompt: 't(Choose File)', defaultValue: link.asset_id }
      ]
    } else if(link.strategy === 'other') {
      return [
        { entity: 't(Destination)', name: 'destination', type: 'textfield', defaultValue: link.destination }
      ]
    }
    return []
  }

  _handleChange() {
    const { link } = this.state
    this.props.onChange(link.strategy !== 'none' ? link : null)
  }

  _handleSet(link) {
    this.setState({
      link: {
        strategy: 'none',
        ...link || {}
      }
    })
  }

  _handleUpdate(link) {
    this.setState({ link })
  }

}

export default LinkField
