import ContactToken from '@apps/crm/admin/tokens/contact'
import Container from '@admin/components/container'
import UserToken from '@admin/tokens/user'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {
    pipeline: PropTypes.object,
    contact: PropTypes.object,
    fields: PropTypes.array
  }

  state = {
    deal: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    const { contact, fields, pipeline } = this.props
    const { admin } = this.context
    const { deal } = this.state
    return {
      title: 't(New Deal)',
      method: 'POST',
      action: '/api/admin/sales/deals',
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', required: true },
            { label: 't(Description)', name: 'description', type: 'textfield' },
            ...contact ? [
              { name: 'contact_id', type: 'hidden', value: contact.id }
            ] : [
              { label: 't(Contact)', name: 'contact_id', type: 'lookup', entity: 'contact', endpoint: '/api/admin/crm/contacts', valueKey: 'id', textKey: 'display_name', required: true, format: ContactToken }
            ],
            { label: 't(Owner)', name: 'owner_id', type: 'lookup', endpoint: '/api/admin/users', valueKey: 'id', textKey: 'full_name', defaultValue: admin.user.id, format: UserToken },
            ...pipeline ? [
              { name: 'pipeline_id', type: 'hidden', value: pipeline.id },
              { label: 't(Stage)', name: 'stage_id', type: 'dropdown', endpoint: `/api/admin/sales/pipelines/${pipeline.id}/stages`, valueKey: 'id', textKey: 'title', selectFirst: true, required: true }
            ] : [
              { label: 't(Pipeline)', type: 'segment', required: true, fields: [
                { entity: 'Pipeline', name: 'pipeline_id', type: 'dropdown', endpoint: '/api/admin/sales/pipelines', valueKey: 'id', textKey: 'title', required: true },
                ...deal.pipeline_id ? [
                  { label: 't(Stage)', name: 'stage_id', type: 'dropdown', show: deal.pipeline_id, endpoint: `/api/admin/sales/pipelines/${deal.pipeline_id}/stages`, valueKey: 'id', textKey: 'title', selectFirst: true, required: true }
                ] : []
              ] }
            ],
            { label: 't(Expected Close Date)', name: 'closes_on', type: 'datefield' },
            { label: 't(Pricing)', type: 'segment', fields: [
              { name: 'pricing_type', type: 'radiogroup', options: [
                { value: 'fixed', text: 't(Fixed Price)' },
                { value: 'recurring', text: 't(Recurring Revenue)' }
              ], required: true, defaultValue: 'fixed' },
              { label: 't(Amount)', name: 'amount', show: deal.pricing_type === 'fixed', type: 'moneyfield' },
              { label: 't(Annual Recurring Revenue)', name: 'annual_recurring_revenue', show: deal.pricing_type === 'recurring', type: 'moneyfield' }
            ] },
            ...fields ? this._getProperties() : []
          ]
        }
      ]
    }
  }

  _getProperties() {
    const { fields } = this.props
    return fields.map(field => ({
      type: field.type,
      name: `values.${field.code}`,
      ...field.config
    }))
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(deal) {
    this.setState({ deal })
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

const mapResources = (props, context) => props.pipeline ? ({
  fields: `/api/admin/sales_pipelines/${props.pipeline.id}/fields`
}) : null

export default Container(mapResources)(New)
