import StandardUsers from './standard_users'
import SupportUsers from './support_users'
import Page from '@admin/components/page'
import Overview from './overview'
import Advisors from './advisors'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.agency.team.title,
  manager: {
    path: `/admin/truevail/admin/agencies/${resources.agency.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview agency={ resources.agency } />
      },
      {
        label: 't(Advisors)',
        panel: <Advisors agency={ resources.agency } />
      },
      {
        label: 't(Support Users)',
        path: '/support_users',
        panel: <SupportUsers agency={ resources.agency } />
      },
      {
        label: 't(Users)',
        path: '/standard_users',
        panel: <StandardUsers agency={ resources.agency } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  agency: `/api/admin/truevail/admin/agencies/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
