import ContactToken from '@apps/crm/admin/tokens/contact'
import UserToken from '@admin/tokens/user'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {
    deal: PropTypes.object,
    fields: PropTypes.array
  }

  state = {
    formdata: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    const { formdata } = this.state
    const { admin } = this.context
    const { deal } = this.props
    return {
      title: 't(Edit Deal)',
      method: 'PATCH',
      endpoint: `/api/admin/sales/deals/${deal.id}/edit`,
      action: `/api/admin/sales/deals/${deal.id}`,
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', required: true },
            { label: 't(Description)', name: 'description', type: 'textfield' },
            { label: 't(Contact)', name: 'contact_id', type: 'lookup', endpoint: '/api/admin/crm/contacts', valueKey: 'id', textKey: 'display_name', required: true, format: ContactToken },
            { label: 't(Owner)', name: 'owner_id', type: 'lookup', endpoint: '/api/admin/users', valueKey: 'id', textKey: 'full_name', format: UserToken },
            { label: 't(Expected Close Date)', name: 'closes_on', type: 'datefield' },
            { label: 't(Pricing)', type: 'segment', fields: [
              { name: 'pricing_type', type: 'radiogroup', options: [
                { value: 'fixed', text: 't(Fixed Price)' },
                { value: 'recurring', text: 't(Recurring Revenue)' }
              ], required: true, defaultValue: ['fixed'] },
              { label: 't(Amount)', name: 'amount', show: formdata.pricing_type === 'fixed', type: 'moneyfield' },
              { label: 't(Annual Recurring Revenue)', name: 'annual_recurring_revenue', show: formdata.pricing_type === 'recurring', type: 'moneyfield' }
            ] },
            ...this._getProperties()
          ]
        }
      ]
    }
  }

  _getProperties() {
    const { fields } = this.props
    return fields.map(field => ({
      type: field.type,
      name: `values.${field.code}`,
      ...field.config
    }))
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(formdata) {
    this.setState({ formdata })
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Edit
