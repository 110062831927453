import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const service_types = {
  choose: {
    title: 't(Choose my own color scheme)',
    text: 't(Choose up to three colors to use as a color scheme for your content)'
  },
  design: {
    title: 't(Create a color scheme for me)',
    text: 't(Let the Truevail design team choose an attractive color scheme for you)'
  }
}

const ColorToken = ({ value }) => (
  <div className="token">
    <strong><T text={ service_types[value].title } /></strong><br />
    <T text={ service_types[value].text } />
    
  </div>
)

ColorToken.propTypes = {
  value: PropTypes.string
}

export default ColorToken
