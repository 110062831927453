import SubscriptionToken from '@apps/truevail/admin/tokens/subscription'
import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'

const mapPropsToPanel = (props, context) => ({
  title: 't(Subscriptions)',
  collection: {
    endpoint: `/api/admin/truevail/agency/advisors/${props.advisor.id}/subscriptions`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Subscription)', key: 'type', primary: true, format: SubscriptionToken },
      { label: 't(Status)', key: 'status', collapsing: true, primary: true, format: StatusToken }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      icon: 'refresh',
      title: 't(No Subscriptions)',
      text: 't(You have not yet configured any subscriptions for this advsior)'
    },
    entity: 'advisor',
    recordTasks: (record) => [
      { 
        label: 't(Deactivate Subscription)', 
        show: record.status === 'active',
        request: {
          endpoint: `/api/admin/truevail/agency/advisors/${props.advisor.id}/subscriptions/${record.id}/activate`,
          method: 'PATCH',
          onFailure: () => context.flash.set('error', 't(Unable to deactivate this subscription)'),
          onSuccess: () => context.flash.set('success', 't(You have sucessfully deactivated this subscription)')
        }
      },
      { 
        label: 't(Activate Subscription)', 
        show: record.status === 'inactive',
        request: {
          endpoint: `/api/admin/truevail/agency/advisors/${props.advisor.id}/subscriptions/${record.id}/activate`,
          method: 'PATCH',
          onFailure: () => context.flash.set('error', 't(Unable to activate this subscription)'),
          onSuccess: () => context.flash.set('success', 't(You have sucessfully activated this subscription)')
        }
      },
      { 
        label: 't(Delete Subscription)', 
        confirm: 't(Are you sure you want to delete this subscription?)',
        request: {
          endpoint: `/api/admin/truevail/agency/advisors/${props.advisor.id}/subscriptions/${record.id}`,
          method: 'DELETE',
          onFailure: () => context.flash.set('error', 't(Unable to delete this subscription)'),
          onSuccess: () => context.flash.set('success', 't(You have sucessfully deleted this subscription)')
        }
      }
    ],
    onClick: (record) => context.router.push(`/admin/truevail/agency/advisors/${props.advisor.id}/subscriptions/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
