import email from '../../entities/email/blocks'
import page from '../../entities/page/blocks'
import React, { Fragment } from 'react'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import DragToken from './token'

const Content = (props) => {

  const { type } = props

  const blocks = type === 'email' ? email : page

  const elements = [
    { label: 't(Sections)', elements: [
      ...type === 'email' ? [
        { 
          icon: 'cog', 
          label: 't(Email Article)', 
          entity: 'section', 
          type: 'email_article', 
          action: 'new' 
        }
      ] : [],
      { 
        icon: 'cog', 
        label: 't(Prebuilt Design)', 
        entity: 'section', 
        type: 'module', 
        action: 'new' 
      },
      { 
        icon: 'cog', 
        label: 't(Blank Section)', 
        entity: 'section', 
        type: 'section', 
        action: 'new' 
      }
    ] },
    { label: 't(Blocks)', elements: blocks.map(block => ({
      noun_project_id: block.noun_project_id, 
      social: block.social, 
      label: block.label, 
      entity: 'block',
      type: block.type,
      action: 'add'
    })) },
    { label: 't(Rows)', elements: [
      { 
        icon: 'cog', 
        label: 't(1 Column Row)', 
        entity: 'row', 
        type: 'one_column', 
        action: 'add' 
      },
      { 
        icon: 'cog', 
        label: 't(2 Column Row)', 
        entity: 'row', 
        type: 'two_column', 
        action: 'add' 
      },
      { 
        icon: 'cog', 
        label: 't(3 Column Row)', 
        entity: 'row', 
        type: 'three_column', 
        action: 'add' 
      },
      { 
        icon: 'cog', 
        label: 't(4 Column Row)', 
        entity: 'row', 
        type: 'four_column', 
        action: 'add' 
      }
    ] },
    { label: 't(Columns)', elements: [
      { 
        icon: 'cog', 
        label: 't(Empty Column)', 
        entity: 'column', 
        type: 'column', 
        action: 'add' 
      }
    ] },
  ]

  return (
    <div className="mjson-designer-content-panel">
      { elements.map((element, i) => (
        <Fragment key={`element_${i}`}>
          <div className="mjson-designer-content-title">
            <T text={ element.label } />
          </div>
          <div className="mjson-designer-content-elements">
            { element.elements.map((element, j) => (
              <div className="mjson-designer-content-element" key={`element_${i}_${j}`} >
                <DragToken { ...element }/>
              </div>
            )) }
          </div>
        </Fragment>        
      ) ) }
    </div>
  )
}

Content.propTypes = {
  type: PropTypes.string
}

export default Content