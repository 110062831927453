import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import React from 'react'

const SocialBatch = ({ social_batch }) => (
  <div className="campaign-token">
    <div className="campaign-token-label">
      <div className="token-link">
        { social_batch.title }  
      </div>
      <div className="token-stats">
        <span>{ pluralize('social campaign', social_batch.social_campaigns_count, true) } </span>
        <span>{ pluralize('publishing', social_batch.publishings_count, true) } </span>
      </div>
    </div>
  </div>
)

SocialBatch.propTypes = {
  social_batch: PropTypes.object
}

export default SocialBatch
