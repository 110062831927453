import IconStyleField from '@admin/components/mjson_designer/components/iconstylefield'
import TextField from '@admin/components/mjson_designer/components/textstylefield'
import ShareField from '@admin/components/mjson_designer/components/sharefield'
import WebRange from '@admin/components/mjson_designer/components/webrange'
import { normalizeConfig } from '../utils'
import Form from '@admin/components/form'
import * as styles from '../styles'
import PropTypes from 'prop-types'
import settings from '../settings'
import React from 'react'

class EditShareBlock extends React.PureComponent {

  static propTypes = {
    device: PropTypes.string,
    entity: PropTypes.object,
    theme: PropTypes.object,
    type: PropTypes.string,
    onChange: PropTypes.func,
    onDone: PropTypes.func
  }

  formRef = React.createRef()

  _handleCancel = this._handleCancel.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  constructor(props) {
    super(props)
    const { entity } = props
    this.state = {
      default: entity,
      config: entity
    }
  }

  render() {
    return <Form { ...this._getForm() } />
  }

  componentDidUpdate(prevProps, prevState) {
    const { config } = this.state
    if(!_.isEqual(config, prevState.config)) {
      this._handleChange()
    }
  }

  _getForm() {
    const { device, entity, theme, type } = this.props
    const { config } = this.state
    return {
      ref: this.formRef,
      onChange: this._handleUpdate,
      onSuccess: this._handleDone,
      showHeader: false,
      buttons: [
        { label: 't(Cancel)', color: 'blue', basic: true, handler: this._handleCancel },
        { label: 't(Done)', color: 'blue', handler: this._handleSubmit }
      ],
      tabs: [
        {
          label: 't(Content)',
          sections: [
            {
              fields: [
                { label: 'Networks', name: 'content.networks', type: ShareField, defaultValue: [
                  { service: 'facebook', text: 'Share on Facebook' },
                  { service: 'linkedin', text: 'Share on LinkedIn' },
                  { service: 'pinterest', text: 'Share on Pinterest' }
                ], value: config.content.networks }
              ]
            }
          ]
        },
        {
          label: 't(Styles)',
          sections: [
            {
              label: 't(Links)',
              collapsing: true,
              collapsed: true,
              fields: [
                { label: 't(Icon)', name: 'styles.icon', type: IconStyleField, device, theme, canHover: false, defaultValue: {
                  size: '24px',
                  backgroundColor: {
                    value: 'default'
                  },
                  color: {
                    value: 'default'
                  },
                  radius: {
                    type: 'circular'
                  }
                }, value: config.styles.icon },
                { label: 't(Text)', name: 'styles.text', type: TextField, device, theme, omitFeatures: ['linkColor','linkFormat','alignment'], defaultColor: true, defaultValue: {
                  preset: 'fineprint',
                  color: { value: 'default'}
                }, value: config.styles.text },
                { label: 't(Padding)', name: 'styles.linkPadding', device, type: WebRange, defaultValue: '5px', units: [
                  { name: 'px', min: 0, max: 1000, step: 1 }
                ], nullValues: ['none','0px'], value: config.styles.linkPadding },
                { label: 't(Alignment)', name: 'styles.align', type: 'alignmentfield', defaultValue: 'center', value: config.styles.align }
              ]
            },
            styles.BackgroundSection(config, device, theme, type),
            styles.BorderSection(config, device, theme, type),
            styles.SpacingSection(config, device, theme, type),
            styles.VisibilitySection(config, device, theme, type)
          ]
        },
        {
          label: 't(Settings)',
          sections: settings(config, type, entity)
        }
      ]
    }
  }

  _getNormalized() {
    const { entity } = this.props
    const { config } = this.state
    return normalizeConfig({
      ...entity,
      meta: config.meta,
      settings: config.settings,
      styles: config.styles,
      content: config.content
    })
  }

  _handleCancel() {
    this.props.onDone(this.state.default)
  }

  _handleChange() {
    const config = this._getNormalized()
    this.props.onChange(config)
  }

  _handleDone() {
    const config = this._getNormalized()
    this.props.onDone(config)
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleUpdate(config) {
    this.setState({
      config: {
        ...this.state.config,
        ...config
      }
    })
  }

}

export default EditShareBlock
