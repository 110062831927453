import UserStatusToken from '@apps/truevail/admin/tokens/user_status'
import Panel from '@admin/components/panel'
import UserToken from '@admin/tokens/user'
import React from 'react'
import New from './new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Users)',
  collection: {
    endpoint: `/api/admin/truevail/admin/agencies/${props.agency.id}/users`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Name)', key: 'last_name', primary: true, format: UserToken },
      { label: 't(Activated)', key: 'account.activation_sent_at', primary: true, collapsing: true, format: UserStatusToken }
    ],
    defaultSort: { key: 'last_name', order: 'asc' },
    empty: {
      icon: 'user',
      title: 't(No Active Users)',
      text: 't(There are no active users for this advisor)',
      buttons: [
        { label: 'Add User', modal: <New agency={ props.agency } /> }
      ]
    },
    entity: 'user',
    recordTasks: (record) => [
      {
        label: 't(Send Activation Email)',
        show: record.account.activated_at === null,
        request: {
          endpoint: `/api/admin/truevail/admin/agencies/${props.agency.id}/users/${record.id}/activate`,
          method: 'PATCH',
          body: {
            is_active: true
          },
          onFailure: (result) => context.flash.set('error', 't(Unable to activate user)')
        }
      },
      {
        label: 't(Activate User)',
        show: record.account.activated_at !== null && !record.is_active,
        request: {
          endpoint: `/api/admin/truevail/admin/agencies/${props.agency.id}/users/${record.id}/enable`,
          method: 'PATCH',
          body: {
            is_active: true
          },
          onFailure: (result) => context.flash.set('error', 't(Unable to activate user)')
        }
      },
      {
        label: 't(Deactivate User)',
        show: record.account.activated_at !== null && record.is_active,
        request: {
          endpoint: `/api/admin/truevail/admin/agencies/${props.agency.id}/users/${record.id}/enable`,
          method: 'PATCH',
          body: {
            is_active: false
          },
          onFailure: (result) => context.flash.set('error', 't(Unable to deactivate user)')
        }
      }
    ]
  },
  tasks : {
    icon: 'plus',
    items: [
      { label: 'Add User', modal: <New agency={ props.agency } /> }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
