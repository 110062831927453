import MarkerStyleField from '@admin/components/mjson_designer/components/markerstylefield'
import ResponsiveField from '@admin/components/mjson_designer/components/responsivefield'
import SliderField from '@admin/components/mjson_designer/components/sliderfield'
import WebRange from '@admin/components/mjson_designer/components/webrange'
import OptionsField from '@admin/components/form/optionsfield'
import DropDownField from '@admin/components/form/dropdown'
import { normalizeConfig } from '../utils'
import Form from '@admin/components/form'
import * as styles from '../styles'
import settings from '../settings'
import PropTypes from 'prop-types'
import React from 'react'

const DropDown = (props) => <ResponsiveField { ...props } component={ DropDownField } />
const Options = (props) => <ResponsiveField { ...props } component={ OptionsField } />
const Range = (props) => <ResponsiveField { ...props } component={ WebRange } />

class Collection extends React.PureComponent {

  static contextTypes = {
    form: PropTypes.object
  }

  static propTypes = {
    device: PropTypes.string,
    entity: PropTypes.object,
    theme: PropTypes.object,
    type: PropTypes.string,
    website: PropTypes.object,
    onChange: PropTypes.func,
    onDone: PropTypes.func
  }

  formRef = React.createRef()

  _handleCancel = this._handleCancel.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  constructor(props) {
    super(props)
    const { entity } = props
    this.state = {
      default: entity,
      config: entity
    }
  }

  render() {
    return <Form { ...this._getForm() } />
  }

  componentDidUpdate(prevProps, prevState) {
    const { config } = this.state
    if(!_.isEqual(config, prevState.config)) {
      this._handleChange()
    }
  }

  _getForm() {
    const { device, entity, theme, type, website } = this.props
    const { config } = this.state
    return {
      ref: this.formRef,
      onChange: this._handleUpdate,
      onSuccess: this._handleDone,
      showHeader: false,
      buttons: [
        { label: 't(Cancel)', color: 'blue', basic: true, handler: this._handleCancel },
        { label: 't(Done)', color: 'blue', handler: this._handleSubmit }
      ],
      tabs: [
        {
          label: 't(Content)',
          sections: [
            {
              fields: [
                { name: 'content.children', type: 'hidden', value: config.content.children },
                { label: 't(Display)', name: 'content.display', type: 'radiogroup', options: [
                  { value: 'column', text: 't(Column)' },
                  { value: 'grid', text: 't(Grid)' },
                  { value: 'slider', text: 't(Slider)' }
                ], defaultValue: 'column', value: config.content.display }
              ]
            },
            {
              label: 'Options',
              fields: [
                { name: 'content.children', type: 'hidden', value: config.content.children },
                { label: 't(Columns)', name: 'content.columns', type: Options, options: [1,2,3,4,5], canHover: false, include: config.content.display !== 'column', defaultValue: 1, value: config.content.columns },
                { label: 't(Marker)', name: 'content.markerType', type: 'radiogroup', options: [
                  { value: 'none', text: 't(None)' },
                  { value: 'number', text: 't(Number)' }
                ], defaultValue: 'none', value: config.content.markerType },
                { label: 't(Slider)', name: 'content.slider', show: config.content.display === 'slider', type: SliderField, value: config.content.slider }
              ]
            }
          ]
        },
        {
          label: 't(Styles)',
          sections: [
            styles.SliderSection(config, device, theme, website, type),
            {
              label: 't(Marker)',
              instructions: 't(Specify how to style the marker)',
              include: config.content.markerType !== 'none',
              marker: config.content.markerType,
              collapsing: true,
              collapsed: true,
              fields: [
                { label: 't(Horizontal Position)', name: 'styles.markerPosition', type: DropDown, device, options: [
                  { value: 'left', text: 't(Left)' },
                  { value: 'center', text: 't(Center)' },
                  { value: 'right', text: 't(Right)' }
                ], include: config.content.markerType !== 'none' && config.content.display === 'column', defaultValue: 'left', value: config.styles.markerPosition },
                { label: 't(Vertical Alignment)', name: 'styles.markerVerticalAlign', type: 'dropdown', options: [
                  { value: 'top', text: 't(Top)' },
                  { value: 'middle', text: 't(Middle)' },
                  { value: 'bottom', text: 't(Bottom)' }
                ], include: config.content.markerType !== 'none', defaultValue: 'top', value: config.styles.markerVerticalAlign },
                { label: 't(Marker)', name: 'styles.marker', type: MarkerStyleField, device, theme, defaultValue: {
                  size: '25px',
                  backgroundColor: { value: '#FFFFFF' },
                  radius: { type: 'circular' },
                  color: { value: '#000000' },
                  padding: '10px'
                },value: config.styles.marker },
                { label: 't(Rail Color)', name: 'styles.railColor', type: styles.BackgroundColor, device, theme, include: config.content.markerType !== 'none' && config.content.display === 'column', value: config.styles.railColor }
              ]
            },
            {
              label: 't(Row)',
              instructions: 't(Specify how this row should appear within its parent)',
              collapsing: true,
              collapsed: true,
              fields: [
                { label: 't(Row Width)', name: 'styles.rowWidth', device, type: Range, units: [
                  { name: 'px', min: 0, max: 1200, step: 1 }
                ], nullValues: ['auto'], defaultValue: 'auto', value: config.styles.rowWidth },
                { label: 't(Horizontal Alignment)', name: 'styles.rowAlign', type: 'dropdown', options: [
                  { value: 'left', text: 't(Left)' },
                  { value: 'center', text: 't(Center)' },
                  { value: 'right', text: 't(Right)' }
                ], defaultValue: 'center', value: config.styles.rowAlign }
              ]
            },
            {
              label: 't(Columns)',
              instructions: 't(Specify how columns should behave within this row)',
              collapsing: true,
              collapsed: true,
              fields: [
                { label: 't(Gap Between Columns)', name: 'styles.gap', device, type: Range, units: [
                  { name: 'px', min: 0, max: 25, step: 1 }
                ], nullValues: ['none','0px'], defaultValue: 'none', value: config.styles.gap },
                { label: 't(Horizontal Alignment)', name: 'styles.columnHorizontalAlign', type: 'dropdown', options: [
                  { value: 'left', text: 't(Left)' },
                  { value: 'center', text: 't(Center)' },
                  { value: 'right', text: 't(Right)' }
                ], defaultValue: 'center', value: config.styles.columnHorizontalAlign },
                { label: 't(Vertical Alignment)', name: 'styles.columnVerticalAlign', type: 'dropdown', options: [
                  { value: 'none', text: 't(None)' },
                  { value: 'top', text: 't(Top)' },
                  { value: 'middle', text: 't(Middle)' },
                  { value: 'bottom', text: 't(Bottom)' }
                ], defaultValue: 'none', value: config.styles.columnVerticalAlign }
              ]
            },
            styles.BackgroundSection(config, device, theme, website, type),
            styles.BoxShadowSection(config, device, theme, website, type),
            styles.BorderSection(config, device, theme, website, type),
            styles.SizingSection(config, device, theme, website, type, false, true),
            styles.SpacingSection(config, device, theme, website, type),
            styles.TransformSection(config, device, theme, website, type),
            styles.AnimationSection(config, device, theme, website, type),
            styles.FiltersSection(config, device, theme, website, type),
            styles.OverflowSection(config, device, theme, website, type),
            styles.VisibilitySection(config, device, theme, website, type)
          ]
        },
        {
          label: 't(Settings)',
          sections: settings(config, type, entity)
        }
      ]
    }
  }

  _getNormalized() {
    const { entity } = this.props
    const { config } = this.state
    return normalizeConfig({
      ...entity,
      meta: config.meta,
      settings: config.settings,
      styles: config.styles,
      content: config.content,
      template: config.template
    })
  }

  _handleCancel() {
    this.props.onDone(this.state.default)
  }

  _handleChange() {
    const config = this._getNormalized()
    this.props.onChange(config)
  }

  _handleDone() {
    const config = this._getNormalized()
    this.props.onDone(config)
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleUpdate(config) {
    this.setState({
      config: {
        ...this.state.config,
        ...config
      }
    })
  }

}

export default Collection
