import Container from '@admin/components/container'
import Stack from '@admin/components/stack'
import Configured from './configured'
import PropTypes from 'prop-types'
import Overview from './overview'
import React from 'react'

class Configure extends React.PureComponent {

  static contextTypes = {
    flash: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    setup: PropTypes.object,
    status: PropTypes.string
  }

  stackRef = React.createRef()

  state = {
    status: null,
    configuration: null
  }

  _handleComplete = this._handleComplete.bind(this)
  _handlePop = this._handlePop.bind(this)
  _handlePush = this._handlePush.bind(this)
  _handleSave = this._handleSave.bind(this)
  _handleTask = this._handleTask.bind(this)

  render() {
    if(this.state.configuration === null) return null
    return <Stack { ...this._getStack() } />
  }

  componentDidMount() {
    const { setup } = this.props
    const { configuration, status } = setup
    this.setState({ configuration, status })
  }

  componentDidUpdate(prevProps, prevState) {
    const { configuration, status } = this.state
    if(!_.isEqual(configuration, prevState.configuration) && prevState.configuration !== null) {
      this._handleSave()
    }
    if(status !== prevState.status && status === 'pending') {
      this._handleOverview()
    }
    if(status !== prevState.status && status === 'configured') {
      this._handleConfigured()
    }
  }

  getProps() {
    const { configuration } = this.state
    return {
      configuration,
      onTask: this._handleTask,
      onDone: this._handleComplete
    }
  }

  _getStack() {
    return {
      display_name: 'configure',
      ref: this.stackRef
    }
  }

  _handleComplete() {
    this.setState({
      status: 'configured'
    }, this._handleSave)
  }

  _handleConfigured() {
    this._handlePush(Configured, {})
  }

  _handleDone(task, configuration) {
    this.setState({
      configuration: {
        ...this.state.configuration,
        ...configuration,
        [task.status]: 'completed'
      }
    })
    this._handlePop()
  }

  _handlePop(index = -1) {
    this.stackRef.current.pop(index)
  }

  _handlePush(component, props) {
    this.stackRef.current.push({ component, props })
  }

  _handleOverview() {
    this._handlePush(Overview, this.getProps.bind(this))
  }

  _handleTask(task) {
    const { configuration } = this.state
    this._handlePush(task.modal, {
      configuration,
      onDone: this._handleDone.bind(this, task),
      onBack: this._handlePop
    })
  }

  _handleSave() {
    const { configuration, status } = this.state
    this.context.network.request({
      endpoint: '/api/admin/truevail/advisor/setup',
      method: 'PATCH',
      body: { configuration, status },
      onFailure: (e) => this.context.flash.set('error', 't(Unable to save configuration)')
    })
  } 

}

const mapResources = (props, context) => ({
  setup: '/api/admin/truevail/advisor/setup'
})

export default Container(mapResources)(Configure)
