import UserToken from '@admin/tokens/user'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Clone extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {
    deal: PropTypes.object,
    fields: PropTypes.array,
    pipeline: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    const { deal, pipeline } = this.props
    return {
      title: 't(Clone Deal)',
      method: 'POST',
      action: '/api/admin/sales/deals',
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { name: 'pipeline_id', type: 'hidden', value: pipeline.id },
            { label: 't(Title)', name: 'title', type: 'textfield', required: true, defaultValue: `Copy of ${deal.title}` },
            { label: 't(Description)', name: 'description', type: 'textfield', defaultValue: deal.description },
            { label: 't(Contact)', name: 'contact_id', type: 'lookup', endpoint: '/api/admin/crm/contacts', valueKey: 'id', textKey: 'display_name', required: true, defaultValue: deal.contact.id },
            { label: 't(Owner)', name: 'owner_id', type: 'lookup', endpoint: '/api/admin/users', valueKey: 'id', textKey: 'full_name', defaultValue: deal.owner?.id, format: UserToken },
            { label: 't(Stage)', name: 'stage_id', type: 'dropdown', endpoint: `/api/admin/sales/pipelines/${pipeline.id}/stages`, valueKey: 'id', textKey: 'title', required: true, defaultValue: deal.stage.id },
            { label: 'Expected Close Date', name: 'closes_on', type: 'datefield', required: true, defaultValue: deal.closes_on },
            { label: 'Pricing', type: 'segment', fields: [
              { name: 'pricing_type', type: 'radiogroup', options: [
                { value: 'fixed', text: 't(Fixed Price)' },
                { value: 'recurring', text: 't(Recurring Revenue)' }
              ], required: true, defaultValue: deal.pricing_type },
              ...deal.pricing_type === 'fixed' ? [
                { label: 't(Amount)', name: 'amount', type: 'moneyfield', required: true, defaultValue: deal.amount }
              ] : [
                { label: 'Annual Recurring Revenue', name: 'annual_recurring_revenue', type: 'moneyfield', required: true, defaultValue: deal.annual_recurring_revenue }
              ]
            ], required: true },
            ...this._getProperties()
          ]
        }
      ]
    }
  }

  _getProperties() {
    const { deal, fields } = this.props
    return fields.map(field => ({
      type: field.type,
      name: `values.${field.code}`,
      ...field.config,
      defaultValue: deal.values[field.code]
    }))
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Clone
