import EmailCampaignToken from '@apps/campaigns/admin/tokens/campaign/email'
import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import React from 'react'
import New from './new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Email Campaigns)',
  collection: {
    endpoint: `/api/admin/truevail/agency/advisors/${props.advisor.id}/email_campaigns`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Email Campaign)', key: 'advisor.title', sort: 'advisor', format: (publishing) => (
        <EmailCampaignToken email_campaign={ publishing.advisor_email_campaign } />
      ) },
      { label: 't(Preview)', key: 'preview_status', collapsing: true, aling: 'center', format: (publishing) => (
        <StatusToken status={ publishing.preview_status } />
      ) }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      icon: 'envelope',
      title: 't(No Email Campaigns)',
      text: 't(There are no vs for this advisor)'
    },
    entity: 'email campaign',
    recordTasks: (record) => [
      {
        label: 't(Send Preview)',
        request: {
          endpoint: `/api/admin/truevail/agency/publishings/${record.id}/preview`,
          method: 'POST',
          onFailure: () => context.flash.set('error', 't(Unable to send preview)'),
          onSuccess: () => context.flash.set('success', 't(Preview queued for delivery)')
        }
      }
    ],
    onClick: (record) => {
      const { provider, host } = context
      host.window.open(`${provider.admin_host}/mjson/dynamic/campaigns_email_campaigns/${record.code}`)
    }
  },
  task: {
    icon: 'plus',
    modal: <New advisor={ props.advisor } />
  }
})

export default Panel(null, mapPropsToPanel)
