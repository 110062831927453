import Logo from '@admin/components/logo'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const WorkflowToken = ({ workflow, compact }) =>(
  <div className="automation-workflow-token">
    { compact !== true &&
      <div className="automation-workflow-token-icon">
        <Logo team={ workflow.program } width="24" />
      </div>
    }
    <div className="automation-workflow-token-label">
      { workflow.title }
      <div className="automation-workflow-token-link">
        <T text={ workflow.trigger.text } />
        { workflow.email &&
          <> <span className="automation-workflow-token-target">{ workflow.email.title }</span></>
        }
        { workflow.email_campaign &&
          <> <span className="automation-workflow-token-target">{ workflow.email_campaign.title }</span></>
        }
        { workflow.event &&
          <> <span className="automation-workflow-token-target">{ workflow.event.title }</span></>
        }
        { workflow.form &&
          <> <span className="automation-workflow-token-target">{ workflow.form.title }</span></>
        }
        { workflow.list &&
          <> <span className="automation-workflow-token-target">{ workflow.list.title }</span></>
        }
        { workflow.store &&
          <> <span className="automation-workflow-token-target">{ workflow.store.title }</span></>
        }
        { workflow.pipeline &&
          <> <span className="automation-workflow-token-target">{ workflow.pipeline.title }</span></>
        }
        { workflow.service &&
          <> <span className="automation-workflow-token-target">{ workflow.service.title }</span></>
        }
        { workflow.tag &&
          <> <span className="automation-workflow-token-target">{ workflow.tag.text }</span></>
        }
        { workflow.trigger.code === 'crm:property_updated' && workflow.field &&
          <>: <span className="automation-workflow-token-target">{ workflow.field.name.value }</span></>
        }
      </div>
      <div className="token-stats">
        <span className={ workflow.status }>{ workflow.status }</span>
        <span>{ workflow.enrolled_count || 0 } enrolled</span>
        <span>{ workflow.active_count || 0 } active</span>
        <span>{ workflow.lost_count || 0 } lost</span>
        <span>{ workflow.converted_count || 0 } converted</span>
        <span>{ workflow.completed_count || 0 } completed</span>
      </div>
    </div>
  </div>
)

WorkflowToken.propTypes = {
  compact: PropTypes.bool,
  workflow: PropTypes.object
}

export default WorkflowToken
