import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const service_types = {
  url: {
    title: 't(I Already Have a Website)',
    text: 't(Let us know your url, so we can link to it from your content)',
    charges: false
  },
  design: {
    title: 't(Design a New Website for Me)',
    text: 't(Hire Truevail to design a new website for you. Our support team will reach out to you with additional details and purchase options)',
    charges: 'starting at $299 one-time fee'
  },
  optout: {
    title: 't(I Dont Need a Website)',
    text: 't(If you dont want have or neeed a website, we can skip this step. Select this option if you don\'t need a website)',
    charges: false
  }
}

const WebsiteToken = ({ value }) => (
  <div className="token">
    <strong><T text={ service_types[value].title } /></strong> { service_types[value].charges &&
      <>
        (<span className="alert">{ service_types[value].charges }</span>)
      </>
    }<br />
    <T text={ service_types[value].text } />
    
  </div>
)

WebsiteToken.propTypes = {
  value: PropTypes.string
}

export default WebsiteToken
