import elementResizeEvent from 'element-resize-event'
import Format from '@admin/components/format'
import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import Columns from './columns'
import React from 'react'

class Grid extends React.Component {

  static propTypes = {
    context: PropTypes.object,
    code: PropTypes.string,
    columns: PropTypes.array,
    defaults: PropTypes.array,
    display: PropTypes.array,
    handler: PropTypes.func,
    hidden: PropTypes.array,
    records: PropTypes.array,
    recordTasks: PropTypes.func,
    rowClass: PropTypes.func,
    selectable: PropTypes.bool,
    selected: PropTypes.object,
    selectAll: PropTypes.bool,
    selectValue: PropTypes.string,
    sort: PropTypes.object,
    sortable: PropTypes.bool,
    total: PropTypes.number,
    visible: PropTypes.array,
    onClick: PropTypes.func,
    onReachBottom: PropTypes.func,
    onSelect: PropTypes.func,
    onSetHidden: PropTypes.func,
    onSelectAll: PropTypes.func,
    onSort: PropTypes.func,
    onToggleHidden: PropTypes.func
  }

  bodyRef = React.createRef(null)
  headerRef = React.createRef(null)
  notifiedRef = React.createRef(false)
  panelRef = React.createRef(null)
  windowRef = React.createRef(null)

  state = {
    averageHeight: 42,
    bodyHeight: [],
    bodyDimensions: [],
    firstIndex: 0,
    headerDimensions: [],
    held: null,
    panelHeight: 0,
    rows: 30,
    timer: null,
    windowHeight: 0
  }

  _handleInit = this._handleInit.bind(this)
  _handleResize = _.throttle(this._handleResize.bind(this), 250)
  _handleScroll = _.throttle(this._handleScroll.bind(this), 100)
  _handleSelectAll = this._handleSelectAll.bind(this)
  _handleTouchStart = this._handleTouchStart.bind(this)
  _handleTouchEnd = this._handleTouchEnd.bind(this)

  render() {
    const { hidden, records, recordTasks, selectable, selectAll, visible, onClick } = this.props
    if(!hidden) return null
    return (
      <div className={ this._getTableClass() }>
        <div className="maha-grid-header">
          <table ref={ this.headerRef }>
            <tbody>
              <tr>
                { selectable &&
                  <td { ...this._getSelectAll() }>
                    <Icon icon={ selectAll ? 'check-circle' : 'circle-o'  } /> 
                  </td>
                }
                { visible.map((column, cindex) => (
                  <td key={`header_${column.key}`} { ...this._getHeader(column, cindex) }>
                    <T text={ column.label } />
                    { this._getSort(column) }
                  </td>
                ))}
                { recordTasks && onClick &&
                  <td className="mobile config" />
                }
                <Columns { ...this._getColumns() } />
              </tr>
            </tbody>
          </table>
        </div>
        <div ref={ this.windowRef } { ...this._getWindow() }>
          <div ref={ this.panelRef }>
            <table ref={ this.bodyRef }>
              <tbody>
                { records.map((record, rindex) => (
                  <tr { ...this._getRow(record, rindex) } key={`row_${record.id || rindex}`}>
                    { selectable &&
                      <td key={`row_${rindex}_select`} { ...this._getSelect(record) }>
                        <Icon icon={  this._getChecked(record) } />
                      </td>
                    }
                    { visible.map((column, cindex) => (
                      <td key={`row_${record.id || rindex}_column_${column.key}`} { ...this._getCell(column, rindex, cindex) }>
                        { record ? <Format { ...this._getFormat(record, column) } /> : null }
                      </td>
                    ))}
                    { recordTasks &&
                      <td className="maha-grid-task mobile icon centered" onClick={ this._handleTasks.bind(this, record) }>
                        <div className="maha-grid-icon">
                          <Icon icon="ellipsis-h" />
                        </div>
                      </td>
                    }
                    { onClick &&
                      <td className="padded icon mobile centered">
                        { onClick && <Icon icon="chevron-right" /> }
                      </td>
                    }
                    { (!recordTasks && !onClick) &&
                      <td className="mobile config"/>
                    }
                  </tr>
                )) }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }

  componentDidMount() {
    this._handleLoadHidden()
  }

  componentDidUpdate(prevProps) {
    const { hidden, records, visible } = this.props
    if(hidden !== prevProps.hidden &&  prevProps.hidden == null) {
      this._handleInit()
    }
    if(!_.isEqual(hidden, prevProps.hidden)) {
      this._handleSaveHidden()
    }
    if(!_.isEqual(visible, prevProps.visible)) {
      this._handleResize()
    }
    if(!_.isEqual(records, prevProps.records)) {
      this._handleResize()
      this.notifiedRef.current = false
    }
  }

  componentWillUnmount() {
    this._handleResize.cancel()
    this._handleScroll.cancel()
    if(this.bodyRef?.current) elementResizeEvent.unbind(this.bodyRef.current)
    if(this.state.timer) clearTimeout(this.state.timer)
  }

  _getCell(column, rindex, cindex) {
    return {
      className: this._getCellClass(column),
      style: this._getCellStyle(rindex, cindex)
    }
  }

  _getCellClass(column) {
    const classes = ['maha-grid-cell']
    if(column.primary === true) classes.push('mobile')
    if(_.includes(['check','check_times'], column.format) || column.centered === true) classes.push('center')
    if(column.collapsing) classes.push('collapsing')
    if(_.includes(['date','datetime'], column.format)) classes.push('datetime')
    if(_.includes(['currency','percent','rate'], column.format)) classes.push('right')
    if(column.align) classes.push(column.align)
    if(column.padded || (!_.isFunction(column.format) && !_.isElement(column.format))) classes.push('padded')
    return classes.join(' ')
  }

  _getCellStyle(rindex, cindex) {
    const { headerDimensions } = this.state
    const { selectable } = this.props
    if(!headerDimensions || rindex > 0) return {}
    const index = cindex + (selectable ?  1 : 0)
    return {
      minWidth: headerDimensions[index] ? headerDimensions[index].w : null
    }
  }

  _getChecked(record) {
    const { selected, selectValue } = this.props
    const value = _.get(record, selectValue)
    const included = _.includes(selected.values, value)
    if(selected.mode === '$in') return included ? 'check-circle' : 'circle-o'
    return included ? 'circle-o' : 'check-circle'
  }

  _getColumns() {
    const { display, onToggleHidden } = this.props
    return {
      columns: display,
      onToggleHidden
    }
  }

  _getFormat(record, column) {
    return {
      value: this._getValue(record, column.key),
      ...record,
      format: column.format
    }
  }

  _getHeader(column, cindex) {
    return {
      className: this._getHeaderClass(column),
      style: this._getHeaderStyle(cindex),
      onClick: this._handleSort.bind(this, column),
      ...column.tooltip ? {
        'data-position': 'bottom center',
        'data-inverted': true,
        'data-tooltip': column.tooltip
      } : {}
    }
  }

  _getHeaderClass(column) {
    let classes = ['maha-grid-cell','padded']
    if(column.primary === true) classes.push('mobile')
    if(_.includes(['date','datetime'], column.format)) classes.push('collapsing datetime')
    if(_.includes(['check','check_times'], column.format)) classes.push('collapsing')
    if(column.collapsing === true) classes.push('collapsing')
    return classes.join(' ')
  }

  _getHeaderStyle(cindex) {
    const { bodyDimensions } = this.state
    const { selectable } = this.props
    const index = cindex + (selectable ?  1 : 0)
    return {
      width: bodyDimensions?.[index]?.w || null
    }
  }

  _getRow(record, rindex) {
    return {
      className: this._getRowClass(record),
      onClick: this._handleClick.bind(this, record, rindex),
      onTouchStart: this._handleTouchStart.bind(this, record),
      onTouchEnd: this._handleTouchEnd.bind(this)
    }
  }

  _getRowClass(record) {
    const { rowClass, handler, onClick  } = this.props
    const { held } = this.state
    let classes = []
    if(record.id === held) classes.push('held')
    if(handler || onClick) classes.push('maha-grid-link')
    if(rowClass && _.isString(rowClass)) classes.push(rowClass)
    if(rowClass && _.isFunction(rowClass)) classes.push(rowClass(record))
    return classes.join(' ')
  }

  _getSelect(record) {
    return {
      className: 'maha-grid-check-cell mobile',
      onClick: this._handleSelect.bind(this, record)
    }
  }

  _getSelectAll() {
    return {
      className: 'maha-grid-check-cell mobile',
      style: this._getHeaderStyle(-1),
      onClick: this._handleSelectAll
    }
  }

  _getSort(column) {
    const { sort } = this.props
    if(!sort || (column.key !== sort.key && column.sort !== sort.key)) return null
    return <Icon icon={sort.order === 'asc' ? 'caret-up' : 'caret-down' } />
  }

  _getTableClass() {
    const { sortable, status } = this.props
    const classes = ['maha-grid']
    if(status === 'loading') classes.push('loading')
    if(sortable) classes.push('sortable')
    return classes.join(' ')
  }

  _getValue(record, key) {
    if(_.isFunction(key)) return key(record)
    if(_.isString(key)) return _.get(record, key)
    return ''
  }

  _getWindow() {
    return {
      className:'maha-grid-body',
      onScroll: this._handleScroll
    }
  }

  _handleClick(record, index) {
    const { onClick, handler } = this.props
    if(handler) handler(record, index)
    if(onClick) onClick(record, index)
  }

  _handleInit() {
    if(!this.windowRef.current) return setTimeout(this._handleInit, 250)
    const windowHeight = window.getComputedStyle(this.windowRef.current).height
    this.setState({
      windowHeight: parseInt(windowHeight.replace('px', ''))
    })
    elementResizeEvent(this.bodyRef.current, this._handleResize)
    this._handleResize()
  }

  _handleLoadHidden() {
    const { code, defaults } = this.props
    this.props.context.local_storage.get({
      key: `table-${code}`,
      onSuccess: (value) => {
        const hidden = value || defaults
        this.props.onSetHidden(hidden)
      },
      onFailure: () => {}
    })
  }

  _handleMeasure(element) {
    if(!element) return null
    const cells = element.childNodes[0].childNodes[0].childNodes
    return Array.prototype.slice.call(cells).map(cell => ({
      w: cell.offsetWidth,
      h: cell.offsetHeight
    }))
  }

  _handleResize() {
    this.setState({
      headerDimensions: [],
      bodyDimensions: []
    }, () => {
      this.setState({
        headerDimensions: this._handleMeasure(this.headerRef.current)
      }, () => {
        this.setState({
          bodyDimensions: this._handleMeasure(this.bodyRef.current)
        })
      })
    })
  }

  _handleSaveHidden() {
    const { code, hidden } = this.props
    this.props.context.local_storage.set({
      key: `table-${code}`,
      value: hidden,
      onSuccess: () => {},
      onFailure: () => {}
    })
  }

  _handleScroll() {
    const panelHeight = parseInt(window.getComputedStyle(this.panelRef.current).height.replace('px', ''))
    const { windowHeight } = this.state
    const { scrollTop } = this.windowRef.current
    const percentScrolled = (scrollTop / (panelHeight - windowHeight)) * 100
    if(!this.notifiedRef.current && percentScrolled > 60) {
      this.props.onReachBottom()
      this.notifiedRef.current = true
    }
    this._handleResize()
  }

  _handleSelect(record, e) {
    e.stopPropagation()
    const { selectValue } = this.props
    const value = _.get(record, selectValue)
    this.props.onSelect(value)
  }

  _handleSelectAll() {
    this.props.onSelectAll()
  }

  _handleSort(column) {
    const { sortable } = this.props
    if(!sortable) return
    const key = column.sort || column.key
    this.windowRef.current.style.scrollBehavior = 'auto'
    this.windowRef.current.scrollTop = 0
    this.windowRef.current.style.scrollBehavior = 'smooth'
    this.props.onSort(key)
  }

  _handleTasks(record, e) {
    e.stopPropagation()
    const { recordTasks } = this.props
    this.props.context.tasks.open({
      items: recordTasks(record)
    })
  }

  _handleTouchStart(record, e) {
    const { handler, recordTasks, onClick } = this.props
    if(!handler && !recordTasks && !onClick) return
    this.setState({
      held: record.id,
      timer: setTimeout(() => {
        this._handleTouchEnd()
        if(!recordTasks) return
        this._handleTasks(record, e)
      }, 500)
    })
  }

  _handleTouchEnd() {
    const { timer } = this.state
    clearTimeout(timer)
    this.setState({
      held: null,
      timer: null
    })
  }

}

export default Grid
