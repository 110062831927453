import AgencyAdvisorSubscription from './agency/advisors/show/subscriptions/show'
import AgencySocialBatchPublishing from './agency/social_batches/publishing'
import AgencySocialPublishing from './agency/social_publishings/show'
import AdvisorSubscription from './advisor/show/subscriptions/show'
import AgencySocialCampaign from './agency/social_campaigns/show'
import AgencyEmailCampaign from './agency/email_campaigns/show'
import AgencyEmailTemplate from './agency/email_templates/show'
import AdminEmailCampaign from './admin/email_campaigns/show'
import AgencySocialBatch from './agency/social_batches/show'
import AdminSenderDomain from './admin/sender_domains/show'
import AdminSocial from './admin/social_campaigns/show'
import AdminSignupPage from './admin/signup_pages/show'
import AgencyAdvisor from './agency/advisors/show'
import AgencyContent from './agency/content/show'
import AdminAdvisor from './admin/advisors/show'
import AdminContent from './admin/content/show'
import AdminAgency from './admin/agencies/show'
import AdminNotice from './admin/notices/show'
import Advisor from './advisor/show'
import Agency from './agency'
import Admin from './admin'

const routes = [
  { path: '/admin', component: Admin },
  { path: '/admin/:view', component: Admin },
  { path: '/admin/agencies/:id/:view', component: AdminAgency },
  { path: '/admin/content/:id/:view', component: AdminContent },
  { path: '/admin/email_campaigns/:id/:view', component: AdminEmailCampaign },
  { path: '/admin/notices/:id/:view', component: AdminNotice },
  { path: '/admin/sender_domains/:id/:view', component: AdminSenderDomain },
  { path: '/admin/signup_pages/:id/:view', component: AdminSignupPage },
  { path: '/admin/advisors/:id/:view', component: AdminAdvisor },
  { path: '/admin/social_campaigns/:id/:view', component: AdminSocial },
  { path: '/advisor', component: Advisor },
  { path: '/advisor/subscriptions/:id/:view', component: AdvisorSubscription },
  { path: '/advisor/:view', component: Advisor },
  { path: '/agency', component: Agency },
  { path: '/agency/:view', component: Agency },
  { path: '/agency/advisors/:advisor_id/subscriptions/:id/:view', component: AgencyAdvisorSubscription },
  { path: '/agency/advisors/:id/:view', component: AgencyAdvisor },
  { path: '/agency/content/:id/:view', component: AgencyContent },
  { path: '/agency/email_campaigns/:id/:view', component: AgencyEmailCampaign },
  { path: '/agency/email_templates/:id/:view', component: AgencyEmailTemplate },
  { path: '/agency/social_batches/:social_batch_id/publishings/:id/:view', component: AgencySocialBatchPublishing },
  { path: '/agency/social_publishings/:id/:view', component: AgencySocialPublishing },
  { path: '/agency/social_batches/:id/:view', component: AgencySocialBatch },
  { path: '/agency/social_campaigns/:id/:view', component: AgencySocialCampaign }
]

export default routes
