import { embedTemplate } from '@core/utils/sdk'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import Details from '@admin/components/details'
import Code from '@admin/components/code'
import PropTypes from 'prop-types'
import Edit from '../edit'
import React from 'react'

const Overview = ({ embed, form }, { provider }) => {

  const form_button = {
    label: form.title,
    className: 'link',
    route: `/admin/forms/forms/${form.id}`
  }

  const embedCode = embedTemplate({
    preamble: `<div data-form="${embed.code}"></div>`,
    asset_root: provider.asset_host,
    actions: [{module: 'forms.embed', method: 'init', args: [JSON.stringify(embed.code)]}]
  })

  const details = {
    audits: `forms_embeds/${embed.id}`,
    comments: `forms_embeds/${embed.id}`,
    sections: [
      {
        title: 't(Embed Details)',
        items: [
          { label: 't(Title)', content: embed.title },
          { label: 't(Form)', content: <Button { ...form_button } />  }
        ]
      },
      {
        title: 't(Embed Code)',
        instructions: 't(Paste the code below into your website in order to embed this form on the page)',
        items: [
          { padded: true, component: <Code code={ embedCode } /> }
        ]
      }
    ]
  }

  if(embed.deleted_at !== null) {
    details.alert = { color: 'red', message: 't(This embed was deleted)' }
  }

  return <Details { ...details } />

}

Overview.contextTypes = {
  provider: PropTypes.object
}

Overview.propTypes = {
  embed: PropTypes.object,
  form: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview form={ props.form } embed={ props.embed } />,
  tasks: {
    items: [
      { 
        label: 't(Edit Embed)', 
        access: { rights: { $oneOf: ['forms:manage_forms'] } },
        modal: <Edit form={ props.form } embed={ props.embed } /> 
      },
      {
        label: 't(Delete Embed)',
        access: { rights: { $oneOf: ['forms:manage_forms'] } },
        confirm: 't(Are you sure you want to delete this embed?)',
        request: {
          endpoint: `/api/admin/forms/forms/${props.form.id}/embeds/${props.embed.id}`,
          method: 'DELETE',
          onSuccess: () => {
            context.router.goBack()
            context.flash.set('success', 't(Successfully deleted this embed)')
          },
          onFailure: () => context.flash.set('error', 't(Unable to delete this embed)')
        }
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
