import ProfileSetupField from '@apps/truevail/admin/components/profilesetupfield'
import TimeFrameField from '@apps/truevail/admin/components/timeframefield'
import ChannelToken from '@apps/truevail/admin/tokens/channel'
import Button from '@admin/components/button'
import SchedulingToken from './scheduling'
import Form from '@admin/components/form'
import T from '@admin/components/t'
import StrategyToken from './strategy'
import PropTypes from 'prop-types'
import React from 'react'

class Social extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {
    configuration: PropTypes.object,
    onBack: PropTypes.func,
    onDone: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: null
  }

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    if(!this.state.config) return null
    return <Form { ...this._getForm() } />
  }

  componentDidMount() {
    const { configuration } = this.props
    this.setState({
      config: configuration
    })
  }

  _getForm() {
    const { programs } = this.context.admin
    const { config } = this.state
    return {
      ref: this.formRef,
      title: 't(Social Media)',
      cancelIcon: 'chevron-left',
      saveText: null,
      buttons: [
        { label: 't(Done)', color: 'blue', handler: this._handleSubmit }
      ],
      onCancel: this._handleBack,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Service)', instructions: 't(Opt-in or Opt-out of the done-for-you social media campaign service)', type: 'segment', fields: [
              { name: 'social_strategy', type: 'radiogroup', options: ['optin','optout'], defaultValue: config.social_strategy || 'optin', format: StrategyToken, required: true },
              { label: 't(Social Profiles)', instructions: (
                <>
                  <p><T text="t(Add the Facebook Page(s) and/or Instagram Business Profile(s) you would like for us to post your content to)" /></p>
                  <p><T text="t(We cannot schedule posts to Personal Facebook or Personal Instagram accounts. You must have a Facebook Page and/or Instagram Business profile for us to schedule your posts. Additionally, the accounts must be linked for us to post to your Instagram Business Profile)" /></p>
                  <ul>
                    <li><Button text="t(How to create a Facebook Page)" className="link" link="https://www.facebook.com/business/help/473994396650734?id=939256796236247" /></li>
                    <li><Button text="t(How to create an Instagram Business Profile)" className="link" link="https://help.instagram.com/502981923235522" /></li>
                    <li><Button text="t(How to link your Instagram Business Profile to your Facebook Page)" className="link" link="https://help.instagram.com/176235449218188" /></li>
                  </ul>
                </>
              ), name: 'social_profiles', type: ProfileSetupField, program: programs[0], defaultValue: config.social_profiles, required:  config.social_strategy === 'optin' },
              { label: 't(Channels)', instructions: 't(Choose which social channels you\'d like to subscribe to)', name: 'social_channel_ids', type: 'checkboxgroup', entity: 'channel', endpoint: '/api/admin/truevail/advisor/channels', valueKey: 'id', textKey: 'title', filter: { service: { $eq: 'social' }, type: { $eq: 'public' } }, deselectable: true, format: (channel) => (
                <ChannelToken channel={ channel } />
              ), defaultValue: config.social_channel_ids },
              { label: 't(Scheduling)', instructions: 't(Choose preferred method of your social campaign scheduling. You will always receive a preview of your monthly content approx. one week before the beginning of the calendar month)', name: 'social_scheduling', type: 'radiogroup', show: config.social_strategy === 'optin', options: ['optin','optout'], defaultValue: config.social_scheduling || 'optin', format: SchedulingToken, required: true },
              { label: 't(Timeframe)', instructions: 't(Specify any timeframes for us to schedule your posts. Post will be randomly scheduled during the times set)', name: 'social_timeframe', type: TimeFrameField, show: config.social_strategy === 'optin' && config.social_scheduling === 'optin', defaultValue: config.social_timeframe, required: true }
            ] }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(config) {
    this.props.onDone(config)
  }

}

export default Social
