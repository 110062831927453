import PropTypes from 'prop-types'
import React from 'react'

const VoiceToken = ({ perspective, language }) => (
  <div className="voice-token">
    { perspective }, { language }
  </div>
)

VoiceToken.propTypes = {
  perspective: PropTypes.string,
  language: PropTypes.string
}

export default VoiceToken
