import { canAccess } from '@core/utils/access'
import Button from '@admin/components/button'
import Avatar from '@admin/components/avatar'
import Badge from '@admin/components/badge'
import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Ribbon extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    maha: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    active: PropTypes.number,
    onChoose: PropTypes.func,
    onHelp: PropTypes.func,
    onSetMode: PropTypes.func
  }

  render() {
    const { user } = this.context.admin
    const badges = this._getBadges()
    return (
      <div className="maha-ribbon">
        <Button { ...this._getMenu() }>
          <div className="maha-badge">
            <Icon icon="th" />
          </div>
          <span><T text="t(Apps)" /></span>
        </Button>
        <div className="maha-ribbon-spacer" />
        { badges.map((badge, index) => (
          <Button { ...this._getBadge(badge) } key={`badge_${index}`}>
            { badge.component ? <badge.component /> : <Badge { ...badge } /> }
            <span><T text={ badge.title } /></span>
          </Button>
        ))}
        <Button { ...this._getAccount() }>
          <div className="maha-badge">
            <Avatar user={ user } width={ 28 } presence={ false } />
          </div>
          <span><T text="t(Account)" /></span>
        </Button>
      </div>
    )
  }

  _getAccess(access) {
    const { admin } = this.context
    return canAccess(access, admin)
  }

  _getFeatures(required) {
    const { features } = this.context.admin
    if(!required) return true
    return required.reduce((found, key) => {
      if(!found) return false
      return features[key] === true
    }, true)
  }

  _getAccount() {
    return {
      className: 'maha-ribbon-item',
      event: 'clicked \'Account\' badge in ribbon',
      handler:  this._handleMode.bind(this, 'account'),
      tooltip: {
        title: 't(Account)',
        position:'right center'
      }
    }
  }

  _getBadge(badge) {
    return {
      className: this._getClass(badge),
      event: `clicked '${badge.tooltip}' badge in ribbon`,
      handler: this._handleChoose.bind(this, badge, badge.index),
      ...badge.tooltip ? {
        tooltip: {
          title: badge.tooltip,
          position:'right center'
        }
      } : {}
    }
  }

  _getBadges() {
    const { badges } = this.context.maha
    return badges.slice().map((badge, index) => ({
      ...badge,
      index
    })).filter((badge) => {
      return badge.app === 'maha' || (this._getAccess(badge.access) && this._getFeatures(badge.features))
    }).sort((a,b) => {
      return (a.weight || 0) > (b.weight || 0) ? 1 : -1
    })
  }

  _getClass(badge) {
    const visible = badge.visible || ['desktop','tablet','mobile']
    const { active } = this.props
    const classes = ['maha-ribbon-item']
    if(badge.index === active) classes.push('active')
    if(!_.includes(visible, 'mobile')) classes.push('hide-mobile')
    if(!_.includes(visible, 'tablet')) classes.push('hide-tablet')
    if(!_.includes(visible, 'desktop')) classes.push('hide-desktop')
    return classes.join(' ')
  }

  _getMenu() {
    return {
      event: 'opened navigation',
      className: 'maha-ribbon-item',
      handler:  this._handleMode.bind(this, 'navigation')
    }
  }

  _handleChoose(item, index) {
    if(item.sidebar) return this.props.onChoose(index)
    if(item.route) this.context.router.push(item.route)
  }

  _handleMode(mode) {
    this.props.onSetMode(mode)
  }

}

export default Ribbon
