import SubscriptionProfilesToken from '@apps/truevail/admin/tokens/subscription_profiles'
import TimeframeToken from '@apps/truevail/admin/tokens/timeframe'
import AssetToken from '@admin/tokens/asset'
import Panel from '@admin/components/panel'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import Edit from './edit'
import React from 'react'

const never = <span className="disabled">NEVER</span>

const Configuration = ({ advisor, channels, configuration }) => {

  const details = {
    sections: [
      {
        title: 't(Configuration Details)',
        items: [
          { label: 't(Last Reminded At)', content: configuration.last_reminded_at, format: 'datetime', empty: never },
          { label: 't(Reminders Sent)', content: configuration.reminders_count || 0 },
          { label: 't(Configured At)', content: configuration.configured_at, format: 'datetime', empty: never }
        ]
      },
      {
        subtitle: 't(Business Information)',
        items: [
          { label: 't(Business Name)', content: configuration.business_name },
          { label: 't(Agency / Host Agency)', content: configuration.host_agency },
          { label: 't(Consortia)', content: configuration.consortia },
          { label: 't(Business Address)', content: configuration.business_address ? configuration.business_address.description : null },
          { label: 't(Business Email)', content: configuration.business_email },
          { label: 't(Business Phone)', content: configuration.business_phone },
          { label: 'Facebook', content: configuration.facebook, format: 'link' },
          { label: 'Instagram', content: configuration.instagram, format: 'link' },
          { label: 'LinkedIn', content: configuration.linkedin, format: 'link' },
          { label: 'YouTube', content: configuration.youtube, format: 'link' },
          { label: 'Pinterest', content: configuration.pinterest, format: 'link' },
          { label: 'TikTok', content: configuration.tiktok, format: 'link' },
          { label: 'Threads', content: configuration.threads, format: 'link' },
          { label: 'Vimeo', content: configuration.vimeo, format: 'link' },
          { label: 'X', content: configuration.x, format: 'link' }
        ]
      },
      {
        subtitle: 't(Branding)',
        items: [
          { label: 't(Logo Strategy)', content: configuration.logo_strategy },
          { label: 't(Logo)', content: configuration.logo_id ? <AssetToken { ...configuration.logo } preview={ true } /> : null },
          { label: 't(Color Strategy)', content: configuration.color_strategy },
          { label: 't(Color 1)', content: configuration.color1 },
          { label: 't(Color 2)', content: configuration.color2 },
          { label: 't(Color 3)', content: configuration.color3 },
          { label: 't(Brand Book / Style Guide)', content: configuration.style_guide_id ? <AssetToken { ...configuration.style_guide } /> : null },
        ]
      },
      {
        subtitle: 't(Personalization)',
        items: [
          { label: 't(Photo Strategy)', content: configuration.photo_strategy },
          { label: 't(Photo)', content: configuration.photo_id ? <AssetToken { ...configuration.photo } preview={ true } /> : null },
          { label: 't(Public Domain)', content: configuration.public_domain },
          { label: 't(Voice)', content: configuration.voice },
          { label: 't(Timezone)', content: configuration.timezone }
        ]
      },
      {
        subtitle: 't(Email Newsletters)',
        items: [
          { label: 't(Email Strategy)', content: configuration.email_strategy },
          { label: 't(Channels)', content: configuration.email_channel_ids ? configuration.email_channel_ids.map((id, index) => {
            const channel = channels.find(channel => channel.id === id)            
            return channel ? <div className="crm-tag-token" key={`channel_${index}`}>{ channel.title }</div> : null            
          }) : null },
          { label: 't(Scheduling)', content: configuration.email_scheduling },
          { label: 't(From Name)', content: configuration.from_name },
          { label: 't(From Email)', content: configuration.from_email },
          { label: 't(Signature Strategy)', content: configuration.signature_strategy },
          { label: 't(Signature)', content: configuration.signature_id ? <AssetToken { ...configuration.signature } /> : null },
          { label: 't(Contacts)', content: configuration.contacts_id ? <AssetToken { ...configuration.contacts } /> : null }
        ]
      },
      {
        subtitle: 't(Social Media)',
        items: [
          { label: 't(Social Media Strategy)', content: configuration.social_strategy },
          { label: 't(Channels)', content: configuration.social_channel_ids ? configuration.social_channel_ids.map((id, index) => {
            const channel = channels.find(channel => channel.id === id)            
            return channel ? <div className="crm-tag-token" key={`channel_${index}`}>{ channel.title }</div> : null            
          }) : null },
          { label: 't(Scheduling)', content: configuration.social_scheduling },
          { label: 't(Timeframe)', content: configuration.social_timeframe ? <TimeframeToken value={ configuration.social_timeframe } /> : null },
          { label: 't(Profiles)', content: configuration.social_profiles ? <SubscriptionProfilesToken subscription_profiles={ configuration.social_profiles } /> : null }
        ]
      },
      {
        subtitle: 't(Website)',
        items: [
          { label: 't(Website Strategy)', content: configuration.website_strategy },
          { label: 't(Website)', content: configuration.website, format: 'link' }
        ]
      },
      {
        subtitle: 't(Additional Information)',
        items: [
          { label: 't(Notes)', content: configuration.notes }
        ]
      }
    ]
  }

  return <Details { ...details } />

}

Configuration.propTypes = {
  advisor: PropTypes.object,
  channels: PropTypes.array,
  configuration: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Configuration)',
  panel: <Configuration advisor={ props.advisor } channels={ props.channels } configuration={ props.configuration} />,
  tasks: {
    items: [
      {
        label: 't(Edit Configuration)',
        modal: <Edit advisor={ props.advisor } />
      }
    ]
  }
})

const mapResourcesToPanel = (props, context) => ({
  channels: '/api/admin/truevail/agency/channels',
  configuration: `/api/admin/truevail/agency/advisors/${props.advisor.id}/configuration`
})

export default Panel(mapResourcesToPanel, mapPropsToPanel)
