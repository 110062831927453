import MJSONScreenshot from '@admin/components/mjson_screenshot'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

class UnscheduledToken extends React.PureComponent {

  static contextTypes = {
    provider: PropTypes.object
  }

  static propTypes = {
    social_campaign: PropTypes.object,
    onEdit: PropTypes.func
  }

  _handleEdit = this._handleEdit.bind(this)
  _handleDragStart = this._handleDragStart.bind(this)

  render() {
    const { social_campaign } = this.props
    return (
      <div { ...this._getToken() }>
        <MJSONScreenshot { ...this._getScreenshot(social_campaign.variants[0]) } />
      </div>
    )
  }

  _getToken() {
    const { social_campaign } = this.props
    return {
      className: 'campaigns-social-schedule-token',
      draggable: true,
      title: social_campaign.title,
      onClick: this._handleEdit,
      onDragStart: this._handleDragStart
    }
  }

  _getScreenshot(entity) {
    return {
      engine: 'mjson',
      entity: `campaigns_social_campaign_variants/${entity.code}`,
      width: 100,
      height: 75,
      screenshoted_at: entity.screenshoted_at,
      updated_at: entity.updated_at
    }
  }

  _handleDragStart(e) {
    const { social_campaign } = this.props
    const { provider } = this.context
    e.stopPropagation()
    e.dataTransfer.dropEffect = 'all'
    e.dataTransfer.setData('social_campaign', JSON.stringify(social_campaign))
    const variant = social_campaign.variants[0]
    const dragImage = document.createElement('img')
    const timestamp = moment(variant.screenshoted_at || variant.updated_at).format('x')
    dragImage.src = `${provider.cdn_host}/mjson/dynamic/${timestamp}/campaigns_social_campaign_variants/${variant.code}/screenshot.jpg`
    dragImage.width = 40
    dragImage.height = 30
    document.body.appendChild(dragImage)
    e.dataTransfer.setDragImage(dragImage, 20, 20)

  }

  _handleEdit() {
    const { social_campaign } = this.props
    this.props.onEdit(social_campaign)
  }

}

export default UnscheduledToken
