import PaletteField from '@admin/components/mjson_designer/components/palettefield'
import Configuration from '../configuration'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    advisor: PropTypes.object,
    configuration: PropTypes.object,
    onBack: PropTypes.func,
    onDone: PropTypes.func
  }

  _handleBack = this._handleBack.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { advisor, configuration } = this.props
    return {
      title: 't(New Template)',
      method: 'POST',
      action: `/api/admin/truevail/agency/advisors/${advisor.id}/templates`,
      cancelIcon: 'chevron-left',
      onCancel: this._handleBack,
      onSuccess: this._handleSuccess,
      before: <Configuration configuration={ configuration } fields={['email_strategy','color1','color2','color3','color3','style_guide_id','logo_id','photo_id','business_address','business_email','business_phone']}  />,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', defaultValue: 'Advisor Template', required: true },
            { label: 't(Palette)', name: 'palette', type: PaletteField, required: true },
            { label: 't(Logo)', name: 'logo_id', type: 'attachmentfield', allow: { content_types: ['images'] }, multiple: false, defaultValue: configuration.logo_id, required: true },
            { label: 't(Photo)', name: 'photo_id', type: 'attachmentfield', allow: { content_types: ['images'] }, multiple: false, defaultValue: configuration.photo_id, required: true },
            { label: 't(Address)', name: 'address', type: 'addressfield', defaultValue: configuration.business_address, required: true },
            { label: 't(Email)', name: 'email', type: 'emailfield', defaultValue: configuration.business_email, required: true },
            { label: 't(Phone)', name: 'phone', type: 'phonefield', defaultValue: configuration.business_phone, required: true },
            { label: 't(Website)', name: 'website', type: 'urlfield', defaultValue: configuration.website, required: true },
            { label: 't(Social)',name: 'facebook', type: 'urlfield', service: 'facebook', placeholder: 't(Enter Facebook URL)', defaultValue: configuration.facebook },
            { name: 'instagram', type: 'urlfield', service: 'instagram', placeholder: 't(Enter Instagram URL)', defaultValue: configuration.instagram },
            { name: 'linkedin', type: 'urlfield', service: 'linkedin', placeholder: 't(Enter LinkedIn URL)', defaultValue: configuration.linkedin },
            { name: 'youtube', type: 'urlfield', service: 'youtube', placeholder: 't(Enter YouTube URL)', defaultValue: configuration.youtube },
            { name: 'pinterest', type: 'urlfield', service: 'pinterest', placeholder: 't(Enter Pinterest URL)', defaultValue: configuration.pinterest },
            { name: 'tiktok', type: 'urlfield', service: 'tiktok', placeholder: 't(Enter TikTok URL)', defaultValue: configuration.tiktok },
            { name: 'threads', type: 'urlfield', service: 'threads', placeholder: 't(Enter Threads URL)', defaultValue: configuration.threads },
            { name: 'vimeo', type: 'urlfield', service: 'vimeo', placeholder: 't(Enter Vimeo URL)', defaultValue: configuration.vimeo },
            { name: 'x', type: 'urlfield', service: 'x', placeholder: 't(Enter X URL)', defaultValue: configuration.x }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleSuccess() {
    this.props.onDone()
  }

}

export default New
