import SocialCampaigns from './social_campaigns'
import EmailCampaigns from './email_campaigns'
import EmailArticles from './email_articles'
import SenderDomains from './sender_domains'
import SupportUsers from './support_users'
import EmailModules from './email_modules'
import AgencyUsers from './standard_users'
import Page from '@admin/components/page'
import SignupPages from './signup_pages'
import Template from './notices/design'
import Agencies from './agencies'
import Advisors from './advisors'
import Notices from './notices'
import Signups from './signups'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Truevail)',
  manager: {
    path: '/admin/truevail/admin',
    items: [
      {
        label: 't(Agencies)',
        path: '/agencies',
        panel: <Agencies />
      },
      {
        label: 't(Advisors)',
        path: '/advisors',
        panel: <Advisors />
      },
      {
        label: 't(Email)',
        children: [
          {
            label: 't(Articles)',
            path: '/email_articles',
            panel: <EmailArticles />
          },
          {
            label: 't(Campaigns)',
            path: '/email_campaigns',
            panel: <EmailCampaigns />
          },
          {
            label: 't(Modules)',
            path: '/email_modules',
            panel: <EmailModules />
          },
          {
            label: 't(Sender Domains)',
            path: '/sender_domains',
            panel: <SenderDomains />
          }
        ]
      },
      {
        label: 't(Social Media)',
        children: [
          {
            label: 't(Campaigns)',
            path: '/social_campaigns',
            panel: <SocialCampaigns />
          }
        ]
      },
      {
        label: 't(Truevail)',
        children: [
          {
            label: 't(Notice Template)',
            path: '/template',
            panel: <Template />
          },
          {
            label: 't(Notices)',
            path: '/notices',
            panel: <Notices  />
          },
          {
            label: 't(Signup Pages)',
            path: '/signup_pages',
            panel: <SignupPages />
          },
          {
            label: 't(Signups)',
            path: '/signups',
            panel: <Signups />
          }
        ]
      },
      {
        label: 't(Users)',
        children: [
          {
            label: 't(Agency Users)',
            path: '/standard_users',
            panel: <AgencyUsers />
          },
          {
            label: 't(Support Users)',
            path: '/support_users',
            panel: <SupportUsers />
          }
        ]
      }
    ]
  }
})


export default Page(null, mapPropsToPage)
