import MJSONScreenshot from '@admin/components/mjson_screenshot'
import PropTypes from 'prop-types'
import React from 'react'

const getScreenshot = (entity) => ({
  engine: 'mjson',
  entity: `truevail_email_templates/${entity.code}`,
  width: 100,
  height: 75,
  screenshoted_at: entity.screenshoted_at,
  placeholder: entity.status === 'pending'
})

const EmailTemplateToken = ({ email_template }) => (
  <div className="campaign-token">
    <div className="campaign-token-preview">
      <div className="campaign-token-preview-inner">
        <MJSONScreenshot { ...getScreenshot(email_template) } />
      </div>
    </div>
    <div className="campaign-token-label">
      <div className="token-link">
        { email_template.title }
      </div>
    </div>
  </div>
)

EmailTemplateToken.propTypes = {
  email_template: PropTypes.object
}

export default EmailTemplateToken
