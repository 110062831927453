import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import Field from './field'
import React from 'react'

class Section extends React.Component {

  static propTypes = {
    collapsed: PropTypes.bool,
    collapsing: PropTypes.bool,
    data: PropTypes.object,
    fields: PropTypes.array,
    highlight: PropTypes.bool,
    errors: PropTypes.object,
    section: PropTypes.object,
    status: PropTypes.string,
    onBusy: PropTypes.func,
    onReady: PropTypes.func,
    onSubmit: PropTypes.func,
    onUpdateData: PropTypes.func,
    onValid: PropTypes.func
  }

  static defaultProps = {
    collapsing: false
  }

  _handleToggle = this._handleToggle.bind(this)

  constructor(props) {
    super(props)
    const collapsed = (props.section.collapsed !== null) ? props.section.collapsed : props.section.collapsing
    this.state = { collapsed }
  }

  render() {
    const { section } = this.props
    const { collapsing, fields, instructions, label, after, include } = section
    if(include === false) return null
    return (
      <div className={ this._getClass() }>
        { label &&
          <div className="maha-form-section-header" onClick={ this._handleToggle }>
            <div className={ this._getLabelClass() }>
              <span><T text={ label } /></span>
            </div>
            { collapsing &&
              <div className="maha-form-section-header-icon">
                <Icon icon="chevron-up" />
              </div>
            }
          </div>
        }
        <div className="maha-form-section-body">
          { instructions &&
             <div className="maha-form-section-instructions">
               <T text={ instructions } />
             </div>
          }
          <div className="maha-form-section-fields">
            { fields.map((field, index) => (
              <Field key={`field_${index}_${field.name}`} {...this._getField(field, index) } />
            ))}
            { after }
          </div>
        </div>
      </div>
    )
  }

  _getClass() {
    const { section } = this.props
    const { collapsing } = section
    const { collapsed } = this.state
    let classes = ['maha-form-section']
    if(section.padded !== false) classes.push('padded')
    if(collapsing) classes.push('collapsing')
    if(collapsing) classes.push(collapsed ? 'collapsed' : 'expanded')
    return classes.join(' ')
  }

  _getField(field) {
    const { data, errors, fields, status, onBusy, onReady, onSubmit, onUpdateData, onValid } = this.props
    return {
      field,
      fields,
      data,
      errors,
      status,
      onBusy,
      onReady,
      onSubmit,
      onUpdateData,
      onValid
    }
  }

  _getLabelClass() {
    const { highlight } = this.props
    const classes = ['maha-form-section-header-label']
    if(highlight) classes.push('error')
    return classes.join(' ')
  }

  _handleToggle() {
    this.setState({
      collapsed: !this.state.collapsed
    })
  }

}

export default Section
