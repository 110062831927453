import CardToken from '@apps/finance/admin/tokens/card'
import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Cards)',
  collection: {
    endpoint: '/api/admin/finance/payment_methods',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Description)', key: 'description', primary: true, format: (payment_method) => (
        <div className="token">
          <CardToken payment_method={ payment_method } /> 
        </div>
      ) },
      { label: 't(Payments)', key: 'payments_count', collapsing: true, align: 'right' },
      { label: 't(Total)', key: 'total', format: 'currency', collapsing: true },
      { label: 't(Status)', key: 'status', collapsing: true, format: StatusToken  }
    ],
    empty: {
      title: 't(No Payment Methods)',
      text: 't(There are no payment methods for this contact)',
      icon: 'dollar'
    },
    entity: 'payment method',
    defaultQuery: { contact_id: props.contact.id, method: 'card' },
    defaultSort: { key: 'created_at', order: 'desc' }
  }
})

export default Panel(null, mapPropsToPanel)
