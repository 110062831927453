import Search from '@admin/components/search'
import PropTypes from 'prop-types'
import Results from './results'
import React from 'react'

class Unassigned extends React.Component {

  static propTypes = {
    assigned: PropTypes.array,
    social_batch: PropTypes.object,
    onAdd: PropTypes.func
  }

  state = {
    view: 'grid'
  }

  _handleAdd = this._handleAdd.bind(this)
  _handleToggle = this._handleToggle.bind(this)

  render() {
    return (
      <div className="truevail-social-batch-campaigns-unassigned">
        <div className="truevail-social-batch-campaigns-unassigned-body">
          <Search { ...this._getSearch() } />
        </div>
      </div>
    )    
  }

  _getSearch() {
    const { assigned, social_batch } = this.props
    const { view } = this.state
    return {
      endpoint: `/api/admin/truevail/agency/social_batches/${social_batch.id}/social_campaigns/unassigned`,
      entity: 't(Social Campaign)',
      filter: {
        status: {
          $eq: 'published'
        }
      },
      buttons: [
        { 
          icon: view === 'list' ? 'th' : 'list',
          handler: this._handleToggle 
        }
      ],
      layout: Results,
      props: {
        assigned,
        view,
        onChoose: this._handleAdd
      }
    }
  }

  _handleAdd(social_campaign) {
    this.props.onAdd(social_campaign)
  }

  _handleToggle() {
    const { view } = this.state
    this.setState({ 
      view: view === 'list' ? 'grid' : 'list'
    })
  }

}

export default Unassigned
