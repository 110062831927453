import ModalPanel from '@admin/components/modal_panel'
import LegacyWebsite from './legacy_website'
import Token from '@admin/components/token'
import List from '@admin/components/list'
import T from '@admin/components/t'
import Status from './advisor/status'
import Additional from './additional'
import Advisor from './advisor/edit'
import Templates from './templates'
import PropTypes from 'prop-types'
import Senders from './senders'
import Social from './social'
import Lists from './lists'
import Forms from './forms'
import Email from './email'
import React from 'react'

class Overview extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    onCancel: PropTypes.func,
    onTask: PropTypes.func
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleTask = this._handleTask.bind(this)
  
  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="maha-modal-panel-inner">
          <List { ...this._getList() } />
        </div>
      </ModalPanel>
    )
  }

  _getList() {
    const tasks = this._getTasks()
    return {
      className: null,
      items: tasks,
      format: (task) => (
        <Token>
          <strong><T text={ task.title } /></strong><br />
          <span><T text={ task.description } /></span>
        </Token>
      ),
      onClick: this._handleTask
    }
  }

  _getPanel() {
    return {
      title: 't(Setup Advisor)',
      leftItems: [
        { label: 't(Cancel)', handler: this._handleCancel}
      ],
      rightItems: [
        { label: 't(Done)', handler: this._handleCancel}
      ]
    }
  }

  _getTasks() {
    const { team } = this.context.admin
    return [
      { title: 't(Advisor)', description: 't(Tell us about your business and how you can be found)', modal: Advisor },
      { title: 't(Additional Information)', description: 't(Request additional information from advisor)', modal: Additional },
      // { title: 't(Logo)', description: 't(Tell us about your business and how you can be found)', modal: Logo },
      { title: 't(Senders)', description: 't(Manage senders for advisor)', modal: Senders },
      { title: 't(Lists)', description: 't(Manage lists for advisor)', modal: Lists },
      { title: 't(Email Templates)', description: 't(Manage email templates for advisor)', modal: Templates },
      { title: 't(Signup Forms)', description: 't(Manage signup forms for advisor)', modal: Forms },
      { title: 't(Email Subscriptions)', description: 't(Manage email subscriptions for advisor)', modal: Email },
      { title: 't(Social Media Subscriptions)', description: 't(Manage social media subscriptions for advisor)', modal: Social },
      ...team.id === 41 ? [
        { title: 't(Legacy Website Subscriptions)', description: 't(Manage legacy website for advisor)', modal: LegacyWebsite }
      ] : [],
      // { title: 't(Website)', description: 't(Manage website for advisor)', modal: Email },
      { title: 't(Advisor Status)', description: 't(Update the advisor status)', modal: Status }
    ]
  }

  _handleCancel() {
    this.props.onCancel()
  }

  _handleTask(task) {
    this.props.onTask(task)
  }

}

export default Overview
