import ContentToken from '@apps/maha/admin/tokens/content'
import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import Clone from './content/clone'
import New from './content/new'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Email Articles)',
  collection: {
    endpoint: '/api/admin/truevail/admin/content/email_articles',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Email Article)', key: 'title', primary: true, format: (content) => (
        <ContentToken content={ content } />
      ) },
      { label: 't(Status)', key: 'status', collapsing: true, format: StatusToken }
    ],
    criteria: {
      fields: [
        {
          label: 't(Fields)',
          fields: [
            { name: 't(Perspective)', key: 'perspective', type: 'select', multiple: true, options: ['first-person singular','first-person plural'] },
            { name: 't(Language)', key: 'language', type: 'select', multiple: true, options: ['american english','canadian english','french','german','italian'] },
            { name: 't(Status)', key: 'status', type: 'select', multiple: true, options: ['draft','published'], format: StatusToken }
          ]
        }
      ]
    },
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      icon: 'file-o',
      title: 't(No Email Articles)',
      text: 't(You have not yet created any email articles)',
      buttons: [
        { label: 't(Create Email Article)', modal: <New type="email_article" /> }
      ]
    },
    entity: 'email article',
    recordTasks: (record) => [
      {
        label: 't(Clone Email Article)',
        modal: <Clone content={ record } />
      },
      {
        label: 't(Publish Email Article)',
        show: record.status === 'draft',
        confirm: 't(Are you sure you want to publish this email article? Once it has been published, you cannot change the content of this email article)',
        request: {
          endpoint: `/api/admin/truevail/admin/content/${record.id}/publish`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Sucessfully published email article)'),
          onFailure: () => context.flash.set('error', 't(Unable to publish email article)')
        }
      },
      {
        label: 't(Unpublish Email Article)',
        show: record.status === 'published',
        confirm: 't(Are you sure you want to unpublish this email article?)',
        request: {
          endpoint: `/api/admin/truevail/admin/content/${record.id}/unpublish`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Sucessfully unpublished email article)'),
          onFailure: () => context.flash.set('error', 't(Unable to unpublish email article)')
        }
      },
      {
        label: 't(Delete Email Article)',
        show: record.status !== 'published',
        confirm: 't(Are you sure you want to delete this email article?)',
        request: {
          endpoint: `/api/admin/truevail/admin/content/${record.id}`,
          method: 'DELETE',
          onSuccess: () => context.flash.set('success', 't(Successfully deleted email article)'),
          onFailure: () => context.flash.set('error', 't(Unable to delete email article)')
        }
      }
    ],
    onClick: (record) => context.router.push(`/admin/truevail/admin/content/${record.id}`)
  },
  task: {
    icon: 'plus',
    modal: <New type="email_article" />
  }
})

export default Panel(null, mapPropsToPanel)
