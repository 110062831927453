import SocialCampaignToken from '@apps/truevail/admin/tokens/social_campaign'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'
import Edit from './edit'
import New from './new'

class Import extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {}

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    return {
      title: 't(Import Social Campaigns)',
      method: 'POST',
      action: '/api/admin/truevail/agency/social_campaigns',
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      format: (config) => ({
        social_campaigns: config.social_campaigns.map(social_campaign => ({
          id: social_campaign.social_campaign.id,
          voice: social_campaign.voice,
          language: social_campaign.language,
          perspective: social_campaign.perspective,
          status: social_campaign.status
        }))
      }),
      sections: [
        {
          fields: [
            { label: 't(Social Campaigns)', name: 'social_campaigns', type: 'collectionfield', orderable: false, token: ({ value }) => (
              <SocialCampaignToken social_campaign={ value.social_campaign } />
            ), cloneable: true, editable: true, entity: 'social campaign', newForm: New, editForm: Edit, required: true }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Import
